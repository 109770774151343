<section class="container min-vh-100">
  <form [formGroup]="form">
    <div class="row justify-content-center">
      <div class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
        <div class="d-flex justify-content-between align-items-center fade-in">
          <svg (click)="goBack()" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
          </svg>
          <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
            Enviar dinheiro
          </h1>
          <div class="col-1"></div>
        </div>
        <div class="flex-grow-1 d-flex flex-column pt-4 px-3 fade-in-left">
          <h4 class="text-start text-wrap fw-700 font-size-16 letter-spacing-05 line-24 color-secundary my-2">enviar para
          </h4>
          <div class="card-add-money d-flex flex-row p-3 align-items-start cursor-pointer my-2">
            <img src="../../../assets/images/fotoPerfil.png" height="40">
            <div class="d-flex flex-column px-3">
              <h4 class="text-start text-wrap fw-700 font-size-14 letter-spacing-05 line-19 color-secundary">
                {{ destinyUser.name }}
              </h4>
              <p class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                {{ destinyUser.phone }}
              </p>
            </div>
          </div>
          <h4 class="text-start text-wrap fw-700 font-size-16 letter-spacing-05 line-24 color-secundary my-2">Você esta
            enviando</h4>
          <div class="border border-dark-green rounded-3 d-flex align-items-center cursor-pointer mt-2 p-2">
            <div class="dropdown">
              <button class="btn dropdown-toggle p-0 border-0 color-secundary" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img [src]="coinSelectSend.img" alt="" height="20px" class="mx-2">
                <span class="fw-400 text-gray">{{coinSelectSend.name}}</span>
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let item of listCoin" class="text-nowrap" (click)="selectCoin(item,'send')"><img
                    [src]="item.img" alt="" height="20px" class="mx-2"><span>{{item.name}}</span>
                </li>
              </ul>
            </div>
            <input *ngIf="coinSelectSend.name === 'BRL'" type="text"
              class="form-control border-0 bg-transparent color-secundary fw-700 font-size-16" formControlName="valuePay" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 2 }">
            <input *ngIf="coinSelectSend.name === 'USDT'" type="text"
              class="form-control border-0 bg-transparent color-secundary fw-700 font-size-16" formControlName="valuePay" currencyMask
              [options]="{ prefix: 'US$ ', thousands: '.', decimal: ',', precision: 2 }">

          </div>
          <p class="color-secundary text-end fw-500 font-size-10 mt-1">Disponível : 100</p>

          <h4 class="text-start text-wrap fw-700 font-size-16 letter-spacing-05 line-24 color-secundary mb-2 mt-4">Seu
            contato
            receberá</h4>
          <div class="border border-dark-green rounded-3 d-flex align-items-center cursor-pointer mt-2 p-2">
            <div class="dropdown">
              <button class="btn dropdown-toggle p-0 border-0 color-secundary" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img [src]="coinSelectReceive.img" alt="" height="20px" class="mx-2">
                <span class="fw-400 text-gray">{{coinSelectReceive.name}}</span>
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let item of listCoin" class="text-nowrap" (click)="selectCoin(item,'receive')"><img
                    [src]="item.img" alt="" height="20px" class="mx-2"><span>{{item.name}}</span>
                </li>
              </ul>
            </div>
            <input readonly *ngIf="coinSelectReceive.name === 'BRL' && coinSelectSend.name === 'BRL' "
              value="R$ {{ form.controls['valuePay'].value}}" type="text"
              class="form-control border-0 bg-transparent color-secundary fw-700 font-size-16" formControlName="valuePay" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 2 }">

            <input readonly *ngIf="coinSelectReceive.name === 'BRL' && coinSelectSend.name === 'USDT' "
              value="R$ {{(usdPrice * form.controls['valuePay'].value).toFixed(2)}}" type="text"
              class="form-control border-0 bg-transparent color-secundary fw-700 font-size-16" formControlName="valuePay" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 2 }">

            <input readonly *ngIf="coinSelectReceive.name === 'USDT' && coinSelectSend.name === 'BRL'"
              value="US$ {{(form.controls['valuePay'].value / usdPrice).toFixed(2) }}" type="text"
              class="form-control border-0 bg-transparent color-secundary fw-700 font-size-16" formControlName="valuePay" currencyMask
              [options]="{ prefix: 'US$ ', thousands: '.', decimal: ',', precision: 2 }">

            <input readonly *ngIf="coinSelectReceive.name === 'USDT' && coinSelectSend.name === 'USDT'"
              value="US$ {{form.controls['valuePay'].value}}" type="text"
              class="form-control border-0 bg-transparent color-secundary fw-700 font-size-16" formControlName="valuePay" currencyMask
              [options]="{ prefix: 'US$ ', thousands: '.', decimal: ',', precision: 2 }">
          </div>
        </div>
        <div class="d-flex pb-5 fade-in">
          <button [routerLink]="['/logged/processing']"
            class="button-green fw-800 font-size-14 mt-3 mx-3">Continuar</button>
        </div>
      </div>
    </div>
  </form>
</section>