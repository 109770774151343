<section class="container min-vh-100" style="overflow: hidden;">
  <div class="row justify-content-center">
    <div *ngFor="let item of user" class="col col-xl-12 pb-4">

      <div class="row d-flex pt-5 pb-2">
        <div class="col fade-in-top mt-3">
          <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="120px"></p>
        </div>
        <div class="col d-flex justify-content-end fade-in-top">
          <button class="bg-transparent border-none mt-1" (click)="toggleBalance()">
            <span class="bi color-secundary" style="font-size: 25px"
              [ngClass]="showBalance ? 'bi-eye' : 'bi-eye-slash'"></span>
          </button>
          <div [routerLink]="['/notifications']" class="p-0 m-0 d-block cursor-pointer position-relative">
            <img src="../../../assets/icon/icon-sino.svg"
              class="mt-3 me-3 ms-2" width="25px" height="23px">
            <div class="new-message-icon position-absolute" *ngIf="hasNewMessages"></div>
          </div>
          <div class="border-profile-picture-s position-relative p-0 rounded-circle cursor-pointer">
            <img [routerLink]="['/logged/my-account']" *ngIf="item.avatar" [src]="item.avatar"
              class="d-block rounded-circle"
              [ngClass]="item.avatar ? 'w-100 h-100' :'default-profile-svg'">

            <img [routerLink]="['/logged/my-account']" *ngIf="!item.avatar && !item.name"
              class="d-flex justify-content-center align-items-center rounded-circle w-100 h-100"
              src="../../../assets/icon/icon-nova-perfil.svg">

            <span [routerLink]="['/logged/my-account']" *ngIf="!item.avatar && item.name"
              class="fw-500 font-size-18 d-flex justify-content-center align-items-center rounded-circle w-100 h-100 margin-top-2 color-white">
              {{item?.name[0]}}</span>
          </div>
        </div>
      </div>

      <!-- CARDS & CALL_ACTIONS -->
      <div class="row mt-4">

        <!-- CARDS -->
        <div class="col-12 col-lg-5 d-flex align-items-center align-items-start flex-lg-column fade-in-left"
          style="gap: .5rem;">
          <!-- CODEWALLET CARD -->
          <!-- <div class="cards-container-home">
            <div class="new-card-home d-block p-2 pb-2">
              <div class="d-flex justify-content-end align-items-end">
                <p class="fw-700 color-primary font-size-10 letter-spacing-080 m-0">
                  BRL
                </p>
              </div>
              <p class="fw-500 font-size-11 letter-spacing-0020 font-size-11-responsive color-gray mb-1">
                {{ 'HOME.BALANCE_CODEWALLET' | translate }}
              </p>
              <p class="fw-700 font-size-16 font-size-16-responsive color-secundary letter-spacing-005 m-0">
                {{ selectedCurrency === 'BRL' ? 'R$' : '$'}}
                {{showBalance ? balanceCodeWallet?.toFixed(2) : '****'}}
              </p>
            </div>
            <button class="button-green-home d-block letter-spacing-0020 h-37 mt-2"
              (click)="cobrarClicked('/logged/add-money')">
              <div class="d-flex justify-content-between align-items-center px-1">
                <span class="fw-500 font-size-11 pt-1">{{ 'HOME.ADD_MONEY' | translate }}</span>
                <img src="../../../assets/icon/home/icon-seta-direita.svg" height="12px" alt>
              </div>
            </button>
          </div> -->

          <!-- CODEWALLET CARD -->
          <div class="cards-container-home">
            <div class="new-card-home d-block p-2 pb-2">
              <div class="d-flex justify-content-end align-items-end">
                <p class="fw-700 color-primary font-size-10 letter-spacing-080 m-0">
                  BRL
                </p>
              </div>
              <p class="fw-500 font-size-11 letter-spacing-0020 font-size-11-responsive color-gray mb-1">
                {{ 'HOME.BALANCE_CODEWALLET' | translate }}
              </p>
              <p class="fw-700 font-size-16 font-size-16-responsive color-secundary letter-spacing-005 m-0">
                {{ selectedCurrency === 'BRL' ? 'R$' : '$'}}
                {{showBalance ? balanceCodeWallet?.toFixed(2) : '****'}}
              </p>
            </div>
            <button class="button-green-home d-block letter-spacing-0020 h-37 mt-2"
              (click)="cobrarClicked('/logged/add-money')">
              <div class="d-flex justify-content-between align-items-center px-1">
                <span class="fw-500 font-size-11 pt-1">{{ 'HOME.ADD_MONEY' | translate }}</span>
                <img src="../../../assets/icon/home/icon-seta-direita.svg" height="12px" alt>
              </div>
            </button>
          </div>

          <!-- CRIPTO CARD -->
           <div class="cards-container-home cards-container-home-2">
            <div class="new-card-home p-2 pb-2">
              <div class="d-flex justify-content-end align-items-end">
                <p class="fw-700 color-primary letter-spacing-080 font-size-10 m-0">USD</p>
              </div>
              <p class="fw-500 font-size-11 letter-spacing-0020 font-size-11-responsive color-gray mb-1">
                {{ 'HOME.BALANCE_CRIPTO' | translate }}</p>
              <div *ngIf="!loadedCriptoBalance" class="skeleton-container height-21 w-100 m-0">
                {{saldoCrypto}}</div>

              <p *ngIf="loadedCriptoBalance"
                class="fw-700 font-size-16 font-size-16-responsive letter-spacing-005 color-secundary m-0">
                $ {{saldoCrypto.toFixed(2)}}
              </p>
            </div>

            <button class="button-green-home letter-spacing-0020 h-37 mt-2" [disabled]="userInfo.blockedByAdmin" routerLink="/logged/deposit">
              <div class="d-flex justify-content-between align-items-center px-1">
                <span class="fw-500 font-size-11 pt-1">{{ 'HOME.ADD_CRIPTO' | translate }}</span>
                <img src="../../../assets/icon/home/icon-seta-direita.svg" height="12px" alt>
              </div>
            </button>
          </div>
        </div>

        <div class="buttons-container-home col-12 col-lg-7 mt-4 mt-lg-0 d-flex flex-wrap fade-in">
          <!-- Pix -->
          <div class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            (click)="cobrarClicked('/logged/send-pix')">
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/icon-pix-menor.svg" height="25px"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">Pix</p>
            </div>
          </div>

          <!-- Cobrar -->
          <div class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
          [routerLink]="['/logged/add-money']" >
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/home/cobrar.svg" height="24px"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">Cobrar</p>
            </div>
          </div>

          <!-- Transferir -->
          <div class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            (click)="cobrarClicked('/transfer')">
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/home/qr code.svg" height="24px"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">{{ 'HOME.CHARGE'
                | translate }}</p>
            </div>
          </div>

          <!-- CRIPTO -->
          <div *ngIf="userInfo.blockedByAdmin"
          class="button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
         >
          <div>
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/home/cripto.svg" height="24px"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">Cripto</p>
            </div>
          </div>
        </div>
           <div *ngIf="!userInfo.blockedByAdmin"
            class="button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            (click)="goToCripto()">
            <div>
              <div class="flex-column">
                <p class="color-secundary d-flex justify-content-center"><img
                    src="../../../assets/icon/home/cripto.svg" height="24px"></p>
                <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">Cripto</p>
              </div>
            </div>
          </div>
          
          <!-- CRIPTO -->
          <!-- <div
            class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            (click)="goToCripto()">
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center mb-2"><img
                  src="../../../assets/icon/home/cripto.svg"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">{{ 'HOME.CRIPTO'
                | translate }}</p>
            </div>
          </div> -->

          <!-- Cartões -->
          <div *ngIf="userInfo.blockedByAdmin" class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
         >
          <div class="flex-column">
            <p class="color-secundary d-flex justify-content-center"><img
                src="../../../assets/icon/home/cartoes.svg"></p>
            <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">{{ 'HOME.CARDS'
              | translate }}</p>
          </div>
        </div>
          <div *ngIf="!userInfo.blockedByAdmin" class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            (click)="needInfoAndRequestCard('/request-card')">
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/home/cartoes.svg"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">{{ 'HOME.CARDS'
                | translate }}</p>
            </div>
          </div>

          <!-- EXTRATO -->
          <div *ngIf="userInfo.blockedByAdmin" class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            >
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/home/extrato.svg" height="22.5px"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">{{
                'HOME.EXTRACT' | translate }}</p>
            </div>
          </div>
          <div *ngIf="!userInfo.blockedByAdmin" class="cursor-pointer button-card-home align-self-start p-3 d-flex align-items-center justify-content-center"
            [routerLink]="['/your-activities']">
            <div class="flex-column">
              <p class="color-secundary d-flex justify-content-center"><img
                  src="../../../assets/icon/home/extrato.svg" height="22.5px"></p>
              <p class="fw-500 font-size-11 letter-spacing-1 color-secundary text-center m-0">{{
                'HOME.EXTRACT' | translate }}</p>
            </div>
          </div>

        </div>
      </div>

      <!-- CLAIM NOW  -->
      <!-- <div (click)="showRescueWarning()" class="card-home cursor-pointer mt-4 mt-lg-5 fade-in">
        <div class="row">
          <div class="col-8 py-3">
            <div class="d-flex flex-column ps-3 ms-1">
              <span class="fw-500 font-size-11 color-secundary letter-spacing-0020 mt-1">{{
                'HOME.NEW_PRODUCTS' | translate }}</span>
              <span class="fw-700 font-size-16 color-secundary letter-spacing-005">{{ 'HOME.REDEEM_BitbentzCoin' |
                translate }}</span>
            </div>
          </div>
          <div class="col-4 d-flex justify-content-end align-items-end">
            <img src="../../../assets/images/maos-home.svg" class="pe-2">
          </div>
        </div>
      </div> -->

      <!-- SALES -->
      <h6 class="fw-700 font-size-21 color-secundary mt-5 ps-1 pb-lg-2 letter-spacing-0098 fade-in-right"
        style="margin-bottom: .65rem;">{{ 'HOME.PROMOTIONS_UPDATES' | translate }}</h6>
      <div class="card-home-blue cursor-pointer mt-2 fade-in-right">
        <div class="row">
          <div class="col-8 py-3">
            <div class="d-flex flex-column ps-3 ms-1">
              <span class="fw-500 font-size-11 color-white letter-spacing-0020 mt-1">{{ 'HOME.BOOST_YOUR'
                | translate }}</span>
              <span class="fw-700 font-size-16 color-white letter-spacing-005">{{ 'HOME.YOUR_MONEY' |
                translate }}</span>
            </div>
          </div>
          <div class="col-4 d-flex justify-content-end align-items-end">
            <img src="../../../assets/icon/home/seu-dinheiro.svg" class="pe-2">
          </div>
        </div>
      </div>
      <div (click)="needInfoAndRequestCard('/request-card')" *ngIf="!userInfo.blockedByAdmin"
        class="card-home-green cursor-pointer mt-3 fade-in-right">
        <div class="row">
          <div class="col-8 py-1">
            <div class="d-flex flex-column mt-3 ps-3">
              <span class="fw-500 font-size-11 color-white letter-spacing-0020">{{ 'HOME.HAVE_ALL' | translate }}</span>
              <span class="fw-700 font-size-17 color-white letter-spacing-005">{{ 'HOME.CODE_WALLET_CARD' | translate }}</span>
            </div>
          </div>
          <div class="col-4 d-flex justify-content-end align-items-center">
            <img src="../../../assets/images/bitbentz-card.png" width="110px" class="pe-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>