import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientUpdateRequest } from 'src/app/dtos/client/client-update-request.dto';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

  form: FormGroup;

  isSubmit: boolean = false;

  userEmail = localStorage.getItem('email');
  userPhone = localStorage.getItem('phone');
  cpfInUse = false
  dateOfBirthInvalid = false

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  goBack() {
    this.router.navigate(['/login-number']);
  }

  goForward() {
    this.router.navigate(['/create-password']);
  }

  confirm() {
    this.isSubmit = true;
    if (this.form.invalid || !this.form.dirty || this.isCPFInvalidAndTouched()) return;

    const dateOfBirthControl = this.form.controls['dateOfBirth'];
    const dateOfBirth = moment(dateOfBirthControl.value, 'DD/MM/YYYY');
    
    if (!dateOfBirth.isValid() || dateOfBirth.date() > 31 || dateOfBirth.month() + 1 > 12) {
        this.dateOfBirthInvalid = true
        return;
    }

    this.clientService.checkUserExistence('document', this.form.controls['cpf'].value).subscribe({
      next: data => {
        if(data){
          this.cpfInUse = true
          this.dateOfBirthInvalid = false
        }
      },
      error: err => {
        this.cpfInUse = false
        this.dateOfBirthInvalid = false
        this.addUserInfo()
      }
    })
  }

  addUserInfo() {
    if (this.userPhone || this.userEmail) {
      this.ngxSpinnerService.show();
      const date = moment(this.form.controls['dateOfBirth'].value, 'DD/MM/YYYY').toDate();
      const request: ClientUpdateRequest = {
        name: this.form.controls['name'].value,
        dateOfBirth: date,
        document: this.form.controls['cpf'].value,
        address: {
          zipCode: '',
          country: '',
          state: '',
          city: '',
          address: '',
          additionalInformation: '',
          number: ''
        }
      }
      this.clientService.updateUserInfo(request).subscribe({
        next: data => {
          this.toastrService.success('Informações adicionadas com sucesso!', '', { progressBar: true });
          this.router.navigate(['/create-password']);
          console.clear()
          this.ngxSpinnerService.hide();
        },
        error: err => {
          console.error(err);
          this.toastrService.error(`${err.error.errors}`, 'Erro', { progressBar: true })
          this.ngxSpinnerService.hide();
        }
      });
    }
  }

  validarCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;
    let add = 0;
    for (let i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    add = 0;
    for (let i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  };

  isCPFInvalidAndTouched(): boolean {
    const cpfControl = this.form.controls['cpf'];

    return cpfControl.dirty && !this.validarCPF(cpfControl.value);
  };
}
