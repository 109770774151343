import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssasPaymentGeneratePaymentTicketRequestDto } from 'src/app/dtos/payment/assas-payment-generate-pix-request.dto';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-checkout-boleto',
  templateUrl: './checkout-boleto.component.html',
  styleUrls: ['./checkout-boleto.component.scss']
})
export class CheckoutBoletoComponent {
  loading = true
  checkoutType: any;
  valueCoin: any;
  dueDate: any;
  boletoResponse: any;
  fee: number;
  codigoDeBarrasUrl: string;

  constructor(
    private router: Router,
    private PaymentService: PaymentService,
    private toastrService: ToastrService,
  ) {
    let response: any = localStorage.getItem('checkoutType');
    this.checkoutType = JSON.parse(response);

    let responseValue: any = localStorage.getItem('valueCoin');
    this.valueCoin = JSON.parse(responseValue);

    let dueDate: any = localStorage.getItem('dueDate');
    this.dueDate = dueDate;

    this.fee = this.checkoutType.fee;
    
    const dto: AssasPaymentGeneratePaymentTicketRequestDto = {
      dueDate: this.dueDate,
      value: this.valueCoin.value,
    }

    this.PaymentService.generateTicket(dto).subscribe({
      next: (data) => {
        this.boletoResponse = data;
        this.toastrService.success('Boleto emitido com sucesso', 'Sucesso', { progressBar: true });
      },
      error: (err) => {
        this.toastrService.error('Erro ao emitir boleto', 'Erro', { progressBar: true });
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  downloadBoleto() {
    const link = document.createElement('a');
    link.href = this.boletoResponse.bankSlipUrl;
    link.target = '_blank';
    link.download = 'boleto.pdf';
    link.click();
  }

  copyValue() {
    navigator.clipboard.writeText(this.boletoResponse.barCode);
    this.toastrService.success('Código copiado', 'Sucesso', { progressBar: true });
  }

  back() {
    this.router.navigate(['/home']);
  }
}
