<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-5 mb-4">
            <div class="fade-in row pt-5">
                <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="fw-700 font-size-16 color-secundary">{{ 'CRYPTO.CRYPTO' | translate }}</p>
                </div>
                <div [routerLink]="['/my-orders']" class="col-1 d-flex justify-content-end align-items-center cursor-pointer">
                    <img src="../../../assets/icon/icon-refresh.svg" width="25px">
                </div>
                <div [routerLink]="['/notifications']"
                    class="col-1 d-flex justify-content-end align-items-center cursor-pointer">
                    <img src="../../../assets/icon/icon-sino.svg" width="25px">
                </div>
            </div>
            <h6 class="fade-in fw-700 font-size-21 color-secundary mt-5 mb-3">{{ 'CRYPTO.LEARN_WITH_US' | translate }}</h6>
            
            <div class="fade-in-left d-flex scroll-x fw-500 font-size-10 color-secundary">
                <div class="d-flex flex-column me-3 pb-2 min-width-70">
                    <img src="../../../assets/icon/aprenda-conosco.svg">
                    <span class="text-center mt-1">{{ 'CRYPTO.LEARN_WITH_US' | translate }}</span>
                </div>
                <div class="d-flex flex-column me-3 pb-2 min-width-70">
                    <img src="../../../assets/icon/economize-usdc.svg" height="70px">
                    <span class="text-center mt-1">{{ 'CRYPTO.SAVE_WITH_USDC' | translate }}</span>
                </div>
                <div class="d-flex flex-column me-3 pb-2 min-width-70">
                    <img src="../../../assets/icon/usdt-swiper.svg">
                    <span class="text-center mt-1">USDT</span>
                </div>
                <div class="d-flex flex-column me-3 pb-2 min-width-70">
                    <img src="../../../assets/icon/eth-swiper.svg">
                    <span class="text-center mt-1">ETH</span>
                </div>
                <div class="d-flex flex-column me-3 pb-2 min-width-70">
                    <img src="../../../assets/icon/bsud-swiper.svg">
                    <span class="text-center mt-1">BUSD</span>
                </div>
                <div class="d-flex flex-column me-3 pb-2 min-width-70">
                    <img src="../../../assets/icon/L-swiper.svg">
                    <span class="text-center mt-1">Litecoin</span>
                </div>
            </div>

            <div class="fade-in-left card-home mt-4 p-3">
                <div class="row">
                    <div class="col-8 pt-2">
                        <div class="d-flex flex-column">
                            <span class="fw-400 font-size-14 color-gray">{{ 'CRYPTO.YOUR_BALANCE' | translate }}</span>
                            <p  class="fw-500 font-size-24 color-secundary">$ {{totalUsdValue.toFixed(2)}}</p>
                        </div>
                    </div>
                    <div class="col-4 d-flex justify-content-end align-items-end pb-2">
                        <span class="fw-500 color-primary font-size-11">USD</span>
                    </div>
                </div>
            </div>
            <div class="fade-in-left row mt-3">

                <!-- BUY -->
                <div class="col-3 d-flex flex-column justify-content-center align-items-center cursor-pointer">
                    <img src="../../../assets/icon/icon-compra-cinza.svg" width="20px">
                    <span class="fw-500 font-size-11 color-gray mt-2">{{ 'CRYPTO.BUY' | translate }}</span>
                </div>

                <!-- SELL -->
                <div class="col-3 d-flex flex-column justify-content-center align-items-center cursor-pointer">
                    <img src="../../../assets/icon/icon-venda-cinza.svg" class="mt-1" width="25px">
                    <span class="fw-500 font-size-11 color-gray mt-2 pt-1">{{ 'CRYPTO.SELL' | translate }}</span>
                </div>
                
                <!-- DEPOSIT -->
                <div class="col-3 d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/deposit']">
                    <img src="../../../assets/icon/icon-deposito.svg" width="28px">
                    <span class="fw-500 font-size-11 color-secundary mt-2">{{ 'CRYPTO.DEPOSIT' | translate }}</span>
                </div>
                
                <!-- WITHDRAW -->
                <div class="col-3 d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/withdraw']">
                    <img src="../../../assets/icon/icon-retirar.svg" width="25px">
                    <span class="fw-500 font-size-11 color-secundary mt-2">{{ 'CRYPTO.WITHDRAW' | translate }}</span>
                </div>
            </div>

            <!-- CRYPTO BALANCES -->
            <h6 class="fade-in fw-700 font-size-21 color-secundary mt-5 mb-3">{{ 'CRYPTO.ASSETS' | translate }}</h6>
            <div *ngFor="let item of criptoBalances" class="fade-in row mt-2 mb-3">
                <div class="col-2">
                    <img [src]="item.icon">
                </div>
                <div class="col-7">
                    <div class="d-flex flex-column">
                        <span class="fw-700 font-size-14 color-secundary letter-spacing-1">{{item.name}}</span>
                        <span class="fw-500 font-size-11 color-gray">{{item.coinCripto}}</span>
                    </div>
                </div>
                <div class="col-3">
                    <div class="d-flex flex-column">
                        <span *ngIf="!loadedCriptoBalance" class="skeleton-container height-25 w-100">{{item.value}}</span>
                        <span *ngIf="loadedCriptoBalance" class="d-flex justify-content-end fw-700 font-size-14 color-secundary">{{item.value}}</span>
                        <span *ngIf="loadedCriptoBalance" class="d-flex justify-content-end fw-500 font-size-11 color-gray">USD {{item.usdValue}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
