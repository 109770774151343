import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';
import { ClientWalletTypeEnum } from 'src/utils/enum/client-wallet-type.enum';
@Component({
  selector: 'app-deposit-coin',
  templateUrl: './deposit-coin.component.html',
  styleUrls: ['./deposit-coin.component.scss']
})
export class DepositCoinComponent implements OnInit {
  @ViewChild('content') ModalShare: any;
  @ViewChild('contentQrCode') ModalQrCode: any;

  coinSelected: any;

  rede: any;
  user: any;
  showCode: boolean = false;
  walletAddress: string;
  wallet!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private clipBoardService: ClipboardService,
    private toastrService: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '');

    const arrCoins = [
      {
        name: 'Bitcoin',
        img: '../../../assets/icon/btc-i.png'
      },
      {
        name: 'Ethereum',
        img: '../../../assets/icon/eth-i.png'
      },
      {
        name: 'BUSD',
        img: '../../../assets/icon/icon-busd.svg'
      },
      {
        name: 'BNB',
        img: '../../../assets/icon/icon-bnb.svg'
      },
      {
        name: 'Tether',
        img: '../../../assets/icon/tether-i.png'
      },
      {
        name: 'USD Coin',
        img: '../../../assets/icon/usd-i.png'
      },
    ]

    this.activatedRoute.params.subscribe((params: any) => {
      if (params['coinSelect']) {
        let teste = arrCoins.filter(el => el.name === params['coinSelect']);
        this.coinSelected = teste[0];

        if (params['coinSelect'] === 'Bitcoin' || params['coinSelect'] === 'Litecoin') {
          this.rede = {
            name: 'Bitcoin',
            img: '../../../assets/icon/btc-i.png'
          }
        } else if (params['coinSelect'] === 'Ethereum' || params['coinSelect'] === 'USD Coin') {
          this.rede = {
            name: 'Ethereum',
            img: '../../../assets/icon/eth-i.png'
          }
        } else {
          this.rede = {
            name: 'Binance',
            img: '../../../assets/icon/bnb-icon.png'
          }
        }
      }
    })
    this.getWalletAddress();
  }

  sharedIn(platform: string) {
    if (platform === 'whatsapp') {
      window.open(`https://web.whatsapp.com/send?text=Meu endereço da carteira é 35yjmvuhjl2452vnakdaphwe`, '_blank');
    }
    if (platform === 'telegram') {
      const text = encodeURIComponent('Meu endereço da carteira é 35yjmvuhjl2452vnakdaphwe');
      const telegramUrl = `https://t.me/share/url?url=&text=${text}`;
      window.open(telegramUrl, '_blank');
    }
  }

  openModalShare() {
    this.modalService.open(this.ModalShare);
  }

  openModalQrCode() {
    this.modalService.open(this.ModalQrCode);
    this.generateQrCode();
  }

  close() {
    this.modalService.dismissAll();
  }

  generateQrCode() {
    this.showCode = !this.showCode
    if (this.coinSelected.name === 'Bitcoin') {
      this.clientService.getWalletAddress(this.user.id, { type: ClientWalletTypeEnum.BITCOIN }).subscribe({
        next: data => {
          this.walletAddress = data;
        },
        error: error => {
          this.toastrService.error('Usuário não possui esse tipo de carteira.', 'Erro', { progressBar: true });

        }
      })
    } else {
      this.clientService.getWalletAddress(this.user.id, { type: ClientWalletTypeEnum.ETHEREUM }).subscribe({
        next: data => {
          this.walletAddress = data;
        },
        error: error => {
          this.toastrService.error('Usuário não possui esse tipo de carteira.', 'Erro', { progressBar: true });

        }
      })
    }
  }

  getWalletAddress() {
    if (this.coinSelected?.name === 'Bitcoin') {
      this.clientService.getWalletAddress(this.user.id, { type: ClientWalletTypeEnum.BITCOIN }).subscribe({
        next: data => {
          this.walletAddress = data;
        },
        error: error => {
          this.toastrService.error('Usuário não possui esse tipo de carteira.', 'Erro', { progressBar: true });
        }
      })
    } else {
      this.clientService.getWalletAddress(this.user.id, { type: ClientWalletTypeEnum.ETHEREUM }).subscribe({
        next: data => {
          this.walletAddress = data;
        },
        error: error => {
          this.toastrService.error('Usuário não possui esse tipo de carteira.', 'Erro', { progressBar: true });

        }
      })
    }
  }

  copyWalletAddress() {
    if (this.coinSelected.name === 'Bitcoin') {
      this.clientService.getWalletAddress(this.user.id, { type: ClientWalletTypeEnum.BITCOIN }).subscribe({
        next: data => {
          this.clipBoardService.copyFromContent(data);
          this.walletAddress = data;
          this.toastrService.success('Endereço copiado com sucesso', 'Sucesso', { progressBar: true });
        },
        error: error => {
          this.toastrService.error('Usuário não possui esse tipo de carteira.', 'Erro', { progressBar: true });
        }
      })
    } else {
      this.clientService.getWalletAddress(this.user.id, { type: ClientWalletTypeEnum.ETHEREUM }).subscribe({
        next: data => {
          this.clipBoardService.copyFromContent(data);
          this.walletAddress = data;
          this.toastrService.success('Endereço copiado com sucesso', 'Sucesso', { progressBar: true });
        },
        error: error => {
          this.toastrService.error('Usuário não possui esse tipo de carteira.', 'Erro', { progressBar: true });

        }
      })
    }
  }

  formatWalletAddress(address: string): string {
    const prefixLength = 19;
    const suffixLength = 17;

    const prefix = address.slice(0, prefixLength);
    const suffix = address.slice(-suffixLength);

    return `${prefix}...${suffix}`;
  }
}