import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {

  constructor(
    private location: Location
  ) {

  }

  back() {
    this.location.back()
  }

}
