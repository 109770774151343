import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmCodeComponent } from './auth/confirm-code/confirm-code.component';
import { ConfirmEmailCodeComponent } from './auth/confirm-email-code/confirm-email-code.component';
import { CreatePasswordComponent } from './auth/create-password/create-password.component';
import { SenhaProgressComponent } from './auth/create-password/senha-progress/senha-progress.component';
import { FingerPrintComponent } from './auth/finger-print/finger-print.component';
import { ForgotPassword1Component } from './auth/forgot-password1/forgot-password1.component';
import { ForgotPassword2Component } from './auth/forgot-password2/forgot-password2.component';
import { HelpCenterComponent } from './auth/help-center/help-center.component';
import { LoginEmailComponent } from './auth/login-email/login-email.component';
import { LoginFaceIdComponent } from './auth/login-face-id/login-face-id.component';
import { LoginNumberComponent } from './auth/login-number/login-number.component';
import { LoginPasswordComponent } from './auth/login-password/login-password.component';
import { LoginWithoutPasswordComponent } from './auth/login-without-password/login-without-password.component';
import { PersonalDataComponent } from './auth/personal-data/personal-data.component';
import { SendCodeComponent } from './auth/send-code/send-code.component';
import { ConfirmPinModelComponent } from './components/confirm-pin-model/confirm-pin-model.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { LoggedModule } from './logged/logged.module';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { AuthenticationService } from './services/authentication.service';
import { ClientService } from './services/client.service';
import { HttpLoaderFactory } from './services/http-load.service';
import { NotificationService } from './services/notification/notification.service';
import { TokenService } from './services/token.service';
import { SplashComponent } from './splash/splash.component';
@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    ConfirmCodeComponent,
    LoginNumberComponent,
    LoginPasswordComponent,
    CreatePasswordComponent,
    SenhaProgressComponent,
    PersonalDataComponent,
    SendCodeComponent,
    HelpCenterComponent,
    ForgotPassword1Component,
    ForgotPassword2Component,
    FingerPrintComponent,
    LoginWithoutPasswordComponent,
    LoginFaceIdComponent,
    LoginEmailComponent,
    ConfirmEmailCodeComponent,
    ConfirmPinModelComponent,
    TermsConditionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    ReactiveFormsModule,
    LoggedModule,
    HttpClientModule,
    WebcamModule,
    NgxIntlTelInputModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideNgxMask(),
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthenticationService,
    ClientService,
    NotificationService,
    TokenService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
