import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-forgot-password1',
  templateUrl: './forgot-password1.component.html',
  styleUrls: ['./forgot-password1.component.scss']
})
export class ForgotPassword1Component {
  form: FormGroup;

  PressBackspace: any;

  phone = localStorage.getItem('phone');

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private clientService: ClientService
  ) {
    this.form = this.formBuilder.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
      code5: ['', [Validators.required]],
    })
  }

  goBack() {
    this.location.back()
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != "") {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === "") {
        if (p != "") {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  verifyCode() {

    const request = {
      phone: this.phone || '',
      code: Number(this.form.controls['code1'].value + this.form.controls['code2'].value + this.form.controls['code3'].value + this.form.controls['code4'].value + this.form.controls['code5'].value)
    }

    this.clientService.forgotPasswordConfirmationCode(request).subscribe({
      next: data => {
        this.toastrService.success('Identidade confirmada.', 'Sucesso', { progressBar: true });
        this.router.navigate(['/forgot-password-register-password']);
      },
      error: error => {
        this.toastrService.error(`${error.error.errors}`, 'Erro', { progressBar: true });
      }
    })
  }
}
