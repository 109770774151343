<ng-container *ngIf="!showPhoto else photo">
  <section class="vh-100 vw-100 p-0 m-0 position-relative" style="overflow: hidden;">

    <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" class="h-100 w-100 d-block"></webcam>
  
    <div [routerLink]="['/login-password']" class="fade-in position-absolute p-1 border-0 rounded-circle cursor-pointer" style="top: 1rem; left: 1rem; background: rgba(0, 0, 0, .3);">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
      </svg>
    </div>
  
    <div class="position-fixed fade-in-bottom" style="bottom: 1.5rem; left: 50%; transform: translateX(-50%);">
      <button class="p-3 border-0 d-flex align-items-center justify-content-center rounded-circle bg-white" (click)="getSnapshot()" style="height: 50px; width: 50px;">
        <svg height="1em" viewBox="0 0 512 512">
          <style>svg{fill:#070813}</style>
          <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
        </svg>
      </button>
    </div>

  </section>
</ng-container>

<ng-template #photo>
  <div (click)="tryAgain()" class="fade-in position-absolute p-1 border-0 rounded-circle cursor-pointer" style="top: 1rem; left: 2rem; background: rgba(0, 0, 0, .3);">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
    </svg>
  </div>

  <section class="container d-flex flex-column min-vh-100 py-5">
    <div class="row pt-5 order-0 order-xl-1">
      <div class="col d-flex align-items-center justify-content-center">
        
        <img [src]="sysImage.length > 0 ? sysImage : webcamImage.imageAsDataUrl" class="border-radius-10 img-preview" style="border: 1px solid #4A35E8" />

      </div>
    </div>

    <div class="row order-1 order-xl-0">
      <div class="col">

        <div class="fw-400 font-size-16 color-secundary text-center letter-spacing-0016 py-4">{{ 'LOGIN_FACEID.CHECK_FACE' | translate }}</div>

      </div>
    </div>

    <div class="row order-2">
      <div class="col d-flex align-items-center justify-content-center">

        <button class="fade-in button-green fw-800 font-size-14 px-4 w-auto mt-xl-4" (click)="sendImage()">
          {{ 'LOGIN_FACEID.CONTINUE' | translate }}
        </button>

      </div>
    </div>
  </section>
</ng-template>
