<section class="container min-vh-100 pb-5">
    <div class="fade-in row pt-5">
        <div [routerLink]="['/logged/my-account']" class="col-2 align-items-start cursor-pointer">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                    d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                    fill="#060A30" />
            </svg>
        </div>
        <div class="col-8 d-flex justify-content-center align-items-center">
            <p class="fw-700 font-size-16 color-secundary m-0">{{ 'ACCOUNT_DETAILS.TITLE' | translate }}</p>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="loaded">
        <div class="col-lg-5">
            <div *ngIf="client?.blockedByAdmin"
            class="card-home mt-5 p-3 fade-in-left">
            <div class="row ">
                <div class="col d-flex flex-column">
                    <span class="fw-700 font-size-14 color-secundary letter-spacing-1" style="color: red;">{{
                        'ACCOUNT_DETAILS.ACCOUNT_BLOQUECKED' | translate }}!</span>
                         <span class="fw-700 font-size-14 color-secundary letter-spacing-1" style="color: red;">{{
                            'ACCOUNT_DETAILS.CHECK_FOR_INFO' | translate }}.</span>
                </div>
            </div>
        </div>
            <div [ngClass]="{'cursor-pointer':!(client?.kyc?.userStatus)}"
                class="card-home mt-5 p-3 fade-in-left">
                <div class="row">
                    <div class="col d-flex flex-column">
                        <span *ngIf="client?.blockedByAdmin === false" class="fw-700 font-size-14 color-secundary letter-spacing-1">{{
                            'ACCOUNT_DETAILS.VALIDATE_IDENTITY' | translate }}!!!!</span>
                        <span class="fw-400 font-size-14 color-secundary letter-spacing-1">{{
                            'ACCOUNT_DETAILS.SECURITY_PRIORITY' | translate }}</span>
                        <span class="fw-500 font-size-14 color-secundary letter-spacing-1">{{
                            'ACCOUNT_DETAILS.VALIDATE_TO_CONTINUE' | translate }}</span>
                        <div class="mt-2">
                            <span *ngIf="client?.kyc?.userStatus == 'PENDING'"
                                [ngClass]="{'bg-blue color-secundary': (client?.kyc?.userStatus == 'PENDING')}"
                                class="fw-600 font-size-14 color-white letter-spacing-1 border-radius-10 px-4 py-2">
                                Em analise
                            </span>
                            <span *ngIf="client?.kyc?.userStatus != 'PENDING'"
                                [ngClass]="{'bg-green color-white': client?.kyc?.userStatus == 'CHECKED', 'bg-blue color-white': !(client?.kyc?.userStatus == 'CHECKED') && !(client?.kyc?.userStatus == 'PENDING') || !client?.kyc?.userStatus}"
                                class="fw-600 font-size-14 text-white letter-spacing-1 border-radius-10 px-4 py-2">{{
                                ('ACCOUNT_DETAILS'+'.'+( client?.kyc?.userStatus || 'PENDING') )| translate }}
                            </span>
                        </div>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center form-check"
                        *ngIf="client?.kyc?.userStatus !== 'PENDING' && client?.kyc?.userStatus !== 'WAITING' && client?.kyc?.userStatus !== 'CHECKED'"
                        (click)="handlerKyc()">
                        <img src="../../../assets/icon/arrow-right.svg" style="padding-right: 34px;">
                    </div>
                </div>
            </div>

            <div class="card-home mt-2 p-3 fade-in-left">
                <div class="row">
                    <div class="col d-flex flex-column">
                        <span class="fw-700 font-size-14 color-secundary letter-spacing-1">Verifique seu telefone</span>
                        <span class="font-size-14 color-secundary letter-spacing-1">{{
                            'ACCOUNT_DETAILS.CLICK_LINK_TO_VERIFY_EMAIL' | translate }}</span>
                        <div *ngIf="client?.phone" class="mt-2 d-flex flex-column-reverse justify-content-between align-items-start">
                            <span
                                class="fw-600 font-size-14  color-white letter-spacing-1 bg-green border-radius-10 px-4 py-2">{{
                                'ACCOUNT_DETAILS.VERIFIED' | translate }}</span>

                            <span class="color-primary mb-3">{{client?.phone}}</span>
                        </div>
                        <div *ngIf="!client?.phone" class="mt-2">
                            <span
                                class="fw-500 font-size-14  color-white letter-spacing-1 bg-blue border-radius-10 px-4 py-2">{{
                                'ACCOUNT_DETAILS.PENDING' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="!client?.phone" class="col-1 d-flex align-items-center justify-content-center form-check">
                        <img src="../../../assets/icon/arrow-right.svg" [routerLink]="['/logged/verify-phone']"
                            style="padding-right:34px;" class="cursor-pointer">
                    </div>
                </div>
            </div>

            <div class="card-home mt-2 p-3 fade-in-left">
                <div class="row">
                    <div class="col d-flex flex-column">
                        <span class="fw-700 font-size-14 color-secundary letter-spacing-1">{{ 'ACCOUNT_DETAILS.VERIFY_EMAIL'
                            | translate }}</span>
                        <span class="font-size-14 color-black letter-spacing-1">Clique no link a seguir para verificar o seu telefone e associá-lo à
                            sua conta BitBentz Bank.</span>
                        <div *ngIf="client?.email" class="mt-2 d-flex flex-column-reverse justify-content-between align-items-start">
                            <span
                                class="fw-600 font-size-14 color-white letter-spacing-1 bg-green border-radius-10 px-4 py-2">{{
                                'ACCOUNT_DETAILS.VERIFIED' | translate }}</span>

                            <span class="color-primary mb-3">{{client?.email}}</span>
                        </div>
                        <div *ngIf="!client?.email" class="mt-2">
                            <span
                                class="fw-500 font-size-14 color-white letter-spacing-1 bg-blue border-radius-10 px-4 py-2">{{
                                'ACCOUNT_DETAILS.PENDING' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="!client?.email" class="col-1 d-flex align-items-center justify-content-center form-check">
                        <img src="../../../assets/icon/arrow-right.svg" (click)="goToVerifyEmail()" style="padding-right:34px;"
                            class="cursor-pointer">
                    </div>
                </div>
            </div>

            <div *ngIf="client?.kyc?.userStatus === 'CHECKED'" class="card-home mt-2 p-3 fade-in-left">
                <div class="row">
                    <div *ngIf="!client?.pin" class="col d-flex flex-column">
                        <span class="fw-700 font-size-14 color-secundary letter-spacing-1 mb-2">Cadastro da Senha Eletrônica de Transações</span>
                        <span *ngIf="!client?.pin" class="font-size-14 color-black letter-spacing-1">Clique no link a
                            seguir para cadastrar a
                            sua Senha Eletrônica</span>
                    </div>
                    <div *ngIf="client?.pin" class="col d-flex flex-column">
                        <span class="fw-700 font-size-14 color-secundary letter-spacing-1 mb-2">Atualizar Senha Eletrônica de Transações</span>
                        <span class="font-size-14 color-black letter-spacing-1">Clique no link a
                            seguir para atualizar a
                            sua Senha Eletrônica</span>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center form-check">
                        <img src="../../../assets/icon/arrow-right.svg" [routerLink]="['/logged/pin']"
                            style="padding-right:34px;" class="cursor-pointer">
                    </div>
                </div>
            </div>

            <div class="card-home mt-2 p-3 d-flex justify-content-between fade-in-left">
                <div>
                    <span class="fw-500 font-size-14 color-secundary letter-spacing-1">{{ 'ACCOUNT_DETAILS.USER_ID' |
                        translate }}</span>
                </div>
                <div>
                    <span class="fw-500 font-size-14 color-secundary letter-spacing-1"
                        #userIdElement><b>{{uniqueId}}</b></span>
                </div>
                <div>
                    <img src="../../../assets/icon/share-green.svg" class="cursor-pointer" (click)="shareValue()">
                </div>
                <div>
                    <img src="../../../assets/icon/copy-green.svg" class="cursor-pointer" (click)="copyValue()">
                </div>
            </div>

            <div class="card-home mt-2 p-3 fade-in-left">
                <div class="row">
                    <div class="col-6 d-flex">
                        <span class="fw-500 font-size-14 color-secundary letter-spacing-1">{{ 'ACCOUNT_DETAILS.PHONE_NUMBER'
                            | translate }}</span>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        <span class="fw-500 font-size-14 color-secundary letter-spacing-1 pe-2"><b>({{phone | slice:3:5}})
                                {{phone | slice:5:14}}</b></span>
                    </div>
                </div>
            </div>
            <div *ngIf="hasPasswordValue == 'true'" class="mt-2 p-3 fade-in">
                <div class="row text-center">
                    <span class="color-primary cursor-pointer" (click)="goChangePassword()">{{
                        'ACCOUNT_DETAILS.CHANGE_PASSWORD' | translate }}</span>
                </div>
            </div>

            <div *ngIf="hasPasswordValue == 'false'" class="mt-2 p-3 fade-in">
                <div class="row text-center">
                    <span class="color-primary cursor-pointer fw-800" (click)="goChangePasswordLogged()">{{
                        'ACCOUNT_DETAILS.CREATE_PASSWORD' | translate }}</span>
                </div>
            </div>

        </div>
    </div>
</section>