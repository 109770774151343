import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-choose-method',
  templateUrl: './choose-method.component.html',
  styleUrls: ['./choose-method.component.scss']
})
export class ChooseMethodComponent {
  chosenMethod : 'EMAIL' | 'PHONE' = 'EMAIL';

  constructor(
    private location: Location,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private router: Router,
  ) {
  }

  changeMethod( method : 'EMAIL' | 'PHONE' ) {
    this.chosenMethod = method
  }

  back() {
    this.location.back();
  }

  continue() {
    this.ngxSpinnerService.show()
    this.accountService.transferPix({verificationType : this.chosenMethod}).subscribe({
      next: () => {
        localStorage.setItem('verificationMethod', this.chosenMethod)
        this.router.navigate(['/logged/send-pix-verify'])
        this.ngxSpinnerService.hide()
        this.toastrService.success('Código enviado com sucesso', '', { progressBar: true })
      },
      error: error => {
        this.toastrService.error('Ocorreu um erro ao enviar o código', '', { progressBar: true })
        console.log(error)
        this.ngxSpinnerService.hide()
      }
    })
  }
}
