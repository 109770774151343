import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-without-password',
  templateUrl: './login-without-password.component.html',
  styleUrls: ['./login-without-password.component.scss']
})
export class LoginWithoutPasswordComponent implements OnInit {

  form: FormGroup;

  PressBackspace: any;

  phone = localStorage.getItem('phone');
  email = localStorage.getItem('email');

  msgkeepcalm = true;
  counterdown: any;

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.fb.group({
      code1: ['', Validators.required],
      code2: ['', Validators.required],
      code3: ['', Validators.required],
      code4: ['', Validators.required],
      code5: ['', Validators.required],
      code6: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.timer();
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != "") {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === "") {
        if (p != "") {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  verifyCode() {
    this.ngxSpinnerService.show();
    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    if (code.length < 6) {
      this.toastrService.error('Código inválido', '', { progressBar: true });
      this.ngxSpinnerService.hide();
      return;
    }

    let authData = {};

    if (this.email) {
      authData = { email: this.email, code: code };

    } else {
      authData = { phone: this.phone, code: code };
    }

    this.authenticationService.authenticateWithoutPasswordVerify(authData)
      .subscribe({
        next: (data) => {
          this.authenticationService.setAuthenticatedUser(data);
          this.router.navigate(['/logged/home']);
          this.ngxSpinnerService.hide();
        },
        error: (err) => {
          this.toastrService.error('Código inválido', '', { progressBar: true })
          this.ngxSpinnerService.hide();
        }
      });
  }

  resendCode() {
    this.msgkeepcalm = true;
    this.ngxSpinnerService.show();

    const phone = this.phone || '';
    const email = this.email || '';

    this.authenticationService
    .authenticateWithoutPassword({ phone: phone || email })
    .subscribe({
      next: (data) => {
        this.toastrService.success('Código reenviado com sucesso!', '', {
          progressBar: true,
        });
        this.router.navigate(['/login-without-password']);
        this.ngxSpinnerService.hide();
        this.timer();
      },
      error: (error) => {
        console.error(error);
        this.toastrService.error(`${error.error.errors}`, 'Erro ao reenviar o código', {
          progressBar: true,
        });
        this.ngxSpinnerService.hide();
        this.timer();
      },
    });
  }

  goBack() {
    this.location.back();
  }

  timer() {
    this.msgkeepcalm = true;

    let seconds = 60;

    const timer = setInterval(() => {
      seconds--;
      this.counterdown = seconds
      if (seconds == 0) {
        this.msgkeepcalm = false;
        clearInterval(timer);
      }
    }, 1000);
  }
}