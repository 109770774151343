import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { ClientService } from 'src/app/services/client.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-validation-id',
  templateUrl: './validation-id.component.html',
  styleUrls: ['./validation-id.component.scss']
})
export class ValidationIdComponent {

  @ViewChild('webcamElement') webcamElement!: ElementRef;
  currentDevice: MediaDeviceInfo | null = null;

  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;
  step6: boolean = false;

  videoWidth = 354;
  videoHeight = 484;
  triggerObservable: Subject<void> = new Subject<void>();
  capturedImage: string | undefined;

  selectedImage: string | null = null;

  selectedFile: any;

  videoOptions: MediaTrackConstraints = {
    width: { ideal: this.videoWidth },
    height: { ideal: this.videoHeight },
  };


  constructor(
    private router: Router,
    private location: Location,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  next(step: number) {
    switch (step) {
      case 1:
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
        this.step6 = false;
        break;
      case 2:
        this.step2 = true;
        this.step1 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
        this.step6 = false;
        break;
      case 3:
        this.step3 = true;
        this.step2 = false;
        this.step1 = false;
        this.step4 = false;
        this.step5 = false;
        this.step6 = false;
        break;
      case 4:
        this.step4 = true;
        this.step2 = false;
        this.step3 = false;
        this.step1 = false;
        this.step5 = false;
        this.step6 = false;
        break;
      case 5:
        this.step5 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step1 = false;
        this.step6 = false;
        break;
      case 6:
        this.step6 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
        this.step1 = false;
        break;

      default:
        break;
    }
  }

  handleImage(webcamImage: WebcamImage): void {
    this.capturedImage = webcamImage.imageAsDataUrl;
  }

  captureImage(): void {
    this.triggerObservable.next();
    if (this.capturedImage) {
      this.clientService.uploadDocumentPhoto(this.capturedImage).subscribe({
        next: data => {
          this.toastrService.success('Sua solicitação para validar a identidade foi enviada com sucesso', '', { progressBar: true })
          this.router.navigate(['logged/details'])
        },
        error: err => {
          this.toastrService.error('Erro ao validar a identidade', '', { progressBar: true })
          console.error('Erro ao salvar o documento:', err);
        }
      });
    }
  }

  async switchCamera(): Promise<void> {
    if (this.webcamElement && this.webcamElement.nativeElement) {
      try {
        const devices = await WebcamUtil.getAvailableVideoInputs();
        const currentIndex = devices.findIndex(
          (device) => device.deviceId === this.currentDevice?.deviceId
        );
        const nextIndex = (currentIndex + 1) % devices.length;
        this.currentDevice = devices[nextIndex];
        this.videoOptions = {
          ...this.videoOptions,
          deviceId: this.currentDevice?.deviceId,
        };
        await this.webcamElement.nativeElement.pause();
        await this.webcamElement.nativeElement.play();
      } catch (err) {
        console.error('Error switching camera:', err);
      }
    }
  }

  back() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
    this.step6 = false;
  }

  backToValidation() {
    this.location.back()
  }

  handleGallerySelection(event: any) {
    this.spinner.show();
    this.selectedFile = event.target.files[0];

    if (!this.selectedFile) {
      this.spinner.hide();
      return;
    }
    if (this.selectedFile.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.capturedImage = e.target?.result as string;
        this.spinner.hide();
      };
      reader.readAsDataURL(this.selectedFile);
    } else {
      this.spinner.hide();
      this.toastrService.error('Selecione um arquivo de imagem válido (por exemplo, JPG, PNG).', '', { progressBar: true });
    }
  }

  uploadImage() {
    if (this.capturedImage) {
      this.spinner.show();

      this.clientService.uploadDocumentPhoto(this.selectedFile).subscribe({
        next: (response) => {
          this.spinner.hide();
          this.toastrService.success('Upload de documento bem-sucedido.', '', { progressBar: true });
          this.router.navigate(['/logged/details']);
        },
        error: (error) => {
          this.spinner.hide();
          this.toastrService.error('Não foi possível fazer o upload, tente novamente mais tarde.', '', { progressBar: true });
        },
      });
    } else {
      this.toastrService.warning('Selecione uma imagem antes de fazer o upload.', '', { progressBar: true });
    }
  }

}
