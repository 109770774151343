<section class="container min-vh-100 pb-5">
    <div class="row justify-content-center">
        <div class="col-md-5 pt-5">
            <div class="d-flex justify-content-between align-items-center mb-5 fade-in">
                <svg (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                </svg>
                <p class="fw-700 font-size-16 color-secundary m-0 text-center" style="margin-left: -10px;">{{ 'CHECKOUT.TITLE' | translate }}</p>
                <div class="col-1"></div>
            </div>
            <p class="color-secundary fw-bold mt-5 fade-in">{{ 'CHECKOUT.ADD_MONEY' | translate }}</p>
            <div class="d-flex align-items-center fade-in">
                <i class="bi bi-wallet" style="color: #4A35E8 !important;"></i>
                <p class="fw-400 color-secundary mb-0 ms-3" *ngIf="valueCoin.coinSelect === 'BRL'">{{valueCoin.value
                    | currency: 'R$ '}}</p>
                <p class="fw-400 color-secundary mb-0 ms-3" *ngIf="valueCoin.coinSelect === 'USDT'">{{valueCoin.value
                    | currency: 'USDT '}}</p>
            </div>
            <p class="color-secundary fw-bold mt-4 fade-in">{{ 'CHECKOUT.PAYMENT_METHOD' | translate }}</p>
            <div class="card-add-money fade-in-left">
                <div class="d-flex justify-content-between align-items-center p-2">
                    <div class="d-flex align-items-center">
                        <img [src]="checkoutType.img">
                        <p *ngIf="checkoutType.name === 'Boleto'" class="fw-400 font-size-14 color-secundary mb-0">Boleto</p>
                        <p *ngIf="checkoutType.name === 'Pix'" class="fw-400 font-size-14 color-secundary mb-0">Pix</p>
                    </div>
                </div>
            </div>

            <div class="accordion accordion-flush mt-3 fade-in-left" id="accordionFlush">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                            <div class="d-flex justify-content-between align-items-center flex-fill">
                                <span class="color-secundary">{{ 'CHECKOUT.AMOUNT_TO_PAY' | translate }}</span>
                                <span class="fw-400 font-size-14 color-secundary me-3"
                                    *ngIf="valueCoin.coinSelect === 'BRL'">{{valueCoin.value
                                    | currency: 'R$ '}}</span>
                                <span class="fw-400 font-size-14 color-secundary me-3"
                                    *ngIf="valueCoin.coinSelect === 'USDT'">{{valueCoin.value
                                    | currency: 'USDT '}}</span>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse show"
                        data-bs-parent="#accordionFlush">
                        <div class="accordion-body">
                            <div *ngIf="checkoutType.name === 'Pix'" class="d-flex justify-content-between align-items-center">
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'CHECKOUT.TAXES' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ fee | currency: 'R$ '}}</p>
                            </div>
                            <div *ngIf="checkoutType.name === 'Pix'" class="d-flex justify-content-between align-items-center mt-2">
                                <p class="fw-400 font-size-14 color-secundary mb-0">Você receberá</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ (valueCoin.value - fee) | currency: 'R$ '}}</p>
                            </div>
                            <div *ngIf="checkoutType.name === 'Boleto'" class="d-flex justify-content-between align-items-center mb-2">
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'CHECKOUT_BOLETO.TAXES' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ fee | currency: 'R$ '}}</p>
                            </div>
                            <div *ngIf="checkoutType.name === 'Boleto'" class="d-flex justify-content-between align-items-center">
                                <p class="fw-400 font-size-14 color-secundary mb-0">Quantidade a receber</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ (valueCoin.value - fee).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="fw-400 font-size-14 color-secundary">{{ 'CHECKOUT.TRANSACTION_DURATION' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary" *ngIf="checkoutType.name === 'Pix'">{{ 'CHECKOUT.PIX_DURATION' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary" *ngIf="checkoutType.name === 'Boleto'">{{ 'CHECKOUT.BOLETO_DURATION' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="fw-bold color-secundary mt-4 fade-in">{{ 'CHECKOUT.PROMO_CODE' | translate }}</p>
            <input type="text" class="form-control bg-transparent py-3 color-secundary fade-in" style="border: 1px solid #364a3d;"
                placeholder="xxxxx-xxxxxx-xxxxxx">
            <div class="d-flex mt-5 pt-5 fade-in">
                <button class="btn button-green fw-800 font-size-14"
                    (click)="nextStep()">{{ 'CHECKOUT.CONTINUE_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</section>
