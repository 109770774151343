import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FeeService } from 'src/app/services/fee.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent {

  checkoutType: any;
  valueCoin: any;

  actualDate = new Date()
  actualDay = this.actualDate.getDate()
  actualMonth = this.actualDate.getMonth() + 1
  actualYear = this.actualDate.getFullYear()

  monthsWith31 = [1, 3, 5, 7, 8, 10, 12]

  invalidDate = true
  filledInput = false

  fee: number;
  constructor(
    private location: Location,
    private router: Router,
    private feeService: FeeService,
  ) { }

  ngOnInit(): void {
    let responseType: any = localStorage.getItem('checkoutType');
    this.checkoutType = JSON.parse(responseType);

    let responseValue: any = localStorage.getItem('valueCoin');
    this.valueCoin = JSON.parse(responseValue);

    this.fee = this.checkoutType.fee;
  }

  validData(): boolean {
    let day = this.actualDay + 3;
    let month = this.actualMonth;
    let year = this.actualYear;

    while (day > 28) {
      if (this.monthsWith31.includes(month)) {
        if (day > 31) {
          day -= 31;
          month++;
          if (month > 12) {
            month = 1;
            year++;
          }
        } else {
          break;
        }
      } else {
        if (month === 2 && (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0))) {
          if (day > 29) {
            day -= 29;
            month++;
          } else {
            break;
          }
        } else {
          if (day > 28) {
            day -= 28;
            month++;
          } else {
            break;
          }
        }
      }
    }

    const formattedDate = String(year) + '-' + String(month < 10 ? '0' + month : month) + '-' + String(day < 10 ? '0' + day : day);
    localStorage.setItem('dueDate', formattedDate);

    return true;
  }

  nextStep() {
    if (this.checkoutType.name === 'Pix') {
      this.router.navigate(['/logged/checkout-pix'])
    } else {
      if (this.validData()) {
        this.router.navigate(['/logged/checkout-boleto'])
      }
    }
  }

  back() {
    this.location.back()
  }

}
