import { Component, Input } from '@angular/core';
import { verificarForcaSenha } from 'src/app/auth/create-password/senha-utils';

@Component({
  selector: 'app-senha-progress',
  template: `
  <div class="progress-container">
    <div
      class="progress"
      [style.width.%]="forcaSenha"
      [ngClass]="{
        'progress-weak': forcaSenha < 50,
        'progress-medium': forcaSenha >= 50 && forcaSenha < 75,
        'progress-strong': forcaSenha >= 75
      }"
    ></div>
    <div class="strength-label" [ngClass]="{
      'weak-label': forcaSenha < 50,
      'medium-label': forcaSenha >= 50 && forcaSenha < 75,
      'strong-label': forcaSenha >= 75
    }">
      {{ forcaSenhaLabel }}
  </div>
`,
  styles: [
    `
    .progress-container {
      width: 100%;
      height: 6px;
      background-color: #f0f0f0;
      border-radius: 4px;
    }

    .progress {
      height: 100%;
      border-radius: 4px;
    }

    .progress-weak {
      background-color: red;
    }

    .progress-medium {
      background-color: yellow;
    }

    .progress-strong {
      background-color: green;
    }

    .strength-label {
        margin-top: 5px;
        text-align: end;
        font-weight: bold;
      }

      .weak-label {
        color: red;
        font-size: 10.75px
      }

      .medium-label {
        color: yellow;
        font-size: 10.75px
      }

      .strong-label {
        color: green;
        font-size: 10.75px
      }
  `,
  ],
})
export class SenhaProgressLoggedComponent {

  @Input() senha: string = '';
  forcaSenha: number = 0;
  forcaSenhaLabel: string = '';

  ngOnChanges() {
    this.verificarForca();
  }

  verificarForca() {
    this.forcaSenha = verificarForcaSenha(this.senha);
    this.atualizarForcaSenhaLabel();
  }

  atualizarForcaSenhaLabel() {
    if (this.forcaSenha < 50) {
      this.forcaSenhaLabel = 'Fraca';
    } else if (this.forcaSenha >= 50 && this.forcaSenha < 75) {
      this.forcaSenhaLabel = 'Média';
    } else {
      this.forcaSenhaLabel = 'Forte';
    }
  }

}
