import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssasTransferPixConfirmationRequestDto } from 'src/app/dtos/account/AssasTransferPixConfirmationRequest.dto';
import { CashOutPixRequestDto } from 'src/app/dtos/account/CashOutPixRequestDto';
import { AccountService } from 'src/app/services/account.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-pix-verify-code',
  templateUrl: './pix-verify-code.component.html',
  styleUrls: ['./pix-verify-code.component.scss']
})
export class PixVerifyCodeComponent {
  form: FormGroup;
  phone = localStorage.getItem('phone') || '+55 00 00000-0000'
  emailJSON = localStorage.getItem('user') || ''
  chosenMethod = localStorage.getItem('verificationMethod')
  email: string;
  PressBackspace: any;
  balanceCodeWallet: number

  // Transaction Info
  chosenValue: any
  keyType: string
  keyPIX: string
  PIXmessage: string

  invalidCode = false

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private accountService: AccountService,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService
  ) {
    this.form = this.formBuilder.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
    });

    this.email = JSON.parse(this.emailJSON).email

    const chosenValue = localStorage.getItem('chosenPixValue')
    if (chosenValue) this.chosenValue = JSON.parse(chosenValue)
    else this.router.navigate(['/logged/send-pix'])

    const keyType = localStorage.getItem('keyType')
    if (keyType) this.keyType = keyType
    else this.router.navigate(['/logged/send-pix'])

    const keyPIX = localStorage.getItem('keyPIX')
    if (keyPIX) this.keyPIX = keyPIX
    else this.router.navigate(['/logged/send-pix'])

    const PIXmessage = localStorage.getItem('PIXmessage')
    if (PIXmessage) this.PIXmessage = PIXmessage

  }

  ngOnInit() { }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != '') {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === '') {
        if (p != '') {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  confirmPin() {

    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    this.clientService.checkPin(code).subscribe({
      next: data => {
        this.verifyCode()

      },
      error: error => {
        console.log('error', error)
        this.toastrService.error('Senha Eletrônica incorreta!');
      }
    })
  }

  verifyCode() {
    this.ngxSpinnerService.show();

    const dto: CashOutPixRequestDto = {
      pixKey: this.keyPIX,
      payment: {amount:  this.chosenValue.value},
  
    }
    console.log(dto)
    this.accountService.pixCashOut(dto).subscribe({
      next: () => {
        this.ngxSpinnerService.hide();
        this.router.navigate(['/logged/send-pix-success']);
      },
      error: error => {
        this.ngxSpinnerService.hide();
        this.toastrService.error('Erro ao realizar transação PIX', '', { progressBar: true });
      }
    })
  }

  back() {
    this.location.back();
  }
}
