<section class="container-fluid p-4" style="background-color: #232323 !important;">
    <div class="d-flex justify-content-end">
        <i class="bi bi-x-lg cursor-pointer color-secundary" (click)="exit()"></i>
    </div>
    <p class="fw-bold color-secundary">Deseja compartilhar seu
        Feedback?</p>

    <p class="fw-light color-secundary mt-4">Nós nos preocupamos com a sua experiência, conte-nos mais para continuarmos
        melhorando.</p>

    <div class="d-flex justify-content-center">
        <i *ngFor="let item of arrStar" class="bi me-3" style="color: #F3BA2F; font-size: 32px !important;"
            [class]="startSelect < item ? 'bi-star' : 'bi-star-fill'" (click)="startSelect = item"></i>
    </div>

    <textarea class="form-control input-textarea-custom mt-4" placeholder="Digite seu feedback" rows="3"></textarea>

    <button class="btn button-green fw-800 font-size-14 mt-5">Enviar</button>

    <div class="d-flex justify-content-center mt-4">
        <button class="btn btn-black-custom letter-spacing-1" (click)="exit()">Cancelar</button>
    </div>
</section>