import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientVerifyEmailConfirmationRequest } from 'src/app/dtos/client/client-confirm-email-request.dto';
@Component({
  selector: 'app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss'],
})
export class ConfirmCodeComponent implements OnInit {
  @ViewChild('termsModal') termsModal: any;

  form: FormGroup;

  PressBackspace: any;
  phone: string = localStorage.getItem('phone') || '+55 00 00000-0000';

  msgkeepcalm = true;
  counterdown: any;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService,
  ) {
    this.form = this.formBuilder.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
      code5: ['', [Validators.required]],
      code6: ['', [Validators.required]],
      check: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.timer();
  }

  goBack() {
    this.location.back();
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != '') {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === '') {
        if (p != '') {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  openModalTerms() {
    this.termsModal = true
  }

  verifyCode() {
    this.ngxSpinnerService.show();
    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    if (code.length < 6) {
      this.toastrService.error('Código inválido.', '', { progressBar: true });
      this.ngxSpinnerService.hide();
      return;
    }

    this.clientService
      .confirmRegistration({ phone: this.phone, code: +code })
      .subscribe({
        next: (data) => {
          this.toastrService.success('Cadastro confirmado com sucesso', '', {
            progressBar: true,
          });
          this.authenticationService.setAuthenticatedUser(data);
          this.router.navigate(['/personal-data']);
          this.ngxSpinnerService.hide();
        },
        error: (err) => {
          this.toastrService.error('Código inválido.', '', {
            progressBar: true,
          });
          this.ngxSpinnerService.hide();
        },
      });
  }

  resendCode() {
    this.msgkeepcalm = true;
    this.ngxSpinnerService.show();

    const request: ClientVerifyEmailConfirmationRequest = {
      phone: this.phone,
      email: ''
    }

    this.clientService.resendCode(request).subscribe({
      next: () => {
        this.ngxSpinnerService.hide();
        this.toastrService.success('Código reenviado com sucesso', '', { progressBar: true });
        this.timer();
      },
      error: err => {
        this.toastrService.error('Ocorreu um erro ao reenviar o código', '', { progressBar: true });
        this.ngxSpinnerService.hide();
        this.timer();
      }
    });
  }

  timer() {
    this.msgkeepcalm = true;

    let seconds = 60;

    const timer = setInterval(() => {
      seconds--;
      this.counterdown = seconds
      if (seconds == 0) {
        this.msgkeepcalm = false;
        clearInterval(timer);
      }
    }, 1000);
  }

  exit() {
    this.termsModal = false;
  }
}