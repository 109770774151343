import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientService } from 'src/app/services/client.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-verify-code-logged',
  templateUrl: './verify-code-logged.component.html',
  styleUrls: ['./verify-code-logged.component.scss']
})
export class VerifyCodeLoggedComponent implements OnInit {
  form: FormGroup;

  PressBackspace: any;
  email = localStorage.getItem('newEmail') || '';
  phone = localStorage.getItem('newPhone') || '';

  // Resend code
  msgkeepcalm = true;
  counterdown: any;

  fromDetails = localStorage.getItem('fromDetails');

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService
  ) {
    this.form = this.formBuilder.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
      code5: ['', [Validators.required]],
      code6: ['', [Validators.required]],
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.timer()
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != '') {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === '') {
        if (p != '') {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  verifyCode() {
    this.ngxSpinnerService.show();
    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    if (code.length < 6) {
      this.toastrService.error('Código inválido.', '', { progressBar: true });
      this.ngxSpinnerService.hide();
      return;
    }

    if (this.email) {
      this.clientService
        .verifyCode({ email: this.email, code: +code })
        .subscribe({
          next: (data) => {
            this.toastrService.success('Email cadastrado com sucesso', '', {
              progressBar: true,
            });

            if (this.fromDetails) {
              this.router.navigate(['/logged/details']);
              localStorage.removeItem('fromDetails');
            } else {
              this.router.navigate(['/logged/home']);
            }

            this.ngxSpinnerService.hide();
            localStorage.removeItem('newEmail');
            localStorage.removeItem('newPhone');
          },
          error: (err) => {
            console.log(err);
            this.toastrService.error('Código inválido.', '', {
              progressBar: true,
            });
            this.ngxSpinnerService.hide();
          },
        });
    }

    if (this.phone) {
      this.clientService
        .verifyCode({ phone: this.phone, code: +code })
        .subscribe({
          next: (data) => {
            this.toastrService.success('Telefone cadastrado com sucesso', '', {
              progressBar: true,
            });
            this.router.navigate(['/logged/validation-id']);
            this.ngxSpinnerService.hide();
            localStorage.removeItem('newEmail');
            localStorage.removeItem('newPhone');
          },
          error: (err) => {
            console.log(err);
            this.toastrService.error('Código inválido.', '', {
              progressBar: true,
            });
            this.ngxSpinnerService.hide();
          },
        });
    }

  }

  resend() {
    if (this.phone) this.resendCodePhone()
    else this.resendCodeEmail()
  }

  resendCodeEmail() {
    this.msgkeepcalm = true;
    this.ngxSpinnerService.show();

    this.clientService.verifyEmailOrPhone({ email: this.email }).subscribe({
      next: (data: any) => {
        this.ngxSpinnerService.hide()
        this.toastrService.success('Código reenviado com sucesso', '', { progressBar: true })
        this.timer()
      },
      error: (error: any) => {
        this.toastrService.error('Ocorreu um erro ao reenviar o código', '', { progressBar: true })
        console.log(error)
        this.ngxSpinnerService.hide()
        this.timer()
      }
    });
  }

  resendCodePhone() {
    this.msgkeepcalm = true;
    this.ngxSpinnerService.show();

    this.clientService.verifyEmailOrPhone({ phone: this.email }).subscribe({
      next: (data: any) => {
        this.ngxSpinnerService.hide()
        this.toastrService.success('Código reenviado com sucesso', '', { progressBar: true })
        this.timer()
      },
      error: (error: any) => {
        this.toastrService.error('Ocorreu um erro ao reenviar o código', '', { progressBar: true })
        console.log(error)
        this.ngxSpinnerService.hide()
        this.timer()
      }
    });
  }

  timer() {
    this.msgkeepcalm = true;

    let seconds = 60;

    const timer = setInterval(() => {
      seconds--;
      this.counterdown = seconds
      if (seconds == 0) {
        this.msgkeepcalm = false;
        clearInterval(timer);
      }
    }, 1000);
  }
}