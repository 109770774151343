import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NumberModalComponent } from './number-modal/number-modal.component';
import { Router } from '@angular/router';
import { ShareDataService } from 'src/app/services/share-data.service';

@Component({
  selector: 'app-send-money-step3',
  templateUrl: './send-money-step3.component.html',
  styleUrls: ['./send-money-step3.component.scss'],
})
export class SendMoneyStep3Component {
  contacts = [
    {
      name: 'John Doe',
      phone: '+55 11 99999-9999',
    },
    {
      name: 'Dani a ela',
      phone: '+55 11 99999-9999',
    },
    {
      name: 'Maria da Silva',
      phone: '+55 11 99999-9999',
    },
  ];

  hideContactCard: boolean = true
  contactsFiltered = this.contacts;

  constructor(
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    private dataService: ShareDataService
  ) {}

  ngOnInit(): void {
    const local = localStorage.getItem('contacts');
    if (local) {
      this.contacts = this.contactsFiltered = JSON.parse(local);
    }
  }

  filterContacts(e: any) {
    const text = e.target.value;
    this.contactsFiltered = this.contacts.filter((contact) => {
      return contact.name.toLowerCase().includes(text.toLowerCase());
    });
  }

  openModal() {
    const modalRef = this.modalService.open(NumberModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.contacts.push(receivedEntry);
        this.contactsFiltered = this.contacts;
        localStorage.setItem('contacts', JSON.stringify(this.contacts));
      }
    });
  }

  userDestiny(value: string) {
    this.dataService.serviceData = value;
  }

  goToSendValue(name: string, phone: string) {
    const user = { name, phone };
    this.userDestiny(JSON.stringify(user));

    this.router.navigate(['logged/send-money-value']);
  }

  goBack() {
    this.location.back();
  }

  hideCard() {
    this.hideContactCard = false;
  }
}
