<section class="container min-vh-100 pb-5" *ngIf="pixResponse">
    <div class="row justify-content-center">
        <div class="col-md-5 pt-5">
            <div class="d-flex justify-content-between align-items-center mb-5 fade-in">
                <svg (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none" class="cursor-pointer">
                    <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                </svg>
                <p class="fw-700 font-size-16 color-secundary m-0 text-center" style="margin-left: -10px;">{{ 'CHECKOUT_PIX.PIX_SECTION_TITLE' | translate }}</p>
                <div class="col-1"></div>
            </div>
            <p class="color-secundary fw-bold mt-5 fade-in">{{ 'CHECKOUT_PIX.RESUME_TITLE' | translate }}</p>

            <!-- ACCORDION -->
            <div class="accordion accordion-flush mt-3 fade-in" id="accordionFlushPay">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <div class="d-flex justify-content-between align-items-center flex-fill">
                                <span class="color-secundary">{{ 'CHECKOUT_PIX.AMOUNT_TO_PAY' | translate }}</span>
                                <span class="fw-400 font-size-14 color-secundary me-3"
                                    *ngIf="valueCoin.coinSelect === 'BRL'">{{valueCoin.value
                                    | currency: 'R$ '}}</span>
                                <span class="fw-400 font-size-14 color-secundary me-3"
                                    *ngIf="valueCoin.coinSelect === 'USDT'">{{valueCoin.value
                                    | currency: 'USDT '}}</span>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse show"
                        data-bs-parent="#accordionFlushPay">
                        <div class="accordion-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'CHECKOUT.TAXES' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ fee | currency: 'R$ '}}</p>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <p class="fw-400 font-size-14 color-secundary mb-0">Você receberá</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ (valueCoin.value - fee) | currency: 'R$ '}}</p>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="fw-light color-secundary">{{ 'CHECKOUT_PIX.TRANSACTION_DURATION' | translate }}</p>
                                <p class="fw-light color-secundary">{{ 'CHECKOUT_PIX.PIX_TRANSACTION_DURATION' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- QR CODE -->
            <div class="d-flex justify-content-center mt-5 mb-5 fade-in-left">
                <qrcode [cssClass]="'qrcode'" [qrdata]="pixResponse.pix" [colorDark]="'#4A35E8'" [colorLight]="'#FFFFFF'" [width]="225" [imageHeight]="225"
                [errorCorrectionLevel]="'M'" style="border-radius: 10px;"></qrcode>
            </div>

            <!-- PIX CODE -->
            <p class="fw-bold text-center color-secundary mt-4 fade-in-left" >{{ 'CHECKOUT_PIX.QR_CODE_TITLE' | translate }}</p>
            <div class="d-flex justify-content-between align-items-start border-green rounded-3 fade-in-left">
                <p class="form-control bg-transparent text-break py-3 color-secundary border-0 mb-0">
                    {{ pixToDisplay }}
                </p>
                <i class="bi bi-files color-secundary me-3 mt-3 cursor-pointer" (click)="copyValue()"></i>
            </div>
            <p class="text-center mt-2 color-lightgreen fade-in">{{ 'CHECKOUT_PIX.PIX_CODE_VALIDITY' | translate }}</p>

            <!-- BUTTON -->
            <div class="d-flex flex-column pt-3 fade-in">
                <p class="text-center color-secundary mt-5 pt-5">{{ 'CHECKOUT_PIX.WAIT_FOR_PAYMENT_MESSAGE' | translate }}</p>
                <button class="btn button-green fw-800 font-size-14" (click)="continue()">{{ 'CHECKOUT_PIX.CONTINUE_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</section>
