import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-buy-success',
  templateUrl: './buy-success.component.html',
  styleUrls: ['./buy-success.component.scss']
})
export class BuySuccessComponent {

  arrStar = [1, 2, 3, 4, 5];

  startSelect: number = 0

  constructor(
    private modalService: NgbModal
  ) { }

  exit() {
    this.modalService.dismissAll()
  }

}
