<section class="container min-vh-100 h-100">
    <div class="row justify-content-center pt-5">
        <div class="col-md-5">
            <div class="d-flex justify-content-between align-items-center">
                <div [routerLink]="['/logged/details']">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                    </svg>
                </div>
                <h1 class="fw-500 font-size-16 color-secundary cursor-click text-center mb-0 mt-1">
                    {{ 'CHANGE_PASSWORD.PERSONAL_INFORMATION' | translate }}
                </h1>
                <div class="col-md-1"></div>
            </div>
            <div class="fw-400 font-size-16 color-secundary text-center mb-5 mt-3">
                <p>{{ 'CHANGE_PASSWORD.CREATE_STRONG_PASSWORD' | translate }}</p>
            </div>
            <form [formGroup]="form">
                <div class="my-3">
                    <label class="form-label fw-700 font-size-14 color-secundary">{{ 'CHANGE_PASSWORD.ENTER_PASSWORD' | translate }}</label>
                    <div class="input-group" style="display: inline-flex;">
                        <input
                            [ngClass]="form.controls['password'].invalid && !form.controls['password'].pristine ? 'input-transparent-login-number-red' : 'input-transparent'"
                            type="{{ mostrarSenha ? 'text' : 'password' }}" formControlName="password"
                            class="input-transparent width-86" [(ngModel)]="senhass" name="senha"
                            placeholder="{{ 'CHANGE_PASSWORD.ENTER_PASSWORD' | translate }}" />
                        <button class="btn btn-outline-secondary" type="button" (click)="toggleMostrarSenha()">
                            <span class="bi" [ngClass]="mostrarSenha ? 'bi-eye' : 'bi-eye-slash'"></span>
                        </button>
                    </div>
                    <div *ngIf="form.controls['password'].invalid && !form.controls['password'].pristine"
                        class="d-flex fw-400 font-size-11 color-red pt-1">{{ 'CHANGE_PASSWORD.MINIMUM_6_CHARACTERS' | translate }}</div>
                </div>
                <div class="my-3">
                    <label class="form-label fw-700 font-size-14 color-secundary">{{ 'CHANGE_PASSWORD.ENTER_NEW_PASSWORD' | translate }}</label>
                    <div class="input-group" style="display: inline-flex;">
                        <input
                            [ngClass]="form.controls['newPassword'].invalid && !form.controls['newPassword'].pristine ? 'input-transparent-login-number-red' : 'input-transparent'"
                            type="{{ mostrarSenha ? 'text' : 'password' }}" formControlName="newPassword"
                            class="input-transparent width-86" [(ngModel)]="senha" name="senha"
                            placeholder="{{ 'CHANGE_PASSWORD.ENTER_NEW_PASSWORD' | translate }}" />
                        <button class="btn btn-outline-secondary" type="button" (click)="toggleMostrarSenha()">
                            <span class="bi" [ngClass]="mostrarSenha ? 'bi-eye' : 'bi-eye-slash'"></span>
                        </button>
                    </div>
                    <div *ngIf="form.controls['newPassword'].invalid && !form.controls['newPassword'].pristine"
                        class="d-flex fw-400 font-size-11 color-red pt-1">{{ 'CHANGE_PASSWORD.MINIMUM_6_CHARACTERS' | translate }}</div>
                </div>
                <div class="mb-5">
                    <label class="form-label fw-700 font-size-14 color-secundary">{{ 'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | translate }}</label>
                    <div class="input-group" style="display: inline-flex;">
                        <input
                            [ngClass]="form.controls['newPassword'].value !== form.controls['confirmNewPassword'].value ? 'input-transparent-login-number-red' : 'input-transparent'"
                            type="{{ mostrarSenha ? 'text' : 'password' }}" formControlName="confirmNewPassword"
                            class="input-transparent width-86" [(ngModel)]="senhas" name="senha"
                            placeholder="{{ 'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | translate }}" />
                        <button class="btn btn-outline-secondary" type="button" (click)="toggleMostrarSenha()">
                            <span class="bi" [ngClass]="mostrarSenha ? 'bi-eye' : 'bi-eye-slash'"></span>
                        </button>
                    </div>
                    <div *ngIf="form.controls['newPassword'].value !== form.controls['confirmNewPassword'].value && !form.controls['confirmNewPassword'].pristine"
                        class="d-flex fw-400 font-size-11 color-red pt-1">{{ 'CHANGE_PASSWORD.PASSWORDS_DO_NOT_MATCH' | translate }}</div>
                </div>
                <div class="d-flex flex-column pt-5">
                    <button [disabled]="form.controls['password'].value === '' || 
                    form.controls['newPassword'].value !== form.controls['confirmNewPassword'].value"
                        class="button-green fw-800 font-size-14" (click)="confirm()">{{ 'CHANGE_PASSWORD.CONFIRM' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</section>
