<section class="container min-vh-100 pb-5">

  <ng-container *ngIf="loading else boletoSection">
    <div class="row justify-content-center fade-in">
        <div class="col-md-5 pt-5">
  
          <div class="d-flex justify-content-between align-items-center mb-5 fade-in">
            <svg style="opacity: 0;" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
            </svg>
            <p class="fw-700 font-size-16 color-secundary m-0 text-center" style="margin-left: -10px;">{{ 'CHECKOUT_BOLETO.TITLE' | translate }}</p>
            <div class="col-1"></div>
          </div>
  
          <p class="color-secundary text-center fw-bold mt-5">{{ 'CHECKOUT_BOLETO.PROCESSING' | translate }}</p>
          <p class="color-secundary text-center fw-light mt-3">{{ 'CHECKOUT_BOLETO.PLEASE_WAIT' | translate }}</p>
          <div class="d-flex justify-content-center mt-5 pt-5">
              <div class="spinner-grow" style="color: #4A35E8 !important;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
          </div>
          
        </div>
    </div>
  </ng-container>

  <ng-template #boletoSection>
    <div class="row justify-content-center fade-in">
      <div class="col-md-5 pt-5">
        <div class="d-flex justify-content-between align-items-center mb-5 fade-in">
            <svg (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none" class="cursor-pointer">
                <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
            </svg>
            <p class="fw-700 font-size-16 color-secundary m-0 text-center" style="margin-left: -10px;">{{ 'CHECKOUT_BOLETO.TITLE' | translate }}</p>
            <div class="col-1"></div>
        </div>
        <p class="color-secundary fw-bold mt-5 fade-in">{{ 'CHECKOUT_BOLETO.SUMMARY' | translate }}:</p>
        
        <div class="d-flex flex-column">
          <p class="fw-500 font-size-16 color-secundary m-0">Data de vencimento</p>
          <p class="fw-500 font-size-14 color-secundary mb-0 mt-1">{{ dueDate }}</p>
        </div>

        <!-- ACCORDION -->
        <div class="accordion accordion-flush mt-3 fade-in" id="accordionFlushPay">
          <div class="accordion-item">
              <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      <div class="d-flex justify-content-between align-items-center flex-fill">
                          <span class="color-secundary">{{ 'CHECKOUT_BOLETO.AMOUNT_TO_PAY' | translate }}</span>
                          <span class="fw-400 font-size-14 color-secundary me-3"
                              *ngIf="valueCoin.coinSelect === 'BRL'">{{valueCoin.value
                              | currency: 'R$ '}}</span>
                          <span class="fw-400 font-size-14 color-secundary me-3"
                              *ngIf="valueCoin.coinSelect === 'USDT'">{{valueCoin.value
                              | currency: 'USDT '}}</span>
                      </div>
                  </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse show"
                  data-bs-parent="#accordionFlushPay">
                  <div class="accordion-body">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                          <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'CHECKOUT_BOLETO.TAXES' | translate }}</p>
                          <p class="fw-400 font-size-14 color-secundary mb-0">{{ fee | currency: 'R$ '}}</p>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                          <p class="fw-400 font-size-14 color-secundary mb-0">Quantidade a receber</p>
                          <p class="fw-400 font-size-14 color-secundary mb-0">{{ (valueCoin.value - fee).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between align-items-center">
                          <p class="fw-400 font-size-14 color-secundary">{{ 'CHECKOUT_BOLETO.TRANSACTION_DURATION' | translate }}</p>
                          <p class="fw-400 font-size-14 color-secundary" *ngIf="checkoutType.name === 'Pix'">{{ 'CHECKOUT_BOLETO.PIX_DURATION' | translate }}</p>
                          <p class="fw-400 font-size-14 color-secundary" *ngIf="checkoutType.name === 'Boleto'">{{ 'CHECKOUT_BOLETO.BOLETO_DURATION' | translate }}</p>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <!-- PAYMENT CODE -->
        <p class="fw-bold color-secundary mt-4 fade-in-left">Código de barras do boleto</p>
        <div class="d-flex justify-content-between align-items-start rounded-3 fade-in-left" style="border: 1px solid #4A35E8;">
          <span class="form-control bg-transparent text-break py-3 color-secundary border-0 mb-0">{{boletoResponse.barCode}}</span>
            <i class="bi bi-files color-secundary me-3 mt-3 cursor-pointer" (click)="copyValue()"></i>
        </div>

        <p class="fw-700 font-size-14 text-center color-secundary pt-4">ou</p>

        <p class="fw-bold color-secundary fade-in-left">{{ 'CHECKOUT_BOLETO.PAYMENT_CODE' | translate }}</p>
        <div class="d-flex justify-content-between align-items-start rounded-3 fade-in-left" style="border: 1px solid #4A35E8;">
            <a [href]="boletoResponse.bankSlipUrl" target="_blank" class="form-control bg-transparent text-break py-3 color-secundary border-0 mb-0 text-decoration-underline">
                {{ boletoResponse.bankSlipUrl }}
            </a>
            <i class="bi bi-files color-secundary me-3 mt-3 cursor-pointer" (click)="downloadBoleto()"></i>
        </div>
        <p class="text-center mt-2 fade-in" style="color: #8BA78F !important;">{{ 'CHECKOUT_BOLETO.PAYMENT_CODE_DESCRIPTION' | translate }}</p>

        <!-- BUTTON -->
        <div class="d-flex flex-column fade-in">
            <p class="text-center color-secundary"></p>
            <button (click)="back()" class="btn button-green fw-800 font-size-14">{{ 'CHECKOUT_BOLETO.CONTINUE_BUTTON' | translate }}</button>
        </div>
      </div>
    </div>
  </ng-template>

</section>
