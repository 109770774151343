<section class="container min-vh-100 pt-3">
  <div class="row justify-content-center">
    <div class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center fade-in">
        <svg (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          PIX
        </h1>
        <div class="col-1"></div>
      </div>

      <!-- TEXT -->
      <div class="px-2">
        <h4 class="fw-700 text-center font-size-18 color-secundary mt-5 mb-3 pb-3 letter-spacing-080">Para onde devemos enviar o código?</h4>
      </div>

      <!-- OPTIONS -->
      <div class="px-2">
        <button (click)="changeMethod('EMAIL')" class="py-3 w-100 text-center color-secundary border-green border-radius-10 fw-800 font-size-14 mb-2" [class.bg-demi-transparent]="chosenMethod === 'EMAIL'" [class.bw-2]="chosenMethod === 'EMAIL'" style="background-color: transparent;">Email</button>
        <button (click)="changeMethod('PHONE')" class="py-3 w-100 text-center color-secundary border-green border-radius-10 fw-800 font-size-14 mb-2" [class.bg-demi-transparent]="chosenMethod === 'PHONE'" [class.bw-2]="chosenMethod === 'PHONE'" style="background-color: transparent;">Telefone</button>
      </div>

      <!-- BUTTONS -->
      <div class="px-2 d-flex flex-column py-5 fade-in">
        <button (click)="continue()" class="button-green fw-800 font-size-14 mt-3">Enviar</button>
        <button [routerLink]="['/logged/home']" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
      </div>
    </div>
  </div>
</section> 