<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col-md-5">
            <ng-container *ngIf="valueMoney else valueScreen">
                <div class="row pt-5 fade-in">
                    <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                                fill="#060A30" />
                        </svg>
                    </div>
                    <div class="col-8 d-flex justify-content-center align-items-center">
                        <p class="fw-700 font-size-16 color-secundary m-0">{{ 'ADD_MONEY.TITLE1' | translate }}</p>
                    </div>
                </div>
                <div class="card-add-money mt-5 mb-2 fade-in-left cursor-pointer"
                    (click)="selectForCheckout('../../../assets/icon/icon-pix.svg', 'Pix')">
                    <div class="d-flex justify-content-between align-items-center p-2">
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/icon/icon-pix.svg">
                            <div class="d-flex flex-column">
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'ADD_MONEY.PIX.TITLE' | translate }}
                                </p>
                                <p class="fw-400 font-size-12 color-gray mb-0">{{ 'ADD_MONEY.PIX.DESCRIPTION' |
                                    translate }}</p>
                            </div>
                        </div>
                        <img src="../../../assets/icon/arrow-right.svg">
                    </div>
                </div>
                <!-- <div class="card-add-money mb-2 fade-in-left cursor-pointer"
                    (click)="selectForCheckout('../../../assets/icon/icon-boleto.svg', 'Boleto')">
                    <div class="d-flex justify-content-between align-items-center p-2">
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/icon/icon-boleto.svg">
                            <div class="d-flex flex-column">
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'ADD_MONEY.BOLETO.TITLE' | translate
                                    }}</p>
                                <p class="fw-400 font-size-12 color-gray mb-0">{{ 'ADD_MONEY.BOLETO.DESCRIPTION' |
                                    translate }}</p>
                            </div>
                        </div>
                        <img src="../../../assets/icon/arrow-right.svg">
                    </div>
                </div> -->

                <div class="d-flex flex-column justify-content-end align-items-center mt-5 pt-4 pb-5 mb-5 fade-in">
                    <p class="fw-400 font-size-16 color-secundary text-center pt-5">{{ 'ADD_MONEY.PAYMENT_NOT_FOUND.TEXT' |
                        translate }}</p>
                    <a class="fw-700 font-size-14 letter-spacing-1 color-blue text-center cursor-pointer">{{
                        'ADD_MONEY.PAYMENT_NOT_FOUND.NOTIFY' | translate }}</a>
                </div>
            </ng-container>

            <ng-template #valueScreen>
                <div class="row pt-5">
                    <div (click)="back()" class="col-2 align-items-start cursor-pointer">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                                fill="#060A30" />
                        </svg>
                    </div>
                    <div class="col-8 d-flex justify-content-center align-items-center">
                        <p class="fw-700 font-size-16 color-secundary m-0">{{ 'ADD_MONEY.TITLE1' | translate }}</p>
                    </div>
                </div>
                <form [formGroup]="form" class="mt-5">
                    <p class="fw-500 font-size-14 color-gray fade-in-left">{{ 'ADD_MONEY.AMOUNT.LABEL' | translate }}
                    </p>
                    <label for="" class="fw-700 font-size-14 color-secundary fade-in">{{ 'ADD_MONEY.AMOUNT.PLACEHOLDER' |
                        translate }}</label>
                    <div class="border border-dark-green rounded-3 py-2 px-1 d-flex align-items-center mt-2 fade-in">
                        <div class="d-flex align-items-center justify-content-start">
                            <img [src]="coinSelect.img" alt="" height="20px" class="mx-2">
                            <span class="fw-400 mt-1"
                                style="color: #9E9D9D; font-size: 0.875rem; letter-spacing: 0.01469rem;">{{
                                coinSelect.name }}</span>
                        </div>
                        <input type="text"
                            class="fw-700 font-size-16 form-control border-0 bg-transparent color-secundary cursor-pointer"
                            formControlName="valuePay" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision: '2' }">
                    </div>
                    <p class="fw-500 font-size-14 color-gray fade-in-left mb-0 mt-2">Valor mínimo de transação: R$ 5,00</p>
                    <p class="fw-500 font-size-14 color-gray fade-in-left mt-1">Taxa de transação: {{ fee | currency: 'R$ '}}</p>

                    <div class="mt-3 fade-in-left">
                        <span class="fw-700 font-size-14 color-secundary">{{ 'ADD_MONEY.AMOUNT.SUGGESTED_VALUES' | translate
                            }}</span>
                        <div class="d-flex justify-content-between align-items-center">
                            <button class="bg-transparent color-primary border-none fw-700 font-size-14 mt-3"
                                (click)="selectValue(50)">50</button>
                            <button class="bg-transparent color-primary border-none fw-700 font-size-14 mt-3"
                                (click)="selectValue(100)">100</button>
                            <button class="bg-transparent color-primary border-none fw-700 font-size-14 mt-3"
                                (click)="selectValue(200)">200</button>
                            <button class="bg-transparent color-primary border-none fw-700 font-size-14 mt-3"
                                (click)="selectValue(300)">300</button>
                            <button class="bg-transparent color-primary border-none fw-700 font-size-14 mt-3"
                                (click)="selectValue(500)">500</button>
                            <button class="bg-transparent color-primary border-none fw-700 font-size-14 mt-3"
                                (click)="selectValue(1000)">1000</button>
                        </div>
                    </div>

                    <div class="mt-5 fade-in">
                        <button [disabled]="form.invalid || +form.controls['valuePay'].value < 5" class="btn button-green fw-800 font-size-14"
                            (click)="submit()">{{ 'ADD_MONEY.AMOUNT.CONTINUE_BUTTON' | translate }}</button>
                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</section>