import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AssasPaymentGeneratePaymentTicketRequestDto, AssasPaymentGeneratePixRequestDto, AssasPixCheckoutResponse } from '../dtos/payment/assas-payment-generate-pix-request.dto';
import { Observable, catchError, map } from 'rxjs';
import { PaymentConfirmationDto } from '../dtos/payment/payment-confirmation.dto';

@Injectable({
  providedIn: 'root'
})
export class FeeService extends BaseService {
  walletApiUrl: string = `${environment.api.path}/fee`

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    super();
  }

  list() {
    return this.httpClient
      .get(`${this.walletApiUrl}/list`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getPixFee(){
    return this.httpClient
      .get(`${this.walletApiUrl}/pix`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getTicketFee(){
    return this.httpClient
      .get(`${this.walletApiUrl}/ticket`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
