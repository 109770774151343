import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  selectLang: string = 'PT-BR';
  touchIDEnabled: boolean = false;
  rememberMeEnabled: boolean = false;

  user?: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,

  ) {
    translate.addLangs(['en', 'pt', 'es']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang?.match(/en|pt|es/) ? browserLang || '' : 'en');
    this.handlerSelectedLanguage(
      browserLang?.match(/en|pt|es/) ? browserLang || '' : 'en'
    );
  }

  ngOnInit(): void {
    const rememberMeEnabled = localStorage.getItem('rememberMeEnabled');
    if (rememberMeEnabled === 'true') {
      this.rememberMeEnabled = true;
    } else if (rememberMeEnabled === 'false') {
      this.rememberMeEnabled = false;
    } else {
      this.rememberMeEnabled = false;
    }

    const storedLanguage = localStorage.getItem('selectedLanguage');

    if (storedLanguage) {
      this.translate.use(storedLanguage);
      this.handlerSelectedLanguage(storedLanguage);
    }
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.handlerSelectedLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  }

  handlerSelectedLanguage(lang: string) {
    if (lang === 'en') {
      this.selectLang = 'EN-US';
      return;
    }
    if (lang === 'es') {
      this.selectLang = 'ES-ES';
      return;
    }
    this.selectLang = 'PT-BR';
  }

  goToConfig() {
    this.router.navigate(['/request-card'])
  }

  toggleTouchID() {
    if (this.touchIDEnabled) {
      console.log('Touch ID ativado');
    } else {
      console.log('Touch ID desativado');
    }
  }

  toggleRemember() {
    localStorage.setItem('rememberMeEnabled', this.rememberMeEnabled.toString());
  }

  logOut() {
    if (this.rememberMeEnabled) {
      localStorage.setItem('rememberMeOnLogout', 'true');
      this.router.navigate(['/']);
    } else {
      this.authenticationService.removeAuthenticatedUser();
      this.router.navigate(['/']);
    }
  }

}
