<section class="container min-vh-100">
  <div class="row justify-content-center">
    <div class="col-lg-6">
      <div class="d-flex justify-content-between align-items-center mt-3 mb-5">
        <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
          </svg>
        </div>
        <p class="text-center color-secundary fw-bold mb-0">Câmbio Monetário</p>
        <i (click)="goToComents()" class="bi bi-chat-dots color-secundary cursor-pointer"></i>
      </div>
      <p class="fw-400 font-size-16 color-secundary pt-5">Indique o valor para a transação</p>
      <form [formGroup]="form">
        <label for class="fw-bold color-secundary">Venda</label>
        <div class="border border-dark-green rounded-3 d-flex align-items-center mt-2">
          <input type="text" class="form-control border-0 bg-transparent color-secundary"
            formControlName="valuePay" currencyMask
            [options]="{ prefix: '', suffix: ' ' + 'USDT', thousands: '', decimal: '.', precision: 2 }">
        </div>
        <p class="color-secundary text-end" style="font-size: 12px;">Disponível US$ 0,00 </p>

        <div class="d-flex justify-content-center align-items-center pt-1">
          <img src="../../../assets/icon/exchange-icon.svg">
        </div>

        <label for class="fw-bold color-secundary">Compra</label>
        <div class="border border-dark-green rounded-3 d-flex align-items-center mt-2">
          <input type="text" class="form-control border-0 bg-transparent color-secundary"
            formControlName="valueRecevice" currencyMask
            [options]="{ prefix: '', suffix: ' ' + 'BRL', thousands: '', decimal: '.', precision: 2 }">
        </div>
        <p class="color-secundary text-end" style="font-size: 12px;">Disponível R$ 0,00 </p>

        <div class="d-flex flex-column justify-content-end align-items-center mt-5 pt-5 pb-5 mb-5">

          <button class="btn button-green fw-800 font-size-14" (click)="submit()">Confirmar</button>
          <a class="fw-700 font-size-14 letter-spacing-1 color-blue text-center mt-4">Leia nossos termos e condições</a>
        </div>
      </form>
    </div>
  </div>
</section>