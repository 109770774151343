import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientInterface } from 'src/app/interface/client.interface';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit {
  oldPINForm: FormGroup;
  newPINForm: FormGroup;
  repeatPINForm: FormGroup;
  client!: ClientInterface;
  showPin: boolean = false;

  oldEqualNewPin = false;
  wrongRepeatPin = false;

  PressBackspace: any;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.oldPINForm = this.formBuilder.group({
      oldCode1: ['', [Validators.required]],
      oldCode2: ['', [Validators.required]],
      oldCode3: ['', [Validators.required]],
      oldCode4: ['', [Validators.required]]
    });
    this.oldPINForm.disable()

    this.newPINForm = this.formBuilder.group({
      newCode1: ['', [Validators.required]],
      newCode2: ['', [Validators.required]],
      newCode3: ['', [Validators.required]],
      newCode4: ['', [Validators.required]]
    });

    this.repeatPINForm = this.formBuilder.group({
      repeatCode1: ['', [Validators.required]],
      repeatCode2: ['', [Validators.required]],
      repeatCode3: ['', [Validators.required]],
      repeatCode4: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.clientService.getUserInfo().subscribe({
      next: (data) => {
        if (data?.kyc?.userStatus !== 'CHECKED') {
          this.toastrService.error('Verifique sua identidade primeiro!');
          this.router.navigate(['/logged/my-account/account-details']);
        }

        this.client = data;

        if (this.client.pin) {
          this.oldPINForm.enable()
        }
      },
    });
  }

  back() {
    this.location.back();
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != '') {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === '') {
        if (p != '') {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  confirm() {
    if (this.newPINForm.invalid || this.repeatPINForm.invalid) return;

    const newPin = Object.values(this.newPINForm.value)
      .join('')
      .replace(/[^0-9.]+/g, '');
    const repeatPin = Object.values(this.repeatPINForm.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    if (newPin !== repeatPin) {
      this.toastrService.error('As senhas não conferem!');
      this.wrongRepeatPin = true
      return
    }
    this.wrongRepeatPin = false

    if (this.client.pin) {
      const oldPin = Object.values(this.oldPINForm.value)
        .join('')
        .replace(/[^0-9.]+/g, '');

      if (newPin === oldPin) {
        this.toastrService.error('As senhas não conferem!');
        this.oldEqualNewPin = true
        return
      }
      this.oldEqualNewPin = false

      this.ngxSpinnerService.show();
      this.clientService.checkPin(oldPin).subscribe({
        next: (data) => {
          if (data === true) {
            this.ngxSpinnerService.hide();
            this.updatePin(newPin, oldPin)
          } else {
            this.toastrService.error('A senha antiga informada não é válida!');
            this.ngxSpinnerService.hide();
            return
          }
        },
        error: () => {
          this.ngxSpinnerService.hide();
          this.toastrService.error('Erro ao verificar senha antiga, tente novamente mais tarde!');
        },
      });
    }

    else {
      this.ngxSpinnerService.show();
      this.clientService.createPin(newPin).subscribe({
        next: (data) => {
          this.ngxSpinnerService.hide();
          this.toastrService.success('Senha Eletrônica criada com sucesso!');
          this.router.navigate(['/logged/my-account/account-details']);
        },
        error: (error) => {
          this.ngxSpinnerService.hide();
          this.toastrService.error('Erro ao criar Senha Eletrônica!');
        },
      });
    }
  }

  updatePin(newPin: string, oldPin: string) {
    this.clientService.updatePin(newPin, oldPin).subscribe({
      next: (data) => {
        this.ngxSpinnerService.hide();
        this.toastrService.success('Senha Eletrônica atualizada com sucesso!');
        this.router.navigate(['/logged/my-account/account-details']);
      },
      error: (error) => {
        this.ngxSpinnerService.hide();
        this.toastrService.error('Erro ao atualizar Senha Eletrônica!');
      },
    });
  }
}