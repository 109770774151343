export function verificarForcaSenha(senha: string): number {
    const comprimentoMinimo = 8;
    const temMaiuscula = /[A-Z]/.test(senha);
    const temMinuscula = /[a-z]/.test(senha);
    const temNumero = /\d/.test(senha);
    const temCaractereEspecial = /[^A-Za-z0-9]/.test(senha);
  
    let forca = 0;
    if (temMaiuscula) forca++;
    if (temMinuscula) forca++;
    if (temNumero) forca++;
    if (temCaractereEspecial) forca++;
  
    return Math.min(forca * 25, 100);
  }
  