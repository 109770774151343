import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfer-success',
  templateUrl: './transfer-success.component.html',
  styleUrls: ['./transfer-success.component.scss']
})
export class TransferSuccessComponent {

  constructor(
    private router: Router
  ) { }

  submit() {
    localStorage.removeItem('chosenTransferValue');
    localStorage.removeItem('waySendCode');
    localStorage.removeItem('nameToTransfer');
    localStorage.removeItem('phoneToTransfer');
    localStorage.removeItem('emailToTransfer');

    this.router.navigate(['/logged/home'])
  }

}
