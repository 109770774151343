import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenExchangeService {
  private apiKey = environment.open_exchange_key;
  private apiUrl = `https://openexchangerates.org/api/latest.json?app_id=${this.apiKey}&symbols=USD,BRL`;

  constructor(private http: HttpClient) {}

  getExchangeRates() {
    return this.http.get<any>(this.apiUrl);
  }
}
