export const environment = {
  // path: 'https://bitbentz.codefabrik.dev',
  //encrypt_key: '5708c8d4-0b2a-4cbd-bea4-99981079020a',
  production: false,
  api: {
    path: 'https://bitbentz.codefabrik.dev',
  },
  encrypt_key: '5708c8d4-0b2a-4cbd-bea4-99981079020a',
  jwt_key: 'd2098e2e-b06e-4f2d-87b8-884f5d2efcb1',
  open_exchange_key: '1ef782a006b241c79676035025f3690b',
  socket: {
    notification: {
      host: 'https://bitbentz.codefabrik.dev',
      path: '/socket/notification',
    }
  },
};
