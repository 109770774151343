import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'code-fabrik-wallet';

  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'pt', 'es']);
    
    // Define 'pt' como idioma padrão se for undefined
    const browserLang = translate.getBrowserLang() || 'pt';
    translate.use(browserLang.match(/en|pt|es/) ? browserLang : 'pt');
  }
}
