<section class="container min-vh-100 pb-5">
    <div class="row justify-content-center">
        <div *ngIf="showSplashCripto === 1" class="col-md-5">
            <div class="fade-in row pt-5">
                <div class="col-2 align-items-center">
                    <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="88px"></p>
                </div>
            </div>
            <div class="fade-in-left d-flex justify-content-center my-5">
                <img src="../../../assets/images/image-rescue-cripto.svg">
            </div>

            <p class="fade-in fw-700 font-size-16 text-center letter-spacing-1 color-secundary">{{ 'SPLASH_CRIPTO.BUY_AND_SALE' | translate }}</p>
            <div class="fade-in text-center letter-spacing-1">
                <p class="fw-400 font-size-14 color-secundary">{{ 'SPLASH_CRIPTO.CRYPTO_IS_DIGITAL_MONEY' | translate }}</p>
            </div>


            <div class="fade-in d-flex flex-column">
                <button (click)="changeSplashCripto(2)" class="button-green fw-800 font-size-14 mt-3">{{ 'SPLASH_CRIPTO.CONTINUE' | translate }}</button>
                <button (click)="dismissAnGo()"
                    class="bg-transparent letter-spacing-1 color-primary border-none fw-800 font-size-14 mt-3">{{ 'SPLASH_CRIPTO.SKIP' | translate }}</button>
            </div>
        </div>

        <div *ngIf="showSplashCripto === 2" class="col-md-5">
            <div class="fade-in row pt-5">
                <div class="col-2 align-items-center">
                    <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="88px"></p>
                </div>
            </div>
            <div class="fade-in-left d-flex justify-content-center my-5">
                <img src="../../../assets/images/image-rescue-cripto2.svg">
            </div>

            <p class="fade-in fw-700 font-size-16 text-center letter-spacing-1 color-secundary">{{ 'SPLASH_CRIPTO.OFFER_WIDE_PAYMENT_METHODS' | translate }}</p>
            <p class="fade-in fw-400 font-size-14 color-secundary text-center letter-spacing-1">{{ 'SPLASH_CRIPTO.UPDATE_YOUR_WALLET' | translate }}</p>

            <div class="fade-in d-flex flex-column">
                <button (click)="changeSplashCripto(3)" class="button-green fw-800 font-size-14 mt-3">{{ 'SPLASH_CRIPTO.CONTINUE' | translate }}</button>
                <button (click)="dismissAnGo()"
                    class="bg-transparent letter-spacing-1 color-primary border-none fw-800 font-size-14 mt-3">{{ 'SPLASH_CRIPTO.SKIP' | translate }}</button>
            </div>
        </div>

        <div *ngIf="showSplashCripto === 3" class="col-md-5">
            <div class="fade-in row pt-5">
                <div class="col-2 align-items-center">
                    <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="88px"></p>
                </div>
            </div>
            <div class="fade-in-left d-flex justify-content-center my-5">
                <img src="../../../assets/images/image-rescue-cripto3.svg">
            </div>

            <p class="fade-in fw-700 font-size-16 text-center letter-spacing-1 color-secundary">{{ 'SPLASH_CRIPTO.SIMPLE_SECURE_TRANSPARENT' | translate }}</p>
            <p class="fade-in fw-400 font-size-14 color-secundary text-center letter-spacing-1">{{ 'SPLASH_CRIPTO.ACQUIRE_CRYPTO_WITH_2_CLICKS' | translate }}</p>

            <div class="fade-in d-flex flex-column">
                <button (click)="dismissAnGo()"
                    class="button-green fw-800 font-size-14 mt-3">{{ 'SPLASH_CRIPTO.CONTINUE' | translate }}</button>
                <button (click)="dismissAnGo()"
                    class="bg-transparent letter-spacing-1 color-primary border-none fw-800 font-size-14 mt-3">{{ 'SPLASH_CRIPTO.SKIP' | translate }}</button>
            </div>
        </div>

    </div>
</section>
