<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col-md-5 d-flex flex-column min-vh-100 h-100 pt-3 fade-in-bottom">
            <div class="d-flex justify-content-between align-items-center">
                <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                        fill="#060A30" />
                </svg>
                <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
                    Cartão
                </h1>
                <div class="col-1"></div>
            </div>
            <div class="flex-grow-1 d-flex flex-column pt-3 px-3">
                <h4 class="text-center fw-700 font-size-21 letter-spacing-05 line-24 color-primary my-3">Cartão solicitado
                    <br>com sucesso!
                </h4>
                <span class="fw-400 font-size-16 color-secundary text-center mb-2">Você receberá um e-mail de confirmação quando seu cartão for liberado.</span>
            </div>
            <div class="col-md-5 h-100">
                <div class="background-rocket h-75">
                </div>
            </div>
        </div>
    </div>

    <div class="w-75 position-absolute d-flex justify-content-center mt-5 pt-5 pb-3 px-2"
        style="bottom: 2rem; left: 50%; transform: translateX(-50%);">
        <button [routerLink]="['/logged/home']" class="button-green fw-800 line-24 font-size-14 mt-4">Concluir</button>
    </div>
</section>