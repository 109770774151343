import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientRegisterRequest } from 'src/app/dtos/client/client-register-request.dto';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientService } from 'src/app/services/client.service';
import LocalStorageUtil from 'src/utils/localStorage.util';

@Component({
  selector: 'app-login-number',
  templateUrl: './login-number.component.html',
  styleUrls: ['./login-number.component.scss']
})
export class LoginNumberComponent implements OnInit {

  @HostListener('window:keydown.enter', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.confirm();
    }
  }

  // separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Brazil, CountryISO.UnitedStates];

  form: FormGroup;

  isSubmit: boolean = false;

  MASK_BR = '(00) 00000-0000';
  MASK_US = '000-000-0000';

  mask: string = this.MASK_BR;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
  ) {
    this.form = this.formBuilder.group({
      phone: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    LocalStorageUtil.clear();
    const rememberMeOnLogout = localStorage.getItem('rememberMeOnLogout');
    if (rememberMeOnLogout === 'true') {
      localStorage.removeItem('rememberMeOnLogout');
      return;
    }

    const rememberMeEnabled = localStorage.getItem('rememberMeEnabled');
    if (rememberMeEnabled === 'true') {
      this.router.navigate(['/logged/home']);
    }
  }

  onCountryChange(selectedCountry: any) {
    const countryCode = selectedCountry.iso2;

    if (countryCode === 'br') {
      this.mask = this.MASK_BR;
    } else if (countryCode === 'us') {
      this.mask = this.MASK_US;
    } else {
      this.mask = '';
    }
  }

  maskApply(a: any) {
    if (this.mask === this.MASK_BR) {
      setTimeout(() => {
        // Remove todos os não dígitos do número
        const numericValue = this.form.controls['phone'].value.number

        let maskedValue;

        if (numericValue.length === 2) {

          maskedValue = numericValue.replace(/(\d{2})/, '($1)');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 3) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d)(\d{4})/, '($1) $2');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 4) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{2})(\d{2})/, '($1) $2');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 5) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{3})(\d{3})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 6) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 7) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 8) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{1})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 9) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{2})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 10) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{3})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 11) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        }
      }, 0)
    }
    if (this.mask === this.MASK_US) {
      setTimeout(() => {
        // Remove todos os não dígitos do número
        const numericValue = this.form.controls['phone'].value.number

        // Aplica a máscara "(00) 00000-0000"
        const maskedValue = numericValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

        this.form.controls['phone'].setValue(maskedValue)
      }, 0)
    }

  }

  confirm() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }

    localStorage.setItem('phone', this.form.controls['phone'].value.e164Number);

    this.clientService.getStatusByPhone(this.form.controls['phone'].value.e164Number).subscribe({
      next: (data: any) => {
        this.ngxSpinnerService.hide();

        if (!data || Object.keys(data).length === 0) {
          this.phoneRegister();
        } else {

          if (data === 'INACTIVE') {
            this.router.navigate(['/confirm-code']);
          } else if (data === 'ACTIVE') {
            this.router.navigate(['login-password']);
          }
        }
      },
      error: (error: any) => {
        this.toastrService.error(`Erro ao tentar efetuar o Login`, '', { progressBar: true });
      }
    });
  }

  phoneRegister() {
    this.ngxSpinnerService.show();

  const request: ClientRegisterRequest = {
      phone: this.form.controls['phone'].value.e164Number,
      email: ''
    }

    this.clientService.register(request).subscribe({
      next: data => {
        this.router.navigate(['/confirm-code']);
        this.ngxSpinnerService.hide();
      },
      error: err => {
        console.error('erro',err);
        this.toastrService.error(`${err.error.errors}`, 'Erro', { progressBar: true })
        this.ngxSpinnerService.hide();
      }
    });
  }

  goToLoginEmail() {
    this.router.navigate(['/login-email']);
  }
}
