import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-rescue',
  templateUrl: './splash-rescue.component.html',
  styleUrls: ['./splash-rescue.component.scss']
})
export class SplashRescueComponent {

  constructor(private location: Location) {

  }

  goBack() {
    this.location.back()
  }
}
