import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-finger-print',
  templateUrl: './finger-print.component.html',
  styleUrls: ['./finger-print.component.scss']
})
export class FingerPrintComponent {

  constructor(
    private location: Location
  ) { }

  goBack() {
    this.location.back();
  }

}
