import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { NotificationModel } from './models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {

  walletApiUrl: string = `${environment.api.path}/notification`

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    super();
  }

  
  listByClient(): Observable<NotificationModel[]> {
    return this.httpClient
      .get(`${this.walletApiUrl}/list-by-client`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  hasNewMessages(): Observable<boolean | {}> {
    return this.httpClient
      .get(`${this.walletApiUrl}/has-new-messages`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  markAsRead(id: string): Observable<null> {
    return this.httpClient
      .put(`${this.walletApiUrl}/mark-as-read/id/${id}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  delete(id: string): Observable<null> {
    return this.httpClient
      .delete(`${this.walletApiUrl}/id/${id}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
