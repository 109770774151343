import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-key-pix-step',
  templateUrl: './key-pix-step.component.html',
  styleUrls: ['./key-pix-step.component.scss']
})
export class KeyPixStepComponent {
  form: FormGroup
  chosenValue: number
  FormatedChosenValue: string


  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private location: Location,
    private router: Router
  ) {
    const chosenValue = localStorage.getItem('chosenPixValue')
    if(chosenValue) this.chosenValue = JSON.parse(chosenValue)
    else this.router.navigate(['/logged/send-pix'])

    this.FormatedChosenValue = this.chosenValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})

    this.form = this.fb.group({
      pixKey: ['', [
        Validators.required,
        Validators.minLength(5)
      ]],
    })
  }

  get pixKeyValue() {
    return this.form.controls['pixKey'].value as string
  }

  ngOnInit() {
  }

  validCPF(cpf: string): boolean {
    cpf = cpf.replace(/\D/g, '');
  
    if (cpf.length !== 11) {
      return false;
    }
  
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let primeiroDigito = 11 - (soma % 11);
  
    primeiroDigito = primeiroDigito >= 10 ? 0 : primeiroDigito;
  
    if (primeiroDigito !== parseInt(cpf.charAt(9))) {
      return false;
    }
  
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    let segundoDigito = 11 - (soma % 11);
  
    segundoDigito = segundoDigito >= 10 ? 0 : segundoDigito;
  
    if (segundoDigito !== parseInt(cpf.charAt(10))) {
      return false;
    }
  
    return true;
  }

  verifyKeyType(): string {
    if (this.validCPF(this.pixKeyValue)) {
      return "CPF";
    } else if (/^[0-9]{14}$/.test(this.pixKeyValue)) {
      return "CNPJ";
    } else if (/^\d{11}$/.test(this.pixKeyValue)) {
      return "PHONE";
    } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.pixKeyValue)) {
      return "EMAIL";
    } else if (
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(this.pixKeyValue)
      ) {
      return "EVP";
    } else {
      return "Not recognized";
    }
  }
  
  submitvalue() {
    if(this.form.controls['pixKey'].value.length < 5) {
      this.toastrService.error('Insira a chave PIX para prosseguir', '', { progressBar: true })
      return
    }
    
    const keyType = this.verifyKeyType()

    if(keyType === 'Not recognized') {
      this.toastrService.error('Tipo da chave PIX não reconhecido', '', { progressBar: true })
      return
    } else 

    localStorage.setItem('keyType', keyType)
    localStorage.setItem('keyPIX', this.pixKeyValue)
    this.router.navigate(['/logged/send-pix-message'])
  }

  back() {
    this.location.back();
  }
}
