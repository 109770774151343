import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';
import { CriptoValuesService } from 'src/app/services/cripto-values.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {
  form: FormGroup;
  step = 1;
  adress: string;
  criptoBalances: any[] = [
    {
      icon: '../../../assets/icon/icon-tether.svg',
      name: 'Tether',
      coinCripto: 'USDT',
    },
    {
      icon: '../../../assets/icon/icon-bitcoin.svg',
      name: 'Bitcoin',
      coinCripto: 'BTC',
    },
    {
      icon: '../../../assets/icon/icon-ethereum.svg',
      name: 'Ethereum',
      coinCripto: 'ETH',
    },
    {
      icon: '../../../assets/icon/icon-busd.svg',
      name: 'Binance USD',
      coinCripto: 'BUSD',
    },
  ];

  userId: string | undefined;
  totalUsdValue: number = 0;
  selectedCripto = 2;
  showSelector = false;
  selectedMax = false;

  loaded = false;

  constructor(
    private fb: FormBuilder,
    private ngxSpinnerService: NgxSpinnerService,
    private criptoValues: CriptoValuesService,
    private location: Location,
    private router: Router
  ) {
    const chosenValue = localStorage.getItem('chosenPixValue')
    this.form = this.fb.group({
      criptoAdress: ['', [
        Validators.required,
        Validators.minLength(26),
      ]],
    })
  }

  ngOnInit() {
    this.ngxSpinnerService.show()
    this.walletBalance();
  }

  walletBalance() {
    this.totalUsdValue = 0;

    const response = this.criptoValues.getWalletBalance();
    if (!response) {
      this.router.navigate(['/logged/cripto'])
    }

    this.criptoBalances = response.criptoBalances;
    this.totalUsdValue = response.totalUsdValue;

    this.ngxSpinnerService.hide();
    this.loaded = true;
  }

  back() {
    if (this.step === 1) this.location.back();
    else this.step = this.step - 1
  }

  nextStep() {
    if (this.form.controls['criptoAdress'].invalid) return

    this.adress = this.form.controls['criptoAdress'].value;
    this.step = this.step + 1;
  }

  selectCripto(i: number) {
    this.selectedCripto = i;
    this.showSelector = false;
  }

  toggleSelector() {
    this.showSelector = !this.showSelector;
  }

  toggleselectedMax() {
    this.selectedMax = !this.selectedMax;
  }

  submit() {
    this.router.navigate(['/home']);
  }
}
