<section class="container min-vh-100">
	<div class="row justify-content-center">
		<div class="col-md-5 fade-in" *ngIf="currentScreenIndex === 0">
			<div class="row pt-5">
				<div [routerLink]="['/logged/receive-money']" class="col-2 align-items-start cursor-pointer">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none">
						<path
							d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
							fill="#060A30" />
					</svg>
				</div>
				<div class="col-8 d-flex justify-content-center align-items-center">
					<p class="fw-700 font-size-16 color-secundary m-0">Cobrar</p>
				</div>
			</div>
			<p class="fw-400 font-size-16 color-secundary mt-5">Qual será a data de vencimento?</p>
			<p class="fw-400 font-size-14 color-gray">
				Após essa data, o boleto de cobrança gerado
				irá vencer.
			</p>

			<div class="row">
				<div class="form-group mb-3 d-flex justify-content-center align-items-center">
					<ngb-datepicker #dp [(ngModel)]="dueDateModel" (ngModelChange)="onDateChange($event)" [minDate]="minDate"></ngb-datepicker>
					<p *ngIf="invalidDate && filledInput" class="fw-light text-danger my-2">Insira uma data válida</p>
				  </div>
				  
			</div>
			<div class="d-flex flex-column fw-700 font-size-14 mt-5">
				<button class="button-green letter-spacing-1"
					(click)="nextScreen()" [disabled]="invalidDate">Continuar</button>
				<button [routerLink]="['/receive-money']" class="letter-spacing-1 bg-transparent border-none mt-4 color-primary">Cancelar</button>
			</div>
		</div>

		<div class="col-md-5 fade-in" *ngIf="currentScreenIndex === 1">
			<div class="row pt-5">
				<div class="col-2 align-items-start cursor-pointer">
					<svg (click)="previousScreen()" width="40" height="40" viewBox="0 0 40 40" fill="none">
						<path
							d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
							fill="#060A30" />
					</svg>
				</div>
				<div class="col-8 d-flex justify-content-center align-items-center">
					<p class="fw-700 font-size-16 color-secundary m-0">Cobrar</p>
				</div>
			</div>
			<p class="fw-700 font-size-16 color-secundary mt-4 mb-1">Vencimento</p>
			<div class="card-add-money">
				<div class="d-flex justify-content-between align-items-center p-2">
					<div class="d-flex align-items-center">
						<img src="../../../../assets/icon/icon-calendario.svg">
						<p class="fw-400 font-size-14 color-secundary mb-0 mt-1">{{ dueDate }}</p>
					</div>
				</div>
			</div>

			<div class="accordion accordion-flush mt-3 fade-in-left" id="accordionFlush">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                            <div class="d-flex justify-content-between align-items-center flex-fill">
                                <span class="color-secundary">{{ 'CHECKOUT.AMOUNT_TO_PAY' | translate }}</span>
                                <span class="fw-400 font-size-14 color-secundary me-3">{{valueToReceive
                                    | currency: 'R$ '}}</span>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse show"
                        data-bs-parent="#accordionFlush">
                        <div class="accordion-body">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'CHECKOUT_BOLETO.TAXES' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">R$ 2,50</p>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="fw-400 font-size-14 color-secundary mb-0">Quantidade a receber</p>
                                <p class="fw-400 font-size-14 color-secundary mb-0">{{ (valueToReceive - 2.5).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="fw-400 font-size-14 color-secundary">{{ 'CHECKOUT.TRANSACTION_DURATION' | translate }}</p>
                                <p class="fw-400 font-size-14 color-secundary">{{ 'CHECKOUT.BOLETO_DURATION' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

			<div class="d-flex flex-column fw-700 font-size-14 mt-5">
				<button class="button-green letter-spacing-1 fw-700"
					(click)="nextScreen()">Gerar cobrança</button>
				<button class="letter-spacing-1 bg-transparent border-none mt-4 color-primary"
					(click)="previousScreen()">Cancelar</button>
			</div>
		</div>

		<div class="col-md-5 fade-in" *ngIf="currentScreenIndex === 2">
			<div class="row pt-5">
				<div class="col-2 align-items-start cursor-pointer">
					<svg (click)="previousScreen()" width="40" height="40" viewBox="0 0 40 40" fill="none">
						<path
							d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
							fill="#060A30" />
					</svg>
				</div>
				<div class="col-8 d-flex justify-content-center align-items-center">
					<p class="fw-700 font-size-16 color-secundary m-0">Cobrar</p>
				</div>
			</div>
			<p class="fw-700 font-size-16 color-secundary mt-5">Cobrando</p>
			<div class="d-flex align-items-center">
				<i class="bi bi-wallet" style="color: #4A35E8 !important;"></i>
				<p class="fw-400 font-size-16 color-secundary mb-0 ms-2">{{valueToReceive | currency: 'R$ '}}</p>
			</div>

			<p class="fw-bold color-secundary mt-4 fade-in-left">Código de barras do boleto</p>
			<div class="d-flex justify-content-between align-items-start rounded-3 fade-in-left" style="border: 1px solid #4A35E8;">
				<span (click)="copyValue()" class="form-control bg-transparent text-break py-3 color-secundary border-0 mb-0">{{boletoResponse.barCode}}</span>
				<i class="bi bi-files color-secundary me-3 mt-3 cursor-pointer" (click)="copyValue()"></i>
			</div>

			<p class="fw-700 font-size-14 text-center color-secundary mt-3">ou</p>

			<p class="fw-bold color-secundary fade-in-left">Link do boleto</p>
			<div class="d-flex justify-content-between align-items-start rounded-3 fade-in-left" style="border: 1px solid #4A35E8;">
				<a [href]="boletoResponse.bankSlipUrl" [download]="'boleto.pdf'" target="_blank"
					class="form-control bg-transparent text-break py-3 color-secundary border-0 mb-0 text-decoration-underline">
					{{ boletoResponse.bankSlipUrl }}
				</a>
				<i class="bi bi-files color-secundary me-3 mt-3 cursor-pointer" (click)="downloadBoleto()"></i>
			</div>
			<p class="text-center mt-2 fade-in" style="color: #8BA78F !important;">A confirmação do pagamento pode demorar alguns dias úteis</p>

			<p class="fw-600 font-size-14 text-center color-secundary letter-spacing-1 mt-2">Você recebá o valor em até 3 dias úteis.</p>
			<div class="d-flex flex-column fw-700 font-size-14 mt-5">
				<button (click)="submit()" class="button-green letter-spacing-1">Concluir</button>
			</div>
		</div>
	</div>
</section>