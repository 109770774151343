import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-share-account',
  templateUrl: './share-account.component.html',
  styleUrls: ['./share-account.component.scss']
})
export class ShareAccountComponent implements OnInit {

  bankData: string = '';
  userPhone: string

  userId: any;
  account: any

  constructor(
    private clientService: ClientService,
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
    this.clientService.getUserInfo().subscribe({
      next: data => {
        this.userId = data;
        this.getAccountInfo();
      }
    })
    this.userPhone = localStorage.getItem('phone') || ''
  }

  shareAccount() {
    if (!this.bankData) {
      console.error('Os dados da conta não estão disponíveis')
    }
    const linkWhatsApp = `https://api.whatsapp.com/send?text=${encodeURIComponent(this.bankData)}`
    window.open(linkWhatsApp, '_blank')
  }

  getAccountInfo() {
    this.accountService.getAccount().subscribe({
      next: data => {
        this.account = data        
        const bank = "BitBentz Bank";
        const nameClient = this.userId.name || '-';
        const cpfClient = this.userId.document || '-';
        const agency = data.agency || '-';
        const accountNumber = data.accountNumber || '-';
        const accountDigit = data.accountDigit || '-';

        this.bankData = `Olá! Aqui estão os dados da minha conta ${bank}.\nNome: ${nameClient}\nCPF: ${cpfClient}\nAgência: ${agency} - ${accountDigit}\nNúmero da conta: ${accountNumber}`;

      },
      error: err => {
        console.error(err)
      }
    })
  }

}
