import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientRegisterRequest } from 'src/app/dtos/client/client-register-request.dto';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientService } from 'src/app/services/client.service';
import LocalStorageUtil from 'src/utils/localStorage.util';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent implements OnInit {

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Brazil, CountryISO.UnitedStates];

  form: FormGroup;

  isSubmit: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit(): void {
    LocalStorageUtil.clear();
    const rememberMeOnLogout = localStorage.getItem('rememberMeOnLogout');
    if (rememberMeOnLogout === 'true') {
      localStorage.removeItem('rememberMeOnLogout');
      return;
    }

    const rememberMeEnabled = localStorage.getItem('rememberMeEnabled');
    if (rememberMeEnabled === 'true') {
      this.router.navigate(['/logged/home']);
    }
  }

  confirm() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }

    localStorage.setItem('email', this.form.controls['email'].value);


    this.clientService.getStatusByEmail(this.form.controls['email'].value).subscribe({
      next: (data: any) => {
        this.ngxSpinnerService.hide();

        if (!data.status || Object.keys(data.status).length === 0) {
          this.emailRegister();
        } else {
          if (data.status === 'INACTIVE' && !data.name) {
            this.router.navigate(['/confirm-email-code']);
          } else if (data.status === 'ACTIVE') {
            this.router.navigate(['login-password']);
          }
        }
      },
      error: (error: any) => {
        this.toastrService.error(`Erro ao tentar efetuar o Login`, '', { progressBar: true });
      }
    });
  }

  emailRegister() {
    this.ngxSpinnerService.show();

    const request: ClientRegisterRequest = {
      email: this.form.controls['email'].value,
      phone: ''
    }

    this.clientService.register(request).subscribe({
      next: data => {
        this.router.navigate(['/confirm-email-code']);
        this.ngxSpinnerService.hide();
      },
      error: err => {
        console.error(err);
        this.toastrService.error(`${err.error.errors}`, 'Erro', { progressBar: true })
        this.ngxSpinnerService.hide();
      }
    }); 
  }

  goToLoginNumber() {
    this.router.navigate(['/login-number']);
  }
}
