export default function removeCountryCode(phoneNumber: string): string {
  const countryCodesToRemove = [
    "+1",   // Estados Unidos
    "+1-242",   // Bahamas
    "+1-246",   // Barbados
    "+1-264",   // Anguila
    "+1-268",   // Antígua e Barbuda
    "+1-284",   // Ilhas Virgens Britânicas
    "+1-340",   // Ilhas Virgens Americanas
    "+1-345",   // Ilhas Cayman
    "+1-441",   // Bermuda
    "+1-473",   // Granada
    "+1-649",   // Ilhas Turks e Caicos
    "+1-664",   // Montserrat
    "+1-670",   // Ilhas Marianas do Norte
    "+1-671",   // Guam
    "+1-684",   // Samoa Americana
    "+1-721",   // São Martinho (parte holandesa)
    "+1-758",   // Santa Lúcia
    "+1-767",   // Dominica
    "+1-784",   // São Vicente e Granadinas
    "+1-787",   // Porto Rico
    "+1-809",   // República Dominicana
    "+1-829",   // República Dominicana
    "+1-849",   // República Dominicana
    "+1-868",   // Trinidad e Tobago
    "+1-869",   // São Cristóvão e Nevis
    "+1-876",   // Jamaica
    "+20",  // Egito
    "+212",  // Marrocos
    "+213",  // Argélia
    "+216",  // Tunísia
    "+218",  // Líbia
    "+220",  // Gâmbia
    "+221",  // Senegal
    "+222",  // Mauritânia
    "+223",  // Mali
    "+224",  // Guiné
    "+225",  // Costa do Marfim
    "+226",  // Burkina Faso
    "+227",  // Níger
    "+228",  // Togo
    "+229",  // Benin
    "+230",  // Maurícia
    "+231",  // Libéria
    "+232",  // Serra Leoa
    "+233",  // Gana
    "+234",  // Nigéria
    "+235",  // Chade
    "+236",  // República Centro-Africana
    "+237",  // Camarões
    "+238",  // Cabo Verde
    "+239",  // São Tomé e Príncipe
    "+240",  // Guiné Equatorial
    "+241",  // Gabão
    "+242",  // Congo
    "+243",  // República Democrática do Congo
    "+244",  // Angola
    "+245",  // Guiné-Bissau
    "+246",  // Território Britânico do Oceano Índico
    "+247",  // Arquipélago de Chagos
    "+248",  // Seychelles
    "+249",  // Sudão
    "+250",  // Ruanda
    "+251",  // Etiópia
    "+252",  // Somália
    "+253",  // Djibouti
    "+254",  // Quênia
    "+255",  // Tanzânia
    "+256",  // Uganda
    "+257",  // Burundi
    "+258",  // Moçambique
    "+260",  // Zâmbia
    "+261",  // Madagascar
    "+262",  // Reunião
    "+263",  // Zimbábue
    "+264",  // Namíbia
    "+265",  // Malawi
    "+266",  // Lesoto
    "+267",  // Botsuana
    "+268",  // Suazilândia
    "+269",  // Comores
    "+27",   // África do Sul
    "+290",  // Santa Helena
    "+291",  // Eritreia
    "+297",  // Aruba
    "+298",  // Ilhas Faroé
    "+299",  // Gronelândia
    "+30",   // Grécia
    "+31",   // Países Baixos
    "+32",   // Bélgica
    "+33",   // França
    "+34",   // Espanha
    "+350",  // Gibraltar
    "+351",  // Portugal
    "+352",  // Luxemburgo
    "+353",  // Irlanda
    "+354",  // Islândia
    "+355",  // Albânia
    "+356",  // Malta
    "+357",  // Chipre
    "+358",  // Finlândia
    "+359",  // Bulgária
    "+36",   // Hungria
    "+370",  // Lituânia
    "+371",  // Letônia
    "+372",  // Estônia
    "+373",  // Moldávia
    "+374",  // Armênia
    "+375",  // Bielorrússia
    "+376",  // Andorra
    "+377",  // Mônaco
    "+378",  // São Marinho
    "+379",  // Cidade do Vaticano
    "+380",  // Ucrânia
    "+381",  // Sérvia
    "+382",  // Montenegro
    "+385",  // Croácia
    "+386",  // Eslovênia
    "+387",  // Bósnia e Herzegovina
    "+389",  // Macedônia do Norte
    "+39",   // Itália
    "+40",   // Romênia
    "+41",   // Suíça
    "+420",  // República Tcheca
    "+421",  // Eslováquia
    "+423",  // Liechtenstein
    "+43",   // Áustria
    "+44",   // Reino Unido
    "+45",   // Dinamarca
    "+46",   // Suécia
    "+47",   // Noruega
    "+48",   // Polônia
    "+49",   // Alemanha
    "+500",  // Ilhas Malvinas
    "+501",  // Belize
    "+502",  // Guatemala
    "+503",  // El Salvador
    "+504",  // Honduras
    "+505",  // Nicarágua
    "+506",  // Costa Rica
    "+507",  // Panamá
    "+508",  // São Pedro e Miquelão
    "+509",  // Haiti
    "+51",   // Peru
    "+52",   // México
    "+53",   // Cuba
    "+54",   // Argentina
    "+55",   // Brasil
    "+56",   // Chile
    "+57",   // Colômbia
    "+58",   // Venezuela
    "+590",  // Guadalupe
    "+591",  // Bolívia
    "+592",  // Guiana
    "+593",  // Equador
    "+594",  // Guiana Francesa
    "+595",  // Paraguai
    "+596",  // Martinica
    "+597",  // Suriname
    "+598",  // Uruguai
    "+599",  // Curaçao
    "+5997", // Sint Eustatius
    "+5993", // Saba
    "+60",   // Malásia
    "+61",   // Austrália
    "+62",   // Indonésia
    "+63",   // Filipinas
    "+64",   // Nova Zelândia
    "+65",   // Singapura
    "+66",   // Tailândia
    "+672",  // Ilha Norfolk
    "+673",  // Brunei
    "+674",  // Nauru
    "+675",  // Papua-Nova Guiné
    "+676",  // Tonga
    "+677",  // Ilhas Salomão
    "+678",  // Vanuatu
    "+679",  // Fiji
    "+680",  // Palau
    "+681",  // Wallis e Futuna
    "+682",  // Ilhas Cook
    "+683",  // Niue
    "+685",  // Samoa
    "+686",  // Kiribati
    "+687",  // Nova Caledônia
    "+688",  // Tuvalu
    "+689",  // Polinésia Francesa
    "+690",  // Tokelau
    "+691",  // Micronésia
    "+692",  // Ilhas Marshall
    "+7",    // Rússia
    "+7-6",  // Cazaquistão
    "+81",   // Japão
    "+82",   // Coreia do Sul
    "+84",   // Vietnã
    "+850",  // Coreia do Norte
    "+852",  // Hong Kong
    "+853",  // Macau
    "+855",  // Camboja
    "+856",  // Laos
    "+86",   // China
    "+870",  // Inmarsat SNAC
    "+875",  // Iridium
    "+878",  // Estações Aeroespaciais Globais Móveis
    "+880",  // Bangladesh
    "+881",  // Global Mobile Satellite System
    "+8810", // ICO Global (serviço de satélite móvel)
    "+8812", // Ellipso (serviço de satélite móvel)
    "+8813", // Iridium (serviço de satélite móvel)
    "+8816", // Globalstar (serviço de satélite móvel)
    "+8817", // IRIDIUM (serviço de satélite móvel)
    "+8818", // Globalstar (serviço de satélite móvel)
    "+8819", // IRIDIUM (serviço de satélite móvel)
    "+882",  // International Networks
    "+883",  // Unidade de Identificação de Rede Internacional
    "+886",  // Taiwan
    "+90",   // Turquia
    "+91",   // Índia
    "+92",   // Paquistão
    "+93",   // Afeganistão
    "+94",   // Sri Lanka
    "+95",   // Mianmar
    "+960",  // Maldivas
    "+961",  // Líbano
    "+962",  // Jordânia
    "+963",  // Síria
    "+964",  // Iraque
    "+965",  // Kuwait
    "+966",  // Arábia Saudita
    "+967",  // Iêmen
    "+968",  // Omã
    "+970",  // Palestina
    "+971",  // Emirados Árabes Unidos
    "+972",  // Israel
    "+973",  // Bahrein
    "+974",  // Catar
    "+975",  // Butão
    "+976",  // Mongólia
    "+977",  // Nepal
    "+98",   // Irã
    "+992",  // Tadjiquistão
    "+993",  // Turcomenistão
    "+994",  // Azerbaijão
    "+995",  // Geórgia
    "+996",  // Quirguistão
    "+998",  // Uzbequistão
  ];

  for (const countryCode of countryCodesToRemove) {
    if (phoneNumber.startsWith(countryCode)) {
      phoneNumber = phoneNumber.slice(countryCode.length).trim();
    }
  }

  return phoneNumber;
}