import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';
import { ChangePhotoComponent } from './change-photo/change-photo.component';
import { AccountService } from 'src/app/services/account.service';
import removeCountryCode from 'src/utils/country-codes.util';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent {

  balanceCodeWallet: number;
  user?: any;

  phone: string = '';
  email = localStorage.getItem('email');

  constructor(
    private router: Router,
    private clientService: ClientService,
    private accountService: AccountService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.phone = removeCountryCode(localStorage.getItem('phone') || '');
    this.getUseInfoHandle();
  }

  getUseInfoHandle() {
    this.clientService.getUserInfo().subscribe({
      next: (data) => {
        if (data.name) {
          const fullName = data.name;
          const firstName = fullName.split(' ')[0];
          this.user = [
            {
              name: firstName,
              avatar: data.avatar || ''
            },
          ];
        } else {
          this.user = [
            {
              name: '',
              avatar: data.avatar || ''
            },
          ];
        }
        this.accountService.getAccount().subscribe({
          next: account => {
            this.balanceCodeWallet = account.balance
          }
        })
      }
    })
  }

  goToConfig() {
    this.router.navigate(['/logged/settings'])
  }

  goToDetails() {
    this.router.navigate(['/logged/details'])
  }

  changePhoto() {
    const modalRef = this.modalService.open(ChangePhotoComponent);
    modalRef.componentInstance.modalClosed.subscribe((closed: boolean) => {
      if (closed) {
        this.getUseInfoHandle();
      }
    });
  }

}
