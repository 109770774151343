<section class="container min-vh-100 pt-3">
  <div class="row justify-content-center">
    <form [formGroup]="form" class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3" *ngIf="loaded">

      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center fade-in">
        <svg (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          Enviar
        </h1>
        <div class="col-1"></div>
      </div>

      <!-- SECOND STEP -->
      <ng-container *ngIf="step === 2 else firstStep">
        <!-- ADRESS -->
        <div class="flex-grow-0 d-flex flex-column px-2 fade-in-left pb-4 mt-5">
          <label for="criptoAdress" class="text-wrap fw-700 font-size-14 line-19 color-secundary mb-2">Endereço</label>
          <input type="text"
            name="criptoAdress"
            id="criptoAdress"
            class="border border-green rounded-3 fw-700 font-size-16 form-control border-0 bg-transparent color-secundary py-3"
            [defaultValue]="adress"
            readonly>
        </div>
  
        <!-- SELECT CRIPTO -->
        <div class="select-container fade-in mx-2 position-relative py-2 px-3 br-6 bg-demi-transparent cursor-pointer">
          <!-- SELECTED OPTION -->
          <div class="d-flex align-items-center justify-content-between" (click)="toggleSelector()">
            <div class="d-flex align-items-center justify-content-start">
              <div class="">
                  <img [src]="criptoBalances[selectedCripto].icon">
              </div>
              <div class="ms-2">
                  <div class="d-flex flex-column">
                      <span class="fw-400 font-size-16 color-secundary letter-spacing-1 mb-0">{{criptoBalances[selectedCripto].coinCripto}}</span>
                      <span class="fw-500 font-size-11 color-secundary letter-spacing-2019 m-0">Saldo: {{criptoBalances[selectedCripto].value}} {{ criptoBalances[selectedCripto].coinCripto }}</span>
                  </div>
              </div>
            </div>
            <div class="p-2 mb-1">
              <svg class="openSVG" [class.rotateSVG]="showSelector" width="14" height="10" viewBox="0 0 14 10" fill="none">
                <path d="M6.46875 8.9375L0.375 2.875C0.09375 2.5625 0.09375 2.09375 0.375 1.8125L1.09375 1.09375C1.375 0.8125 1.84375 0.8125 2.15625 1.09375L7 5.90625L11.8125 1.09375C12.125 0.8125 12.5938 0.8125 12.875 1.09375L13.5938 1.8125C13.875 2.09375 13.875 2.5625 13.5938 2.875L7.5 8.9375C7.21875 9.21875 6.75 9.21875 6.46875 8.9375Z" fill="white"/>
              </svg>              
            </div>
          </div>

          <!-- SELECTOR -->
          <div *ngIf="showSelector" class="fade-in br-6 w-100 selector-options position-absolute d-flex flex-column align-items-center justify-content-start">
            <div *ngFor="let item of criptoBalances; index as i" (click)="selectCripto(i)" class="option-cripto w-100 d-flex align-items-center py-2 mb-2 px-3 br-6">
              <div class="">
                  <img [src]="item.icon">
              </div>
              <div class="ms-2">
                  <div class="d-flex flex-column">
                      <span class="fw-400 font-size-16 color-secundary letter-spacing-1 mb-0">{{item.coinCripto}}</span>
                      <span class="fw-500 font-size-11 color-secundary letter-spacing-2019 m-0">Saldo: {{item.value}}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <!-- VALUE -->
        <div class="flex-grow-1 d-flex flex-column px-2 fade-in-left pb-4 mt-5">
          <!-- CHECKBOX -->
          <div class="d-flex justify-content-between align-items-center mb-2 cursor-pointer ">
            <p for="criptoAdress" class="text-wrap fw-700 font-size-14 line-19 color-secundary m-0">Valor</p>
            <div class="d-flex justify-content-end align-items-center" (click)="toggleselectedMax()">
              <ng-container *ngIf="selectedMax else selectedMaxBox">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#4A35E8"/>
                </svg>
              </ng-container>
              <ng-template #selectedMaxBox>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="#4A35E8" stroke="#4A35E8"/>
                  <path d="M7.40633 16.0835L2.21883 10.896C1.90633 10.5835 1.90633 10.0522 2.21883 9.73975L3.34383 8.61475C3.65633 8.30225 4.15633 8.30225 4.46883 8.61475L8.00008 12.1147L15.5001 4.61475C15.8126 4.30225 16.3126 4.30225 16.6251 4.61475L17.7501 5.73975C18.0626 6.05225 18.0626 6.5835 17.7501 6.896L8.56258 16.0835C8.25008 16.396 7.71883 16.396 7.40633 16.0835Z" fill="#070813"/>
                </svg>                  
              </ng-template>
              <p class="ms-2 fw-500 font-size-11 line-19 color-secundary mb-0">Máx</p>
            </div>
          </div>

          <!-- Data -->
          <div class="border border-dark-green rounded-3 bg-transparent py-2 px-3 mb-2">
            <span class="d-flex justify-content-start text-left text-start fw-400 font-size-16 color-secundary m-0 letter-spacing-0016">{{criptoBalances[selectedCripto].value}} {{ criptoBalances[selectedCripto].coinCripto }}</span>
            <span class="d-flex justify-content-start text-left text-start fw-500 font-size-11 color-gray m-0 letter-spacing-2019">USD {{criptoBalances[selectedCripto].usdValue}}</span>
          </div>

          <p *ngIf="+((this.criptoBalances[this.selectedCripto].value).replace('.', '')) === 0" class="d-flex color-red font-size-11 letter-spacing-1 mt-1 fade-in-left mb-3 ms-2">Saldo {{ criptoBalances[selectedCripto].coinCripto }} insuficiente</p>
        </div>

        <!-- ESTIMATED -->
        <div *ngIf="!showSelector" class="mx-2 p-3 bg-demi-transparent br-6 d-flex flex-column align-items-center justify-content-start" >
          <div class="w-100 d-flex align-items-center justify-content-between mb-2">
            <div>
              <p class="d-inline fw-700 font-size-14 line-19 color-secundary letter-spacing-2019">
                Gás
              </p>

              <p class="d-inline fw-500 font-size-11 line-19 letter-spacing-2019" style="color: #AEB0BB;">
                (Estimada)
              </p>

              <svg class="d-inline ms-1" width="15" height="13" viewBox="0 0 15 13" fill="none">
                <path d="M7.5 1.25C5.60156 1.25 3.89062 2.25781 2.95312 3.875C1.99219 5.51562 1.99219 7.50781 2.95312 9.125C3.89062 10.7656 5.60156 11.75 7.5 11.75C9.375 11.75 11.0859 10.7656 12.0234 9.125C12.9844 7.50781 12.9844 5.51562 12.0234 3.875C11.0859 2.25781 9.375 1.25 7.5 1.25ZM7.5 12.5C5.34375 12.5 3.375 11.375 2.29688 9.5C1.21875 7.64844 1.21875 5.375 2.29688 3.5C3.375 1.64844 5.34375 0.5 7.5 0.5C9.63281 0.5 11.6016 1.64844 12.6797 3.5C13.7578 5.375 13.7578 7.64844 12.6797 9.5C11.6016 11.375 9.63281 12.5 7.5 12.5ZM6.375 8.75H7.125V6.5H6.5625C6.35156 6.5 6.1875 6.33594 6.1875 6.125C6.1875 5.9375 6.35156 5.75 6.5625 5.75H7.5C7.6875 5.75 7.875 5.9375 7.875 6.125V8.75H8.625C8.8125 8.75 9 8.9375 9 9.125C9 9.33594 8.8125 9.5 8.625 9.5H6.375C6.16406 9.5 6 9.33594 6 9.125C6 8.9375 6.16406 8.75 6.375 8.75ZM7.5 4.8125C7.17188 4.8125 6.9375 4.57812 6.9375 4.25C6.9375 3.94531 7.17188 3.6875 7.5 3.6875C7.80469 3.6875 8.0625 3.94531 8.0625 4.25C8.0625 4.57812 7.80469 4.8125 7.5 4.8125Z" fill="#AEB0BB"/>
              </svg>  
            </div>

            <div>
              <p class="d-inline fw-700 font-size-14 line-19 color-secundary letter-spacing-2019">
                {{criptoBalances[selectedCripto].value}} {{ criptoBalances[selectedCripto].coinCripto }}
              </p>
            </div>
          </div>

          <div class="w-100 d-flex align-items-center justify-content-end mb-2">
            <div>
              <p class="d-inline fw-500 font-size-14 line-19 color-secundary letter-spacing-2019 m-0">
                Taxa máxima:
              </p>
              <p class="d-inline fw-700 font-size-11 line-19 letter-spacing-2019 m-0" style="color: #AEB0BB;">
                {{criptoBalances[selectedCripto].value}} {{ criptoBalances[selectedCripto].coinCripto }}
              </p>
            </div>
          </div>
          
          <div class="w-100 d-flex align-items-center justify-content-center">
            <div>
              <p class="d-inline fw-500 color-primary font-size-11 line-19 letter-spacing-2019 m-0">
                Provavelmente em < 30 segundos
              </p>
            </div>
          </div>

        </div>

        <!-- BUTTONS -->
        <div class="px-2 d-flex flex-column py-5 fade-in">
          <button [disabled]="this.form.controls['criptoAdress'].invalid" (click)="submit()" class="button-green fw-800 font-size-14 mt-3">Transferir</button>
          <button [routerLink]="['/logged/home']" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
        </div>
      </ng-container>
      
      <!-- FIRST STEP -->
      <ng-template #firstStep>
        <!-- VALUE -->
        <div class="flex-grow-1 d-flex flex-column px-2 fade-in-left pb-4 mt-5">
          <label for="criptoAdress" class="text-wrap fw-700 font-size-14 line-19 color-secundary mb-2">Digite o endereço da carteira</label>
          <input type="text"
            name="criptoAdress"
            id="criptoAdress"
            class="border border-dark-green rounded-3 fw-700 font-size-16 form-control border-0 bg-transparent color-secundary py-3"
            formControlName="criptoAdress"
            placeholder="Digite o endereço">
        </div>
  
        <!-- BUTTONS -->
        <div class="px-2 d-flex flex-column py-5 fade-in">
          <button [disabled]="this.form.controls['criptoAdress'].invalid" (click)="nextStep()" class="button-green fw-800 font-size-14 mt-3">Continuar</button>
          <button [routerLink]="['/logged/home']" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
        </div>
      </ng-template>

    </form>
  </div>
</section> 