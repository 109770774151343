import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {

  constructor(private location: Location,) {

  }

  goBack() {
    this.location.back()
  }
}
