import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ClientService } from 'src/app/services/client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rescue',
  templateUrl: './rescue.component.html',
  styleUrls: ['./rescue.component.scss']
})
export class RescueComponent implements OnInit {

  user?: any;

  constructor(
    private clientService: ClientService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getUserInfo()
  }

  getUserInfo() {
    this.clientService.getUserInfo().subscribe({
      next: data => {
        if (data.name) {
          const fullName = data.name
          const firstName = fullName.split(' ')[0];
          this.user = [
            {
              name: firstName,
              rank: 'Bronze',
              currentValue: '100',
              maxValue: '3000',
              BitbentzCoin: '2.900',
              nextRank: 'Prata',
            }
          ]
        } else {
          this.user = [
            {
              name: '',
              rank: 'Bronze',
              currentValue: '100',
              maxValue: '3000',
              BitbentzCoin: '2.900',
              nextRank: 'Prata',
            }
          ]
        }
      }
    })
  }

  goBack() {
    this.router.navigate(['/home']);
  }

  updateProgressBar(progress: any) {
    const progressBar = document.querySelector('.progress-fill') as HTMLElement;
    progressBar.style.width = `${progress}`
  }

}

