import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssasPaymentGeneratePixRequestDto, AssasPixCheckoutResponse } from 'src/app/dtos/payment/assas-payment-generate-pix-request.dto';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-receive-pix',
  templateUrl: './receive-pix.component.html',
  styleUrls: ['./receive-pix.component.scss']
})
export class ReceivePixComponent implements OnInit {
  form: FormGroup;
  pixResponse: AssasPixCheckoutResponse

  valueToReceive: any;
  message: string

  showResultPage: boolean = false

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private paymentService: PaymentService,
  ) {
    this.form = this.fb.group({
      mensagem: new FormControl('', [Validators.required, Validators.minLength(5)])
    })
  }

  ngOnInit(): void {
    let responseValue: any = localStorage.getItem('receiveValuePix');
    this.valueToReceive = JSON.parse(responseValue);
  }

  copyValue() {
    navigator.clipboard.writeText(this.pixResponse.payload)
    this.toastrService.success('Código copiado', 'Sucesso', { progressBar: true });
  }

  submit() {
    if(this.form.invalid) return

    this.message = this.form.controls['mensagem'].value

    this.generatePIX()
  }

  sendBiling() {
    localStorage.removeItem('receiveValuePix');
    localStorage.removeItem('receiveType');
    this.router.navigate(['/logged/home'])
  }

  generatePIX() {
    this.ngxSpinnerService.show();

    const dto: AssasPaymentGeneratePixRequestDto = {
      description: this.message,
      value: this.valueToReceive,
    }

    this.showResultPage = true

    this.paymentService.generatePIX(dto).subscribe({
      next: (data) => {
        this.pixResponse = data
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        this.ngxSpinnerService.hide();
      }
    })
  }

  back() {
    this.router.navigate(['/home'])
  }
}
