<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col-md-5">
            <div class="d-flex justify-content-between align-items-center mt-3">
                <svg (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                </svg>
                <p class="text-center color-secundary fw-bold mb-0">Venda</p>
                <i (click)="goToComents()" class="bi bi-chat-dots color-secundary cursor-pointer"></i>
            </div>
            <form [formGroup]="form" class="mt-5">
                <label for="" class="fw-bold color-secundary">Você paga</label>
                <div class="border border-dark-green rounded-3 d-flex align-items-center mt-2">
                    <div class="dropdown">
                        <button class="btn dropdown-toggle p-0 border-0 color-secundary" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img [src]="coinSelect?.img" alt="" height="20px" class="mx-2">
                            <span class="fw-400 text-gray">{{coinSelect?.name}}</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let item of listCoin" class="text-nowrap" (click)="selectCoin(item)"><img
                                    [src]="item?.img" alt="" height="20px" class="mx-2"><span>{{item?.name}}</span></li>
                        </ul>
                    </div>
                    <input type="text" class="form-control border-0 bg-transparent color-secundary"
                        formControlName="valuePay">
                </div>

                <label for="" class="fw-bold color-secundary mt-4">Você recebe</label>
                <div class="border border-dark-green rounded-3 d-flex align-items-center mt-2">
                    <div class="dropdown">
                        <button class="btn dropdown-toggle p-0 border-0 color-secundary" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img [src]="coinReceiveSelect?.img" alt="" height="20px" class="mx-2">
                            <span class="fw-400 text-gray">{{coinReceiveSelect?.name}}</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let item of listCoinReceive" class="text-nowrap"
                                (click)="selectCoinReceive(item)"><img [src]="item?.img" alt="" height="20px"
                                    class="mx-2"><span>{{item?.name}}</span></li>
                        </ul>
                    </div>
                    <input type="text" class="form-control border-0 bg-transparent color-secundary"
                        formControlName="valueRecevice">
                </div>

                <div class="d-flex flex-column justify-content-end align-items-center mt-5 pt-5 pb-5 mb-5">
                    <p class="fw-400 font-size-16 color-secundary text-center pt-5">Ao pressionar este botão, você estará
                        comprando instantaneamente
                        o valor que selecionou.</p>
                    <button class="btn button-green fw-800 font-size-14" (click)="submit()">Vender</button>
                    <a class="fw-700 font-size-14 letter-spacing-1 color-blue text-center mt-4">Leia nossos termos e condições</a>
                </div>
            </form>
        </div>
    </div>
</section>