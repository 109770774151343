import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { pairwise, startWith } from 'rxjs';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private clientService: ClientService,
    private toastrService: ToastrService,
  ) {
    this.form = this.fb.group({
      type: ['', Validators.required],
      value: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.form.controls['type'].valueChanges.pipe(startWith(null), pairwise()).subscribe({
      next: data => {
        this.reset()
      }
    })
  }

  reset() {
    this.form.controls['value'].reset();
  }

  submit() {
    let inputType = this.form.controls['type'].value;
    let inputValue = this.form.controls['value'].value;

    if (inputType === 'phone') {
      inputValue = '+55' + this.form.controls['value'].value
    }

    this.clientService.checkUserExistence(inputType, inputValue).subscribe({
      next: response => {
        if (response) {
          localStorage.setItem('nameToTransfer', response[1] || '');
          localStorage.setItem('phoneToTransfer', response[2] || '');
          localStorage.setItem('emailToTransfer', response[3] || '');
          this.clientService.setUserDataTransfer(response)
          this.router.navigate(['/logged/transfer-value'])
        } else {
          this.toastrService.error(response, 'Erro', { progressBar: true })
        }
      },
      error: err => {
        this.toastrService.error('Usuário não encontrado', 'Erro', { progressBar: true })
        console.error(err);
      }
    })
  }

}