import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { Subject, catchError, debounceTime, of, switchMap } from 'rxjs';
import { ClientInterface } from 'src/app/interface/client.interface';
import { ClientService } from 'src/app/services/client.service';
import removeCountryCode from 'src/utils/country-codes.util';

@Component({
  selector: 'app-personal-info-needed',
  templateUrl: './personal-info-needed.component.html',
  styleUrls: ['./personal-info-needed.component.scss']
})
export class PersonalInfoNeededComponent implements OnInit, OnDestroy {
  model: NgbDateStruct | null = null;
  form: FormGroup;
  submitted = false;
  client: ClientInterface | undefined;

  registerInfos = Boolean(localStorage.getItem('registerInfos'));

  isSubmit: boolean = false;

  private zipCodeChanged = new Subject<string>();

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private router: Router,
    private http: HttpClient
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      document: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      number: ['', [Validators.required]],
      additionalInformation: '',
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('registerInfos');
  }

  ngOnInit(): void {
    this.clientService.getUserInfo().subscribe({
      next: (client) => {
        this.client = client;

        if (client.dateOfBirth) {
          const date = moment(client.dateOfBirth);
          this.model = new NgbDate(date.year(), date.month() + 1, date.date());
        } else {
          this.model = null;
        }

        this.form.patchValue({
          name: client.name,
          document: client.document,
          zipCode: client?.address?.zipCode || '',
          country: client?.address?.country || '',
          state: client?.address?.state || '',
          city: client?.address?.city || '',
          address: client?.address?.address || '',
          additionalInformation: client.address?.additionalInformation || '',
          number: client?.address?.number || '',
        });
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.zipCodeChanged
      .pipe(
        debounceTime(1000),
        switchMap(zipCode => {
          const viaCEPUrl = `https://viacep.com.br/ws/${zipCode}/json/`;

          return this.http.get(viaCEPUrl).pipe(
            catchError(error => {
              this.toastrService.error('Erro na requisição CEP', '', {
                progressBar: true,
              });
              return of(null);
            })
          );
        })
      )
      .subscribe((data: any) => {
        if (data && !data.erro) {
          this.form.patchValue({
            city: data.localidade,
            state: data.uf,
            country: 'Brasil',
            address: data.logradouro,
          });
        } else {
          this.form.patchValue({
            city: '',
            state: '',
            country: '',
            address: '',
          });
        }
      });
  }

  searchViaCEP() {
    const zipCode = this.form.get('zipCode')?.value;

    if (zipCode) {
      this.zipCodeChanged.next(zipCode);
    }
  }

  confirm() {
    this.submitted = true;
    if (this.form.invalid || !this.form.dirty || this.isCPFInvalidAndTouched()) return;

    const currentCPF = this.client?.document || '';
    const newCPF = this.form.controls['document'].value;

    if(currentCPF !== newCPF) {
      this.clientService.checkUserExistence('document', this.form.controls['document'].value).subscribe({
        next: data => {
          this.form.controls['document'].setErrors({ 'documentExists': true });
        },
        error: err => {
          this.updateUserInfo();
          console.clear();
        }
      });
    } else {
      this.updateUserInfo();
    }
  }

  updateUserInfo() {
    this.submitted = true;
    if (this.form.invalid || !this.form.dirty || this.isCPFInvalidAndTouched()) {
      return;
    }

    let date: Date;
    if (this.form.controls['dateOfBirth'].value) {
      date = new Date();
      date.setFullYear(this.form.controls['dateOfBirth'].value.year);
      date.setMonth(this.form.controls['dateOfBirth'].value.month - 1);
      date.setDate(this.form.controls['dateOfBirth'].value.day);
    } else {
      if (this.client?.dateOfBirth) date = new Date(this.client?.dateOfBirth);
      else date = new Date();
    }
    const request = {
      name: this.form.controls['name'].value,
      dateOfBirth: date,
      document: this.form.controls['document'].value,
      address: {
        zipCode: this.form.controls['zipCode'].value,
        country: this.form.controls['country'].value,
        state: this.form.controls['state'].value,
        city: this.form.controls['city'].value,
        address: this.form.controls['address'].value,
        additionalInformation:
          this.form.controls['additionalInformation'].value,
        number: this.form.controls['number'].value,
      },
    };

    this.clientService.updateUserInfo(request).subscribe({
      next: (data: any) => {
        this.toastrService.success('Usuário atualizado com sucesso!', '', {
          progressBar: true,
        });
        if (this.client?.phone && !this.client?.email) {
          this.router.navigate(['/logged/verify-email']);
        } else if (this.client?.email && !this.client?.phone) {
          this.router.navigate(['/logged/verify-phone'])
        } else if (this.client?.phone && this.client?.email) {
          this.router.navigate(['/logged/validation-id'])
        }
      },
      error: (err: any) => {
        console.error(err);
        this.toastrService.error('Erro ao atualizar Usuário!', '', {
          progressBar: true,
        });
      },
    });
  }

  validarCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;
    let add = 0;
    for (let i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    add = 0;
    for (let i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  };

  isCPFInvalidAndTouched(): boolean {
    const cpfControl = this.form.controls['document'];

    return cpfControl.dirty && !this.validarCPF(cpfControl.value);
  };

}