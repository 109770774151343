import { Component } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { FeeService } from 'src/app/services/fee.service';

@Component({
  selector: 'app-add-money',
  templateUrl: './add-money.component.html',
  styleUrls: ['./add-money.component.scss']
})
export class AddMoneyComponent {

  valueMoney: boolean = true
  fee: number;
  form: FormGroup;

  coinSelect = {
    name: 'BRL',
    img: '../../../assets/icon/brl-icon.png'
  };

  listCoin: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private feeService: FeeService,
  ) {
    this.form = this.fb.group({
      valuePay: ['0', [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  selectValue(value: number) {
    this.form.patchValue({
      valuePay: value
    })
  }
  
  back() {
    this.valueMoney = true
  }

  submit() {
    let valueCoin = {
      coinSelect: this.coinSelect.name,
      value: this.form.controls['valuePay'].value
    }
    localStorage.setItem('valueCoin', JSON.stringify(valueCoin));
    this.router.navigate(['logged/checkout'])
  }

  selectForCheckout(img: string, name: string) {
    if(name == 'Pix'){
      this.feeService.getPixFee().subscribe({
        next: (data) => {
          this.fee = data.value
          let checkoutType = {
            img: img,
            name: name,
            fee: data.value
          };
          localStorage.setItem('checkoutType', JSON.stringify(checkoutType));
          this.valueMoney = false;
        },
        error: err => {
          console.error(err);
        }
      })
    }else if(name == 'Boleto'){
      this.feeService.getTicketFee().subscribe({
        next: (data) => {
          this.fee = data.value
          let checkoutType = {
            img: img,
            name: name,
            fee: data.value
          };
          localStorage.setItem('checkoutType', JSON.stringify(checkoutType));
          this.valueMoney = false;
        },
        error: err => {
          console.error(err);
        }
      })
    }
 
  }
}
