import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageKeys } from 'src/utils/localStorage.util';
import { ClientConfirmEmailRegistration } from '../dtos/client/client-confirm-email-registration.dto';
import { ClientVerifyEmailConfirmationRequest } from '../dtos/client/client-confirm-email-request.dto';
import { ClientForgotPasswordRegisterRequestDto } from '../dtos/client/client-forgot-password-register-request.dto';
import { ClientForgotPasswordSendCodeRequestDto } from '../dtos/client/client-forgot-password-send-code-request.dto';
import { ClientRegisterRequest } from '../dtos/client/client-register-request.dto';
import { ClientResetPasswordConfirmationRequestDto } from '../dtos/client/client-reset-password-confirmation-request.dto';
import { ClientUpdatePasswordDto } from '../dtos/client/client-update-password.dto';
import { ClientUpdateRequest } from '../dtos/client/client-update-request.dto';
import { ClientVerifyEmailRequest } from '../dtos/client/client-verify-email-request.dto';
import { ClientWalletAddressRequestDto } from '../dtos/client/client-wallet-address-request.dto';
import { ClientInterface } from '../interface/client.interface';
import { BaseService } from './base.service';

@Injectable()
export class ClientService extends BaseService {
  walletApiUrl: string = `${environment.api.path}/client`;
  userInfo: ClientInterface | undefined;
  userData: any;

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  register(dto: ClientRegisterRequest) {
    return this.httpClient
      .post(`${this.walletApiUrl}/register`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  verifyEmailOrPhone(dto: ClientVerifyEmailRequest) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/verify-email-or-phone`,
        dto,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  verifyCode(dto: ClientConfirmEmailRegistration) {
    return this.httpClient
      .post(`${this.walletApiUrl}/verify-code`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getStatusByPhone(phone: any) {
    return this.httpClient
      .get(`${this.walletApiUrl}/phone/${phone}/status`, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getStatusByEmail(email: any) {
    return this.httpClient
      .get(`${this.walletApiUrl}/email/${email}/status`, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmRegistration(dto: ClientResetPasswordConfirmationRequestDto) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/confirm-registration`,
        dto,
        this.anonymousHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmEmailRegistration(dto: ClientConfirmEmailRegistration) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/confirm-email-registration`,
        dto,
        this.anonymousHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  forgotPasswordSendCode(dto: ClientForgotPasswordSendCodeRequestDto) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/reset-password-verification`,
        dto,
        this.anonymousHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getWalletAddress(_id: string, dto: ClientWalletAddressRequestDto) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/wallet-address/${_id}`,
        dto,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  forgotPasswordConfirmationCode(
    dto: ClientResetPasswordConfirmationRequestDto
  ) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/reset-password-confirmation`,
        dto,
        this.anonymousHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  forgotPassword(dto: ClientForgotPasswordRegisterRequestDto) {
    return this.httpClient
      .put(`${this.walletApiUrl}/reset-password`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getAuthenticatedUser() {
    const user = localStorage.getItem(LocalStorageKeys.user);
    return user === null ? undefined : JSON.parse(user);
  }

  getUserInfo(): Observable<ClientInterface> {
    return this.httpClient
      .get(`${this.walletApiUrl}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  checkUserExistence(type: string, value: string) {
    return this.httpClient
      .get(
        `${this.walletApiUrl}/check-user-existence/${type}/${value}`,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateUserInfo(dto: ClientUpdateRequest): Observable<ClientInterface> {
    return this.httpClient
      .put(`${this.walletApiUrl}/edit`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updatePassword(dto: ClientUpdatePasswordDto): Observable<ClientInterface> {
    return this.httpClient
      .put(`${this.walletApiUrl}/update-password`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  uploadAvatar(file: File): Observable<ClientInterface> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient
      .post<any>(
        `${this.walletApiUrl}/upload-avatar`,
        formData,
        this.authorizedHeaderMulti()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  uploadDocumentPhoto(imageData: string): Observable<ClientInterface> {
    const formData = new FormData();
    formData.append('file', imageData);

    return this.httpClient
      .post<any>(
        `${this.walletApiUrl}/upload-document-photo/`,
        formData,
        this.authorizedHeaderMulti()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  createPin(pin: string) {
    return this.httpClient
      .post(
        `${this.walletApiUrl}/register-pin`,
        this.encrypt({pin}),
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updatePin(pin: string, oldPin: string) {
    return this.httpClient
      .put(
        `${this.walletApiUrl}/update-pin`,
        this.encrypt({ pin, oldPin }),
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  checkPin(pin: string) {
    return this.httpClient
      .post(`${this.walletApiUrl}/check-pin`, this.encrypt({pin}) , this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  
  resendCode(dto: ClientVerifyEmailConfirmationRequest) {
    return this.httpClient
      .post(`${this.walletApiUrl}/resend-code`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  setUserDataTransfer(data: any) {
    this.userData = data;
  }

  getUserDataTransfer() {
    return this.userData;
  }
}
