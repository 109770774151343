<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col-md-5">
            <div class="fade-in row pt-5 pb-4">
                <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path
                            d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                            fill="#060A30" />
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="fw-700 font-size-16 color-secundary m-0">Extrato</p>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <button class="button-period" [class.active-button-period]="active === '3days'" (click)="getAccountInfo('3days')">3 Dias</button>
                <button class="button-period" [class.active-button-period]="active === 'week'" (click)="getAccountInfo('week')">Uma Semana</button>
                <button class="button-period" [class.active-button-period]="active === '30days'" (click)="getAccountInfo('30days')">30 Dias</button>
                <button class="button-period" [class.active-button-period]="active === '60days'" (click)="getAccountInfo('60days')">60 Dias</button>
            </div>
            <ng-container *ngIf="activities?.length !== 0 else empty">
                <div *ngFor="let item of activities" class="fade-in-left card-home mt-2 p-2">
                    <div class="row">
                        <div class="col-6 d-flex justify-content-end align-items-center">
                            <p class="fw-400 font-size-11 color-secundary"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2 d-flex align-items-center justify-content-center">
                            <p class="ps-3">
                                <svg width="32" height="28" viewBox="0 0 32 28" fill="none">
                                    <path
                                        d="M24 14C25.0625 14 26 14.9375 26 16C26 17.125 25.0625 18 24 18C22.875 18 22 17.125 22 16C22 14.9375 22.875 14 24 14ZM29 4C30.625 4 32 5.375 32 7V24C32 26.25 30.1875 28 28 28H6C2.625 28 0 25.375 0 22V6C0 2.6875 2.625 0 6 0H25C26.625 0 28 1.375 28 3V4H29ZM30 24V7C30 6.5 29.5 6 29 6H7C6.4375 6 6 5.5625 6 5C6 4.5 6.4375 4 7 4H26V3C26 2.5 25.5 2 25 2H6C3.75 2 2 3.8125 2 6V22C2 24.25 3.75 26 6 26H28C29.0625 26 30 25.125 30 24Z"
                                        fill="white" />
                                </svg>
                            </p>
                        </div>
                        <div class="col-6">
                            <div class="d-flex flex-column">
                                <span class="fw-400 font-size-14 color-secundary letter-spacing-1">{{item.type == 'IN' ?
                                    'Recebeu' : 'Transferência'}}</span>
                                <span class="fw-400 font-size-11 color-gray">{{item.type == 'IN' ?
                                    'dê' : 'para'}} {{item.name}}</span>
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column align-items-end">
                            <p [ngClass]="item.type == 'IN' ? 'color-primary' : 'color-red'"
                                class="letter-spacing-1 fw-400 font-size-11 m-0">{{ item.type == 'IN' ? '' : '-'
                                }}R${{ item.value.toFixed(2) }}</p>
                            <span class="fw-400 font-size-11 color-gray">{{item.updatedAt | date:
                                'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #empty>
                <div class="text-center mt-5 fade-in-left">
                    <p class="font-size-16 color-secundary">Nenhuma movimentação encontrada.</p>
                    <img class="pt-3" src="../../../../assets/icon/none-notifications-img.svg">
                </div>
            </ng-template>

        </div>
    </div>
</section>