import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss']
})
export class SendCodeComponent implements OnInit {

  form: FormGroup;

  PressBackspace: any;

  phone = localStorage.getItem('phone');
  email = localStorage.getItem('email');

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.fb.group({
      code1: ['', Validators.required],
      code2: ['', Validators.required],
      code3: ['', Validators.required],
      code4: ['', Validators.required],
      code5: ['', Validators.required],
      code6: ['', Validators.required],
    })
  }

  ngOnInit(): void {

  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != "") {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === "") {
        if (p != "") {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }


  }

  verifyCode() {
    this.ngxSpinnerService.show();
    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    if (code.length < 6) {
      this.toastrService.error('Código inválido', '', { progressBar: true });
      this.ngxSpinnerService.hide();
      return;
    }

    let authData = {};

    if (this.email !== 'email@email.com') {
      authData = { email: this.email };
    } else {
      authData = { phone: this.phone };
    }

    this.authenticationService.authenticateWithoutPasswordVerify(authData)
      .subscribe({
        next: (data) => {
          this.authenticationService.setAuthenticatedUser(data);
          this.router.navigate(['/logged/home']);
          this.ngxSpinnerService.hide();
        },
        error: (err) => {
          this.toastrService.error('Código inválido', '', { progressBar: true })
          this.ngxSpinnerService.hide();
        }
      });
  }

  goBack() {
    this.location.back();
  }
}