import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ShareDataService } from 'src/app/services/share-data.service';

@Component({
  selector: 'app-adicionar-manualmente',
  templateUrl: './adicionar-manualmente.component.html',
  styleUrls: ['./adicionar-manualmente.component.scss'],
})
export class AdicionarManualmenteComponent {
  form: FormGroup;
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private dataService: ShareDataService
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  exit() {
    this.modalService.dismissAll();
  }

  userDestiny(value: string) {
    this.dataService.serviceData = value;
  }

  save() {
    this.isSubmit = true;
    if (this.form.invalid) {
      this.toastr.error('Preencha os campos corretamente', 'Erro', {
        progressBar: true,
      });
      return;
    }

    const country = '+' + this.form.value.phone.slice(0, 2);
    const state = ' ' + this.form.value.phone.slice(2, 4);
    const start = ' ' + this.form.value.phone.slice(4, 9);
    const end = '-' + this.form.value.phone.slice(9, 13);

    const user = {
      name: this.form.value.name,
      phone: country.concat(state, start, end),
    };

    this.userDestiny(JSON.stringify(user));

    this.modalService.dismissAll();
    this.router.navigate(['logged/send-money-value']);
  }
}
