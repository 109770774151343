import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {

  form: FormGroup;

  isSubmit: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private location: Location,
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit(): void {
  }

  confirm() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }

    localStorage.setItem('newEmail', this.form.controls['email'].value)

    this.clientService.verifyEmailOrPhone({ email: this.form.controls['email'].value }).subscribe({
      next: (data: any) => {
        this.router.navigate(['/verify-code-logged']);
      },
      error: (error: any) => {
        console.error(error);
        if (error.status === 400) {
          this.form.controls['email'].setErrors({ 'emailExists': true });
        } else {
          this.toastrService.error(`Erro ao verificar e-mail.`, '', { progressBar: true });
        }
      }
    });
  }

  back() {
    this.location.back();
  }
}
