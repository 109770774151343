import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientInterface } from 'src/app/interface/client.interface';
import { ClientService } from 'src/app/services/client.service';
import LocalStorageUtil from 'src/utils/localStorage.util';
import { Location } from '@angular/common';
import { KycUserStatus } from 'src/app/dtos/kyc/kyc-user-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  @ViewChild('userIdElement', { static: false }) userIdElement:
    | ElementRef
    | undefined;
  phone = localStorage.getItem('phone');
  client: ClientInterface | undefined;
  uniqueId!: string;

  hasPasswordValue: string;
  hasPassword: any;

  loaded = false

  constructor(
    private router: Router,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private location: Location,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getUserInfo();
    this.ngxSpinnerService.show();
    this.clientService.getUserInfo().subscribe({
      next: (client) => {
        if (client.phone) {
          this.phone = client.phone;
        }
        this.client = client;
        if (client._id) {
          this.uniqueId = `${client._id.substr(0, 5)}${client._id.substr(
            -5
          )}`.toUpperCase();
        }
        this.loaded = true
        this.ngxSpinnerService.hide();
      },
      error: (error) => {
        this.toastrService.error(`${error.error.errors}`, 'Erro', {
          progressBar: true,
        });
        this.loaded = true
        this.ngxSpinnerService.hide();
      },
    });
  }

  goChangePassword() {
    this.router.navigate(['/logged/change-password']);
  }

  goChangePasswordLogged() {
    this.router.navigate(['/create-password-logged']);
  }

  copyValue() {
    if (this.userIdElement) {
      const userIdText = this.userIdElement.nativeElement.innerText;
      const textarea = document.createElement('textarea');
      textarea.value = userIdText;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.toastrService.success('ID copiado', 'Sucesso', {
        progressBar: true,
      });
    }
  }

  shareValue() {
    if (this.userIdElement) {
      const userIdText = this.userIdElement.nativeElement.innerText;
      if (navigator.share) {
        navigator
          .share({
            text: userIdText,
          })
          .then(() => { })
          .catch((error) => {
            this.toastrService.error(
              'Não foi possível compartilhar o ID',
              'Erro',
              { progressBar: true }
            );
          });
      } else {
        this.toastrService.warning(
          'Seu navegador não suporta a funcionalidade de compartilhamento',
          'Aviso',
          { progressBar: true }
        );
      }
    }
  }

  back() {
    this.location.back();
  }

  getUserInfo() {
    this.clientService.getUserInfo().subscribe({
      next: (data) => {
        this.hasPassword = data.hasPassword;
        if (this.hasPassword == true) {
          this.hasPasswordValue = 'true';
        } else {
          this.hasPasswordValue = 'false';
        }
        this.ngxSpinnerService.hide();
      },
      error: (err) => {
        this.toastrService.error('Dados não do usuário não encontrados', '', {
          progressBar: true,
        });
        LocalStorageUtil.clear();
        this.router.navigate(['/login-number']);

        this.ngxSpinnerService.hide();
      },
    });
  }


  handlerKyc() {
    if (this.client && this.hasEssentialInfo(this.client)) {
      if (this.client.kyc?.userStatus === KycUserStatus.WAITING) {
        return;
      }
      this.router.navigate(['/logged/validation-id']);
    } else {
      localStorage.setItem('registerInfos', 'true');
      this.router.navigate(['/personal-info-needed']);
    }
  }

  hasEssentialInfo(data: ClientInterface): boolean {
    return (
      !!data &&
      !!data.dateOfBirth &&
      !!data.document &&
      !!data.name &&
      !!data.address &&
      !!data.address.address &&
      !!data.address.country &&
      !!data.address.city &&
      !!data.address.zipCode &&
      !!data.address.state
    );
  }

  goToVerifyEmail() {
    localStorage.setItem('fromDetails', 'true');
    this.router.navigate(['/logged/verify-email']);
  }

}
