<div class="dropdown">
  <div class="dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false">
    <div *ngIf="selectLang == 'PT-BR'">
      <img alt src="../../../../assets/icon/brazil-svgrepo-com.webp" style="height: 21px; width: 21px;">
    </div>
    <div *ngIf="selectLang == 'EN-US'">
      <img alt src="../../../../assets/icon/united-states-of-america-svgrepo-com.webp" style="height: 21px; width: 21px;">
    </div>
    <div *ngIf="selectLang == 'ES-ES'">
      <img alt src="../../../../assets/icon/spain-svgrepo-com.webp" style="height: 21px; width: 21px;">
    </div>
  </div>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><span class="dropdown-item color-secundary nav-fonts cursor-pointer" (click)="changeLanguage('pt')">Português</span></li>
    <li><span class="dropdown-item color-secundary nav-fonts cursor-pointer" (click)="changeLanguage('en')">Inglês</span></li>
    <li><span class="dropdown-item color-secundary nav-fonts cursor-pointer" (click)="changeLanguage('es')">Espanhol</span></li>
  </ul>
</div>