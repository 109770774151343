import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssasPaymentGeneratePixRequestDto, AssasPixCheckoutResponse } from 'src/app/dtos/payment/assas-payment-generate-pix-request.dto';
import { PaymentService } from 'src/app/services/payment.service';
import { MessageGatewaySocketService } from '../../../../services/sockets/message-gateway.socket.service';

@Component({
  selector: 'app-checkout-pix',
  templateUrl: './checkout-pix.component.html',
  styleUrls: ['./checkout-pix.component.scss']
})
export class CheckoutPixComponent {

  @ViewChild('inputField') inputField!: ElementRef<HTMLInputElement>;
  checkoutType: any;
  valueCoin: any;
  pixResponse: AssasPixCheckoutResponse;
  fee: number;
  pixToDisplay: string;
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private PaymentService: PaymentService,
    private ngxSpinnerService: NgxSpinnerService,
    private messageGatewaySocketService: MessageGatewaySocketService,
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();

    let response: any = localStorage.getItem('checkoutType');
    this.checkoutType = JSON.parse(response);

    let responseValue: any = localStorage.getItem('valueCoin');
    this.valueCoin = JSON.parse(responseValue);

    this.fee = this.checkoutType.fee;

    const dto: AssasPaymentGeneratePixRequestDto = {
      description: '',
      value: this.valueCoin.value,
    }

    this.PaymentService.generatePIX(dto).subscribe({
      next: (data) => {
        this.pixResponse = data;
        this.getPixDisplay(data.pix)
        this.messageGatewaySocketService.requestConfirmPixPaymentResponse();
        this.messageGatewaySocketService.pixId.subscribe({
          next: (response: string) => {
            if (response === this.pixResponse.id)
              this.back();
          }
        })
      },
      error: (err) => {
        this.toastrService.error('Erro ao gerar pix. Tente novamente mais tarde.', 'Erro', { progressBar: true });
        this.back();
      },
      complete: () => {
        this.ngxSpinnerService.hide();
      }
    })
  }

  getPixDisplay(pix: string): string {
    if (!pix) {
        return '';
    }

    const firstTenDigits = pix.substring(0, 10); 
    const lastTenDigits = pix.substring(pix.length - 10);

   return this.pixToDisplay =  `${firstTenDigits}...${lastTenDigits}`;
}

  copyValue() {
    navigator.clipboard.writeText(this.pixResponse.pix)
    this.toastrService.success('Código copiado', 'Sucesso', { progressBar: true });
  }

  continue() {
    this.router.navigate(['/logged/home']);
    localStorage.removeItem('checkoutType');
    localStorage.removeItem('valueCoin');
  }

  back() {
    this.router.navigate(['/home'])
  }
}
