import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-your-activities',
  templateUrl: './your-activities.component.html',
  styleUrls: ['./your-activities.component.scss']
})
export class YourActivitiesComponent implements OnInit {

  activities: any;
  phone: string = localStorage.getItem('phone') || '+55 00 00000-0000';
  user: any;

  active: string

  currentDateString: any;
  threeDaysAgoString: any;
  oneWeekAgoString: any;
  thirtyDaysAgoString: any;
  sixtyDaysAgoString: any;

  periodPreference: string | null;

  constructor(
    private accountService: AccountService,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService,
  ) {
    this.periodPreference = localStorage.getItem('periodPreference')
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.ngxSpinnerService.show()
    
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0];
    this.currentDateString = currentDateString
    
    const threeDaysAgo = new Date(currentDate);
    threeDaysAgo.setDate(currentDate.getDate() - 3);
    const threeDaysAgoString = threeDaysAgo.toISOString().split('T')[0];
    this.threeDaysAgoString = threeDaysAgoString
    
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);
    const oneWeekAgoString = oneWeekAgo.toISOString().split('T')[0];
    this.oneWeekAgoString = oneWeekAgoString
    
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().split('T')[0];
    this.thirtyDaysAgoString = thirtyDaysAgoString
    
    const sixtyDaysAgo = new Date(currentDate);
    sixtyDaysAgo.setDate(currentDate.getDate() - 60);
    const sixtyDaysAgoString = sixtyDaysAgo.toISOString().split('T')[0];
    this.sixtyDaysAgoString = sixtyDaysAgoString
  }

  getAccountInfo(selectedPeriod: string) {
    if(selectedPeriod === this.active) return
    localStorage.setItem('periodPreference', selectedPeriod)

    this.active = selectedPeriod

    let period;

    switch(selectedPeriod) {
      case '3days':
        period = this.threeDaysAgoString
        break;
      case 'week':
        period = this.oneWeekAgoString
        break;
      case '30days':
        period = this.thirtyDaysAgoString
        break;
      case '60days':
        period = this.sixtyDaysAgoString
        break;
    }

    this.accountService.getAccountMovement(period, this.currentDateString).subscribe({
      next: data => {
        this.activities = data;
        this.activities.sort((a: any, b: any) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);
          return dateB.getTime() - dateA.getTime();
        });

        this.ngxSpinnerService.hide();
      },
      error: err => {
        console.error(err);
        this.ngxSpinnerService.hide();
      }
    })
  }

  getUserInfo() {
    this.clientService.getUserInfo()
      .subscribe({
        next: (data) => {
          this.user = data;

          const createdAtDate = new Date(this.user.createdAt);
          const createdAtString = createdAtDate.toISOString().split('T')[0];

          this.user.createdAt = createdAtString;

          if(this.periodPreference) {
            this.getAccountInfo(this.periodPreference);
            this.active = this.periodPreference
          } else {
            this.getAccountInfo('week');
            this.active = 'week'
          }

        },
        error: (err) => {
          console.log(err);
        }
      })
  }
}
