import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-money-set2',
  templateUrl: './send-money-set2.component.html',
  styleUrls: ['./send-money-set2.component.scss']
})
export class SendMoneySet2Component implements OnInit {

    constructor(
      private router: Router
    ) {

  }

  ngOnInit(): void {

  }

  openContacts() {
    const width = screen.width;

    if (width <= 1241) {
      window.open('tel://');
    } else {
      this.router.navigate(['/logged/send-money-contact']);
    }
  }

}
