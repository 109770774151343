import { Component } from '@angular/core';
import { BuySuccessComponent } from '../buy/buy-success/buy-success.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent {

  
  form: FormGroup;

  coinSelect?: any;

  listCoin: any = [];

  coinReceiveSelect: any;

  listCoinReceive: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private location: Location
  ) {
    this.form = this.formBuilder.group({
      coin: ['', [Validators.required]],
      valuePay: ['', [Validators.required]],
      coinReceive: ['', [Validators.required]],
      valueRecevice: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.coinSelect = {
      name: 'BRL',
      img: '../../../assets/icon/brl-icon.png'
    };

    this.listCoin = [
      {
        name: 'BRL',
        img: '../../../assets/icon/brl-icon.png'
      },
      {
        name: 'USDT',
        img: '../../../assets/icon/usdt-icon.png'
      },
      {
        name: 'BUSD',
        img: '../../../assets/icon/busd-icon.png'
      },
    ];

    this.coinReceiveSelect = {
      name: 'ETH',
      img: '../../../assets/icon/eth-icon.png'
    }

    this.listCoinReceive = [
      {
        name: 'ETH',
        img: '../../../assets/icon/eth-icon.png'
      },
      {
        name: 'BNB',
        img: '../../../assets/icon/bnb-icon.png'
      },
      {
        name: 'MATIC',
        img: '../../../assets/icon/matic-icon.png'
      },
    ]
  }

  selectCoin(coin: any) {
    this.coinSelect = coin
  }

  selectCoinReceive(coinReceive: any) {
    this.coinReceiveSelect = coinReceive
  }

  goToComents() {
    this.modalService.open(BuySuccessComponent)
  }

  submit() {
  }

  back() {
    this.location.back();
  }

}
