import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPinModelComponent } from 'src/app/components/confirm-pin-model/confirm-pin-model.component';
import { AssasGetPixInformationRequestDto } from 'src/app/dtos/account/AssasGetPixInformationRequest.dto';
import { AssasTransferPixConfirmationRequestDto } from 'src/app/dtos/account/AssasTransferPixConfirmationRequest.dto';
import { AccountService } from 'src/app/services/account.service';
import { BankAccount } from 'src/app/types/pixInfo.type';

@Component({
  selector: 'app-key-pix-message',
  templateUrl: './key-pix-message.component.html',
  styleUrls: ['./key-pix-message.component.scss']
})
export class KeyPixMessageComponent {
  @ViewChild('chooseMethod') modal: any;
  @ViewChild('changeValueModal') changeValueModal: any;

  form: FormGroup;
  formModal: FormGroup;
  formChangeValue: FormGroup;

  chosenValue: number;
  chosenPixValue: any;
  keyType: string;
  keyPIX: string;
  FormatedChosenValue: string;
  taxedFormatedChosenValue: string;
  receiverData: BankAccount;
  loaded = false;
  tax: number;

  balanceCodeWallet: number;

  pinCode: string = '';

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private location: Location,
    private ngxSpinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private router: Router
  ) {
    // Verify Cons

    let responseChosenValue: any = localStorage.getItem('chosenPixValue')

    this.chosenPixValue = JSON.parse(responseChosenValue);
    console.log('chosen', this.chosenPixValue)

    const chosenValue = this.chosenPixValue.value;

    this.tax = this.chosenPixValue.fee
    console.log(this.tax)
    if (chosenValue) this.chosenValue = JSON.parse(chosenValue)
    else this.router.navigate(['/logged/send-pix'])

    const keyType = localStorage.getItem('keyType')
    if (keyType) this.keyType = keyType
    else this.router.navigate(['/logged/send-pix'])
    console.log(keyType)
    const keyPIX = localStorage.getItem('keyPIX')
    if (keyPIX) this.keyPIX = keyPIX
    else this.router.navigate(['/logged/send-pix'])
    console.log('his.keyPIX ', this.keyPIX )
    //this.ngxSpinnerService.show()

    this.FormatedChosenValue = this.chosenValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    this.taxedFormatedChosenValue = (this.chosenValue - this.tax).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

    // Forms
    this.form = this.fb.group({
      pixMessage: ['', [
        Validators.minLength(5)
      ]],
    })

    this.formChangeValue = this.fb.group({
      valuePix: [this.chosenValue, Validators.required],
    })

    this.formModal = this.fb.group({
      sendCode: ['PHONE']
    })

  }

  ngOnInit() { }

  goToCheckPin() {
    if (this.form.controls['pixMessage'].value.length < 5 && this.form.controls['pixMessage'].value.length > 0) {
      this.toastrService.error('A mensagem precisa ter pelo menos 5 dígitos', '', { progressBar: true })
      return
    }

    if (this.form.controls['pixMessage'].value.length >= 5) {
      localStorage.setItem('PIXmessage', this.form.controls['pixMessage'].value)
    }
    this.router.navigate(['/logged/send-pix-verify']);
  }

  submitChangePIXValue() {
    if (this.formChangeValue.controls['valuePix'].value === 0) {
      this.toastrService.error('Insira um valor', '', { progressBar: true })
      return
    }
    if (this.formChangeValue.controls['valuePix'].value && (this.formChangeValue.controls['valuePix'].value + 2.5) > this.balanceCodeWallet) {
      this.toastrService.error('Saldo insuficiente', '', { progressBar: true })
      return
    }

    // SUCCESS
    this.chosenValue = this.formChangeValue.controls['valuePix'].value
    this.FormatedChosenValue = this.chosenValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    this.taxedFormatedChosenValue = (this.chosenValue - this.tax).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

    localStorage.setItem('chosenPixValue', this.formChangeValue.controls['valuePix'].value)
    this.toastrService.success('Valor alterado com sucesso', '', { progressBar: true })
    this.close()
  }

  openChangeValueModal() {
    this.modalService.open(this.changeValueModal)
  }

  back() {
    this.location.back();
  }

  close() {
    this.modalService.dismissAll();
  }
}
