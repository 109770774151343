<section class="container min-vh-100">
    <div class="row justify-content-center">
        <ng-container *ngIf="!pixResponse else responsePage">
            <div class="col-md-5 fade-in">
                <div class="row pt-5">
                    <div [routerLink]="['/logged/receive-money']" class="col-2 align-items-start cursor-pointer">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                                fill="#060A30" />
                        </svg>
                    </div>
                    <div class="col-8 d-flex justify-content-center align-items-center">
                        <p class="fw-700 font-size-16 color-secundary m-0">Cobrar</p>
                    </div>
                </div>
                <div class="accordion accordion-flush mt-3 fade-in-left" id="accordionFlush">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                <div class="d-flex justify-content-between align-items-center flex-fill">
                                    <span class="color-secundary">{{ 'CHECKOUT.AMOUNT_TO_PAY' | translate }}</span>
                                    <span class="fw-400 font-size-14 color-secundary me-3">{{valueToReceive| currency: 'R$ '}}</span>
                                </div>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse show"
                            data-bs-parent="#accordionFlush">
                            <div class="accordion-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'CHECKOUT.TAXES' | translate }}</p>
                                    <p class="fw-400 font-size-14 color-secundary mb-0">R$ 2,50</p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mt-2">
                                    <p class="fw-400 font-size-14 color-secundary mb-0">Você receberá</p>
                                    <p class="fw-400 font-size-14 color-secundary mb-0">{{ (valueToReceive - 2.5) | currency: 'R$ '}}</p>
                                </div>
                                <hr>
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="fw-400 font-size-14 color-secundary">{{ 'CHECKOUT.TRANSACTION_DURATION' | translate }}</p>
                                    <p class="fw-400 font-size-14 color-secundary">{{ 'CHECKOUT.PIX_DURATION' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form [formGroup]="form">
                    <p class="fw-400 font-size-16 color-secundary mt-3">Escreva uma mensagem</p>
                    <p class="fw-400 font-size-14 color-gray">
                        Quem for te pagar vai ver essas informações
                    </p>
                    <input type="text" formControlName="mensagem"
                        class="input-transparent-darkgreen fw-500 font-size-14 w-100" id="mensagem" placeholder="Mensagem..." maxlength="50">

                    <div class="d-flex flex-column fw-800 font-size-14 mt-5 pb-5">
                        <button [disabled]="this.form.invalid" (click)="submit()" class="button-green letter-spacing-1 fw-700 font-size-14">Gerar
                            cobrança</button>
                        <button [routerLink]="['/receive-money']"
                            class="btn btn-outline-dark bg-transparent border-none mt-4 color-primary">Cancelar</button>
                    </div>
                </form>
            </div>
        </ng-container>

        <ng-template #responsePage>
            <div class="col-md-5 fade-in">
                <div class="row pt-5">
                    <div [routerLink]="['/logged/receive-money']" class="col-2 align-items-start cursor-pointer">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                                fill="#060A30" />
                        </svg>
                    </div>
                    <div class="col-8 d-flex justify-content-center align-items-center">
                        <p class="fw-700 font-size-16 color-secundary m-0">PIX</p>
                    </div>
                </div>
                <p class="fw-400 mx-auto w-75 font-size-14 color-gray text-center pt-5">
                    Mostre o QR code na sua tela, copie o código ou envie um link
                    com todos os dados para quem vai te pagar.
                </p>

                <!-- QR CODE -->
                <div class="d-flex justify-content-center mt-5 mb-5 fade-in-left">
                    <qrcode [cssClass]="'qrcode'" [qrdata]="pixResponse.payload" [colorDark]="'#4A35E8'" [colorLight]="'#FFFFFF'" [width]="225"
                        [imageHeight]="225"
                        [errorCorrectionLevel]="'M'" style="border-radius: 10px;"></qrcode>
                </div>

                <p class="fw-400 font-size-16 color-secundary">Valor a ser recebido</p>
                <div class="d-flex align-items-center">
                    <i class="bi bi-wallet" style="color: #4A35E8 !important;"></i>
                    <p class="fw-light color-secundary mb-0 ms-2">{{(valueToReceive - 2.5) | currency: 'R$ '}}</p>
                </div>
                <p class="fw-bold text-center color-secundary mt-4 fade-in-left">Código do PIX</p>
                <div class="d-flex justify-content-between align-items-start border-green rounded-3 fade-in-left">
                    <p class="form-control bg-transparent text-break py-3 color-secundary border-0 mb-0">
                        {{ pixResponse.payload }}
                    </p>
                    <i class="bi bi-files color-secundary me-3 mt-3 cursor-pointer" (click)="copyValue()"></i>
                </div>
                <p class="text-center mt-2 color-lightgreen fade-in">Código válido por 30 minutos</p>

                <div class="d-flex flex-column mt-5 pt-3 pb-5">
                    <button (click)="sendBiling()" class="button-green letter-spacing-1 fw-700 font-size-14">Concluir</button>
                </div>
            </div>
        </ng-template>
    </div>
</section>