import { Component } from '@angular/core';

@Component({
  selector: 'app-request-card-success',
  templateUrl: './request-card-success.component.html',
  styleUrls: ['./request-card-success.component.scss']
})
export class RequestCardSuccessComponent {

}
