import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { WalletService } from 'src/app/services/wallet.service';
import { ClientInterface } from 'src/app/interface/client.interface';
import { ClientService } from 'src/app/services/client.service';
import { CriptoValuesService } from 'src/app/services/cripto-values.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cripto',
  templateUrl: './cripto.component.html',
  styleUrls: ['./cripto.component.scss']
})
export class CriptoComponent implements OnInit {

  criptoBalances: any;
  userId: string | undefined;
  loadedCriptoBalance = false;
  user: any = localStorage.getItem('user');

  walletBalanceInfos: any = localStorage.getItem('walletBalance');

  totalUsdValue: number = 0;

  constructor(
    private location: Location,
    private walletService: WalletService,
    private criptoValues: CriptoValuesService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {

    this.ngxSpinnerService.show();
    if (!this.user) {
      this.router.navigate(['/']);
    }

    this.userId = JSON.parse(this.user).id;
    this.walletBalance();

  }

  walletBalance() {

    this.totalUsdValue = 0;

    if (this.userId) {
      let balances = JSON.parse(this.walletBalanceInfos);

      this.criptoBalances = [
        {
          icon: '../../../assets/icon/icon-tether.svg',
          name: 'Tether',
          coinCripto: 'USDT',
        },
        {
          icon: '../../../assets/icon/icon-bitcoin.svg',
          name: 'Bitcoin',
          coinCripto: 'BTC',
        },
        {
          icon: '../../../assets/icon/icon-ethereum.svg',
          name: 'Ethereum',
          coinCripto: 'ETH',
        },
        {
          icon: '../../../assets/icon/icon-busd.svg',
          name: 'Binance USD',
          coinCripto: 'BUSD',
        },
      ]

      const response = this.criptoValues.setWalletBalance(balances);
      this.criptoBalances = response.criptoBalances;

      let saldoCrypto = 0;

      for (let i = 0; i < balances.balances.length; i++) {

        const balance = balances.balances[i];

        if (!isNaN(balance.valueInUsd)) {
          saldoCrypto += Number(balance.valueInUsd);
        }
      }

      this.totalUsdValue = saldoCrypto;

      this.loadedCriptoBalance = true;

      this.ngxSpinnerService.hide();
    }
  }

  goBack() {
    this.location.back();
  }

}
