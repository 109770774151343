<section class="container min-vh-100">
  <div class="row justify-content-center">
    <div class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
      <div class="d-flex justify-content-between align-items-center">
        <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          Enviar dinheiro
        </h1>
        <div class="col-1"></div>
      </div>
      <div class="flex-grow-1 d-flex flex-column pt-5 px-3">
        <h4 class="text-start text-wrap fw-700 font-size-16 letter-spacing-05 line-24 color-secundary my-3">Escolha sua opção preferida</h4>
        <div class="card-add-money d-flex flex-row p-3 align-items-center cursor-pointer" (click)="openContacts()">
          <img src="../../../../assets/icon/green-phone.svg" height="24">
          <div class="flex-grow-1 d-flex flex-column justify-content-start px-3">
            <h4 class="text-start text-wrap fw-700 font-size-14 letter-spacing-05 line-19 color-secundary">Para um contato</h4>
            <p class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">Envie dinheiro para um número<br>de telefone</p>
          </div>
          <img src="../../../assets/icon/arrow-right.svg" class="cursor-pointer">
        </div>
        <div class="card-add-money p-3 mt-3">
          <p class="text-center text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">Você também pode enviar dinheiro para sua<br>conta bancária com fundos recebidos de<br>outros usuários ou sites online.</p>
        </div>
      </div>
    </div>
  </div>
</section>
