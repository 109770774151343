import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TransferCodeAccountsRegisterConfirmationDto } from 'src/app/dtos/account/transfer-code-accounts-register-confirmation.dto';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-transfer-verify-code',
  templateUrl: './transfer-verify-code.component.html',
  styleUrls: ['./transfer-verify-code.component.scss']
})
export class TransferVerifyCodeComponent implements OnInit {
  form: FormGroup;
  phone = localStorage.getItem('phone') || '+55 00 00000-0000'
  PressBackspace: any;

  userInfo: any;
  receiveCode: any;
  receiveValue: any;
  userTransferInfo: any;

  getAccountUserInfo: any

  invalidCode = false;

  // Resend code
  msgkeepcalm = true;
  counterdown: any;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private clientService: ClientService,
    private accountService: AccountService
  ) {
    this.form = this.formBuilder.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.userTransferInfo = this.clientService.getUserDataTransfer();
    this.accountService.getAccountById(this.userTransferInfo[0]).subscribe({
      next: data => {
        this.getAccountUserInfo = data
      },
      error: err => {
        console.error(err);
      }
    })

    this.receiveCode = localStorage.getItem('waySendCode');
    this.receiveValue = localStorage.getItem('chosenTransferValue');
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != '') {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === '') {
        if (p != '') {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  confirmPin() {
    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    this.clientService.checkPin(code).subscribe({
      next: data => {
        if (data === true) {
          this.verifyCode()
          this.invalidCode = false
        }
        else {
          this.toastrService.error('Senha Eletrônica incorreta!');
          this.invalidCode = true
        }
      },
      error: error => {
        this.toastrService.error('Senha Eletrônica incorreta!');
      }
    })
  }

  verifyCode() {
    this.ngxSpinnerService.show();

    const code = Object.values(this.form.value)
      .join('')
      .replace(/[^0-9.]+/g, '');

    if (this.getAccountUserInfo) {
      const firstAccount = this.getAccountUserInfo[0];

      const dto: TransferCodeAccountsRegisterConfirmationDto = {
        pin: code,
        destinationAgency: firstAccount.agency,
        destinationAccountNumber: firstAccount.accountNumber,
        destinationAccountDigit: firstAccount.accountDigit,
        value: Number(this.receiveValue)
      }
      this.accountService.transferCodeAccountsConfirmation(dto).subscribe({
        next: data => {
          this.ngxSpinnerService.hide();
          this.router.navigate(['/logged/transfer-success']);
        },
        error: err => {
          this.ngxSpinnerService.hide();
          console.error(err)
          this.toastrService.error(`${err.error.errors}`, 'Erro', { progressBar: true });
        }
      })
    } else {
      this.ngxSpinnerService.hide();
      this.toastrService.error('Informações da conta do usuário não disponíveis.', '', { progressBar: true });
    }
  }

  back() {
    this.location.back();
  }
}
