import { Component } from '@angular/core';

@Component({
  selector: 'app-pix-success',
  templateUrl: './pix-success.component.html',
  styleUrls: ['./pix-success.component.scss']
})
export class PixSuccessComponent {

}
