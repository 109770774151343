import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BuySuccessComponent } from './buy-success/buy-success.component';
import { TokenService } from 'src/app/services/token.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit {

  form: FormGroup;

  coinSelect?: any;

  listCoin: any = [];

  coinReceiveSelect: any;

  listCoinReceive: any = [];
  contacts: any;
  contactsFiltered: any;

  valuePay: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private tokenService: TokenService,
    private toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private location: Location
  ) {
    this.form = this.formBuilder.group({
      coin: ['', [Validators.required]],
      valuePay: ['', [Validators.required]],
      coinReceive: ['', [Validators.required]],
      valueRecevice: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.coinSelect = {
      name: 'BRL',
      img: '../../../assets/icon/brl-icon.png',
      value: 'REAL'
    };

    this.listCoin = [
      {
        name: 'BRL',
        img: '../../../assets/icon/brl-icon.png',
        value: 'REAL'
      },
      {
        name: 'USDT',
        img: '../../../assets/icon/usdt-icon.png',
        value: 'USDT'
      },
      {
        name: 'BUSD',
        img: '../../../assets/icon/busd-icon.png',
        value: 'BUSD'
      },
    ];

    this.coinReceiveSelect = {
      name: 'ETH',
      img: '../../../assets/icon/eth-icon.png',
      value: 'ETHEREUM'
    }

    this.listCoinReceive = [
      {
        name: 'ETH',
        img: '../../../assets/icon/eth-icon.png',
        value: 'ETHEREUM'
      },
      {
        name: 'BNB',
        img: '../../../assets/icon/bnb-icon.png',
        value: 'BINANCECOIN'
      },
      // {
      //   name: 'MATIC',
      //   img: '../../../assets/icon/matic-icon.png',
      //   value: 'MATIC'
      // },
      {
        name: 'BTC',
        img: '../../../assets/icon/btc-icon.svg',
        value: 'BITCOIN'
      },
    ]
  }

  selectCoin(coin: any) {
    this.coinSelect = coin
  }

  selectCoinReceive(coinReceive: any) {
    this.coinReceiveSelect = coinReceive
  }

  goToComents() {
    this.modalService.open(BuySuccessComponent)
  }

  submit() {
  }

  back() {
    this.location.back();
  }

  convert() {
    this.ngxSpinnerService.show();
    const payload = {
      symbol: this.coinSelect.value,
      quantity: this.valuePay.toString(),
      tokenToBuy: this.coinReceiveSelect.value
    };
  
    this.tokenService.exchangeCoin(payload).subscribe({
      next: data => {
        // this.toastrService.success('Conversão confirmada', 'Sucesso', { progressBar: true });
  
        this.form.patchValue({
          valueRecevice: data
        });
        this.ngxSpinnerService.hide();
      },
      error: error => {
        this.toastrService.error('Não foi possível fazer a conversão no momento.', 'Erro', { progressBar: true });
        this.ngxSpinnerService.hide();
      }
    });
  }
}