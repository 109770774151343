import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientInterface } from 'src/app/interface/client.interface';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  model!: NgbDateStruct;
  form: FormGroup;
  client: ClientInterface | undefined;

  phone = localStorage.getItem('phone');

  senha: string = '';
  senhas: string = '';
  senhass: string = '';
  mostrarSenha: boolean = false;
  mostrarSenha1: boolean = false;
  mostrarSenha2: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {

  }

  confirm() {
    this.ngxSpinnerService.show();
    if (this.form.invalid) {
      return;
    }
    let request = {
      password: this.form.controls['password'].value,
      newPassword: this.form.controls['newPassword'].value
    };

    if (request.newPassword === this.form.controls['confirmNewPassword'].value) {
      this.clientService.updatePassword(request).subscribe({
        next: (data) => {
          this.ngxSpinnerService.hide();
          this.toastrService.success('Senha alterada com sucesso!', 'Sucesso', { progressBar: true });
          this.router.navigate(['/logged/my-account'])
        },
        error: (err) => {
          console.error(err),
          this.ngxSpinnerService.hide();
            this.toastrService.error('Senha atual incorreta!', 'Erro', { progressBar: true })
        }
      })
    } else {
      this.ngxSpinnerService.hide();
      this.toastrService.error('Senhas não conferem', '', { progressBar: true })
    }

  }

  toggleMostrarSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

}
