import { Component } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-receive-money',
  templateUrl: './receive-money.component.html',
  styleUrls: ['./receive-money.component.scss']
})
export class ReceiveMoneyComponent {

  form: FormGroup
  receive: any;
  receiveType: any;
  showValueForReceive = true;

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) {
    this.form = this.fb.group({
      valueReceivePix: ['', Validators.required, [this.minValueValidatorPix()]],
      valueReceive: ['', Validators.required, [this.minValueValidator()]],
    })
  }

  back() {
    this.showValueForReceive = true;
    localStorage.removeItem('receiveType');
    localStorage.removeItem('receiveValue');
    localStorage.removeItem('receiveValuePix');
  }

  selectForCheckout(name: string) {
    let receiveType = {
      name: name,
    };
    localStorage.setItem('receiveType', JSON.stringify(receiveType));

    let responseType: any = localStorage.getItem('receiveType')
    this.receiveType = JSON.parse(responseType);

    this.showValueForReceive = false;
  }

  minValueValidator(): AsyncValidatorFn {
    return (control) => {
      const value = parseFloat(control.value.toString().replace('R$ ', '').replace(',', '.'));

      if (isNaN(value) || value < 5) {
        return of({ minValue: true });
      } else {
        return of(null);
      }
    };
  }

  minValueValidatorPix(): AsyncValidatorFn {
    return (control) => {
      const value = parseFloat(control.value.toString().replace('R$ ', '').replace(',', '.'));

      if (isNaN(value) || value < 5) {
        return of({ minValue: true });
      } else {
        return of(null);
      }
    };
  }

  submit() {
    let responseType: any = localStorage.getItem('receiveType');
    this.receive = JSON.parse(responseType);

    if (this.receive.name === 'Pix') {
      this.router.navigate(['/logged/receive-pix'])
      localStorage.setItem('receiveValuePix', this.form.controls['valueReceivePix'].value);
    } else {
      this.router.navigate(['/logged/receive-ticket'])
      localStorage.setItem('receiveValue', this.form.controls['valueReceive'].value);
    }
  }

}
