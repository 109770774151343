import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  arrCoins: any = [];
  searchText: string = '';
  filteredCoins: any = [];

  constructor(
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.arrCoins = [
      {
        name: 'Bitcoin',
        img: '../../../assets/icon/btc-i.png'
      },
      {
        name: 'Ethereum',
        img: '../../../assets/icon/eth-i.png'
      },
      {
        name: 'BUSD',
        img: '../../../assets/icon/icon-busd.svg'
      },
      {
        name: 'BNB',
        img: '../../../assets/icon/icon-bnb.svg'
      },
      {
        name: 'Tether',
        img: '../../../assets/icon/tether-i.png'
      },
      {
        name: 'USD Coin',
        img: '../../../assets/icon/usd-i.png'
      },
    ];

    this.filteredCoins = this.arrCoins;
  }

  selectCoin(coin: any) {
    this.router.navigate([`/logged/deposit-coin/${coin.name}`]);
  }

  back() {
    this.location.back();
  }

  filterCoins() {
    if (!this.searchText) {
      this.filteredCoins = this.arrCoins;
      return;
    }

    this.filteredCoins = this.arrCoins.filter((coin: any) =>
      coin.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}