import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KycUserStatus } from 'src/app/dtos/kyc/kyc-user-status.enum';
import { ClientInterface } from 'src/app/interface/client.interface';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';
import { OpenExchangeService } from 'src/app/services/open-exchange.service';
import { WalletService } from 'src/app/services/wallet.service';
import { MessageGatewaySocketService } from '../../services/sockets/message-gateway.socket.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user?: any;
  saldoCrypto: any;
  balanceCodeWallet?: number;
  loadedCriptoBalance = false;
  hasNewMessages = false

  selectedCurrency: 'BRL' | 'USD' = 'BRL';
  currencyValue: string;
  currencyCents: string;

  showBalance: boolean = true;

  phone = localStorage.getItem('phone');
  email = localStorage.getItem('email');

  selectedLanguage: string;

  userInfo: ClientInterface;
  dismissSplashCripto = localStorage.getItem('dismissSplashCripto') || false;

  requestCard: boolean | undefined;

  constructor(
    private clientService: ClientService,
    private accountService: AccountService,
    private walletService: WalletService,
    private notificationService: NotificationService,
    private openExange: OpenExchangeService,
    private toastrService: ToastrService,
    private router: Router,
    private messageGatewaySocketService: MessageGatewaySocketService,
  ) { }

  ngOnInit(): void {

    this.messageGatewaySocketService.initializeMain();

    this.clientService.getUserInfo().subscribe({
      next: (data) => {
        console.log('userInfo', data)
        this.userInfo = data;
        this.requestCard = data.requestCard;
        this.walletService.getWalletBalance(data._id).subscribe({

          next: (next) => {
            localStorage.setItem('walletBalance', JSON.stringify(next));
            let saldoCrypto = 0;

            for (let i = 0; i < next.balances.length; i++) {
              const balance = next.balances[i];

              if (!isNaN(balance.valueInUsd)) {
                saldoCrypto += Number(balance.valueInUsd);
              }
            }

            this.saldoCrypto = saldoCrypto;
          },

          error: (error) => { },
          complete: () => {
            this.loadedCriptoBalance = true;
          },
        });
        this.user = [
          {
            name: data.name,
            balanceWallet: 0,
            avatar: data.avatar || '',
          },
        ];

        this.accountService.getAccount().subscribe({
          next: (data) => {
            this.balanceCodeWallet = data.balance;
          },
        });

        const currencyValue = this.user[0].balanceWallet.toLocaleString(
          'pt-BR',
          { style: 'currency', currency: 'BRL' }
        );

        this.currencyValue = currencyValue.slice(3, currencyValue.length - 3);
        this.currencyCents = currencyValue.slice(
          currencyValue.length - 3,
          currencyValue.length
        );
      },

    });

    this.selectedLanguage = localStorage.getItem('selectedLanguage') || 'pt';

    this.notificationService.hasNewMessages().subscribe({
      next: data => {
        if (data === true) {
          this.hasNewMessages = data;
        }
      },
      error: error => {
        this.toastrService.error('Erro ao carregar mensagens', 'Erro', { progressBar: true });
      }
    })
  }

  toggleBalance(): void {
    this.showBalance = !this.showBalance;
  }

  formatBalance(balance: string): string {
    if (this.showBalance) {
      return balance;
    } else {
      const hiddenBalance = '*'.repeat(balance.length);
      return hiddenBalance;
    }
  }

  async showBRL() {
    const currencyValue = this.user[0].balanceWallet.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.currencyValue = currencyValue.slice(3, currencyValue.length - 3);
    this.currencyCents = currencyValue.slice(
      currencyValue.length - 3,
      currencyValue.length
    );
  }

  showUSD() {
    this.openExange.getExchangeRates().subscribe((data) => {
      const valueString =
        this.currencyValue
          .replaceAll(',', '')
          .replaceAll('.', '')
          .replaceAll(' ', '') +
        '.' +
        this.currencyCents
          .replaceAll(',', '')
          .replaceAll('.', '')
          .replaceAll(' ', '');
      const localeStringUSD = (
        Number(valueString) / data.rates.BRL
      ).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

      this.currencyValue = localeStringUSD.slice(1, localeStringUSD.length - 3);
      this.currencyCents = localeStringUSD.slice(
        localeStringUSD.length - 3,
        localeStringUSD.length
      );
    });
  }

  toggleCurrency() {
    switch (this.selectedCurrency) {
      case 'BRL':
        this.selectedCurrency = 'USD';
        this.showUSD();
        break;

      case 'USD':
        this.selectedCurrency = 'BRL';
        this.showBRL();
        break;
    }
  }

  cobrarClicked(route: string) {
    if (!this.hasEssentialInfo(this.userInfo) && !this.userInfo.blockedByAdmin) {
      this.router.navigate(['/logged/personal-info-needed']);
      localStorage.setItem('registerInfos', 'true');
    } else {
      if (!(this.userInfo.kyc?.userStatus === KycUserStatus.CHECKED && !this.userInfo.blockedByAdmin) ) {
        this.router.navigate(['/logged/details']);
        if (this.userInfo.blockedByAdmin === false) {
          this.toastrService.warning('Valide sua identidade para prosseguir!', '', { progressBar: true });

        }
        return
      }

      if (!this.userInfo.pin) {
        this.router.navigate(['/logged/pin']);
        this.toastrService.warning('Crie sua Senha Eletrônica para prosseguir', '', { progressBar: true });
        return
      }

      else this.router.navigate([route]);
    }
  }

  hasEssentialInfo(data: ClientInterface): boolean {
    return (
      !!data &&
      !!data.dateOfBirth &&
      !!data.document &&
      !!data.name &&
      !!data.address &&
      !!data.address.address &&
      !!data.address.country &&
      !!data.address.city &&
      !!data.address.zipCode &&
      !!data.address.state
    );
  }

  showRescueWarning() {
    this.toastrService.warning(
      this.selectedLanguage === 'pt'
        ? 'Você não esta habilitado no momento para participar desta promoção.'
        : 'You are currently not eligible to participate in this promotion.',
      '',
      { progressBar: true }
    );
  }

  goToCripto() {
    if (this.dismissSplashCripto) this.router.navigate(['/logged/cripto']);
    else this.router.navigate(['/logged/splash-cripto']);
  }

  needInfoAndRequestCard(route: string) {
    if (!this.hasEssentialInfo(this.userInfo)) {
      this.router.navigate(['/logged/personal-info-needed']);
      localStorage.setItem('registerInfos', 'true');
    } else {
      if (!(this.userInfo.kyc?.userStatus === KycUserStatus.CHECKED) || !this.userInfo.pin)
        this.router.navigate(['/logged/details']);
      else this.goToRequestCard();
    }
  }

  goToRequestCard() {
    if (this.requestCard) {
      this.router.navigate(['/logged/request-card-success']);
    } else {
      this.router.navigate(['/logged/request-card']);
    }
  }
}
