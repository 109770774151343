import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdicionarManualmenteComponent } from './adicionar-manualmente/adicionar-manualmente.component';

@Component({
  selector: 'app-send-money-step1',
  templateUrl: './send-money-step1.component.html',
  styleUrls: ['./send-money-step1.component.scss']
})
export class SendMoneyStep1Component {


  constructor(
    private modalService: NgbModal,
  ) {
    
  }

  openModal() {
    this.modalService.open(AdicionarManualmenteComponent, { size: 'sm' })
  }

}
