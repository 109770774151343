import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-login-password',
  templateUrl: './login-password.component.html',
  styleUrls: ['./login-password.component.scss'],
})
export class LoginPasswordComponent {
  form: FormGroup;
  phone = localStorage.getItem('phone');
  email = localStorage.getItem('email');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
  }
  confirm() {
    this.ngxSpinnerService.show();

    const request: { phone?: string; email?: string; password: string } = {
      phone: undefined,
      email: undefined,
      password: '',
    };

    if (this.phone) {
      request.phone = this.phone;
    } else if (this.email) {
      request.email = this.email;
    }

    request.password = this.form.controls['password'].value || '';

    this.authenticationService.authenticate(request).subscribe({
      next: (data) => {
        localStorage.setItem('user', JSON.stringify(data));
        this.authenticationService.setAuthenticatedUser(data);
        this.router.navigate(['/logged/home']);
        this.ngxSpinnerService.hide();
      },
      error: (err) => {
        this.toastrService.error(`Senha incorreta ou não cadastrada`, 'Erro', {
          progressBar: true,
        });
        this.ngxSpinnerService.hide();
      },
    });
  }

  
  sendCodeWithoutPassword() {
    this.ngxSpinnerService.show();

    const phone = this.phone || '';
    const email = this.email || '';

    this.authenticationService
      .authenticateWithoutPassword({ phone: phone || email })
      .subscribe({
        next: (data) => {
          this.toastrService.success('Código enviado com sucesso!', '', {
            progressBar: true,
          });
          this.router.navigate(['/login-without-password']);
          this.ngxSpinnerService.hide();
        },
        error: (error) => {
          console.error(error);
          this.toastrService.error(`${error.error.errors}`, 'Erro', {
            progressBar: true,
          });
          this.ngxSpinnerService.hide();
        },
      });
  }


  goToFaceId() {
    this.router.navigate(['/login-face-id']);
  }

  forgotPassword() {
    this.ngxSpinnerService.show();
    this.clientService
      .forgotPasswordSendCode({ phone: this.phone || '' })
      .subscribe({
        next: (data) => {
          this.router.navigate(['forgot-password']);
          this.toastrService.success('Código enviado com sucesso', '', {
            progressBar: true,
          });
          this.ngxSpinnerService.hide();
        },
        error: (error) => {
          this.toastrService.error(`${error.error.errors}`, 'Erro ao enviar código', {
            progressBar: true,
          });
          this.ngxSpinnerService.hide();
        },
      });
  }
}
