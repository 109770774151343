<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col-md-5">
            <div class="row pt-5">
                <div (click)="goBack()" class="col-2 align-items-start cursor-pointer">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path
                            d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                            fill="#060A30" />
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="148px"></p>
                </div>
            </div>
            <h6 class="fw-700 font-size-21 color-secundary text-center my-5">{{ 'PERSONAL_DATA.TITLE' | translate }}</h6>
            <div class="fw-400 font-size-16 color-secundary text-center mb-5">
                <span>{{ 'PERSONAL_DATA.PERSONALIZED_EXPERIENCE' | translate }}</span>
            </div>
            <form [formGroup]="form">
                <div class="mb-3 d-flex flex-column">
                    <label for="name" class="form-label fw-700 font-size-14 color-secundary">{{ 'PERSONAL_DATA.FULL_NAME' | translate }}</label>
                    <input type="text" formControlName="name" class="input-transparent fw-400 font-size-14" id="name"
                        placeholder="{{ 'PERSONAL_DATA.NAME_SURNAME' | translate }}">
                </div>
                <div class="mb-3 d-flex flex-column">
                    <label for="dateOfBith" class="form-label fw-700 font-size-14 color-secundary">{{ 'PERSONAL_DATA.DATE_OF_BIRTH' | translate }}</label>
                    <input type="text" mask="00/00/0000" formControlName="dateOfBirth" [class.border-red]="dateOfBirthInvalid"
                        class="input-transparent fw-400 font-size-14" id="dateOfBith" placeholder="{{ 'PERSONAL_DATA.DATE_OF_BIRTH' | translate }}">
                </div>
                <div *ngIf="dateOfBirthInvalid">
                    <p class="d-flex fw-400 font-size-14 color-red">Data de nascimento inválida.</p>
                </div>
                <div class="mb-3 d-flex flex-column">
                    <label for="cpf" class="form-label fw-700 font-size-14 color-secundary">CPF</label>
                    <input type="text" mask="000.000.000-00" formControlName="cpf" [class.border-red]="cpfInUse"
                        class="input-transparent fw-400 font-size-14" id="cpf" placeholder="CPF">
                </div>
                <div *ngIf="isCPFInvalidAndTouched()">
                    <p class="d-flex fw-400 font-size-14 color-red">{{ 'PERSONAL_DATA.INVALID_CPF' | translate }}</p>
                </div>
                <div *ngIf="cpfInUse">
                    <p class="d-flex fw-400 font-size-14 color-red">Este CPF já está em uso.</p>
                </div>
                <div class="mt-4 mb-3">
                    <button [disabled]="this.form.invalid || !this.form.dirty || isCPFInvalidAndTouched()" class="button-green fw-800 font-size-14"
                        (click)="confirm()">{{ 'PERSONAL_DATA.CONFIRM' | translate }}</button>
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <button (click)="goForward()" class="bg-transparent color-primary border-none fw-800 font-size-14 mt-3">{{ 'PERSONAL_DATA.SKIP' |
                        translate }}</button>
                </div>
            </form>
        </div>
    </div>
</section>