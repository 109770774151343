import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { ClientForgotPasswordRegisterRequestDto } from 'src/app/dtos/client/client-forgot-password-register-request.dto';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  form: FormGroup;

  senha: string = '';
  senhas: string = '';
  mostrarSenha: boolean = false;
  phone: string = localStorage.getItem('phone') || '';
  email: string = localStorage.getItem('email') || '';

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(60),
      ])],
      confirmPassword: [''],
    })
  }

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }

  toggleMostrarSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  verificarForca() {
    this.senha = this.form.controls['password']?.value;
  }

  confirm() {
    this.autheticantionUser();
  }

  autheticantionUser() {
    if (this.phone) {
      let dto: ClientForgotPasswordRegisterRequestDto = {
        phone: this.phone,
        newPassword: this.form.controls['password'].value
      }
      this.clientService.forgotPassword(dto)
        .subscribe({
          next: (data) => {
            this.ngxSpinnerService.hide();
            this.toastrService.success('Senha criada com sucesso!', '', { progressBar: true });
            this.router.navigate(['/logged/home']);
            this.userConfirmPassword();
          },
          error: (err) => {
            this.toastrService.error('Não foi possível criar senha', '', { progressBar: true })
            this.ngxSpinnerService.hide();
          }
        })
    } else if (this.email) {
      let dto: ClientForgotPasswordRegisterRequestDto = {
        email: this.email,
        newPassword: this.form.controls['password'].value
      }
      this.clientService.forgotPassword(dto)
        .subscribe({
          next: (data) => {
            this.ngxSpinnerService.hide();
            this.toastrService.success('Senha criada com sucesso!', '', { progressBar: true });
            this.router.navigate(['/logged/home']);
            this.userConfirmPassword();
          },
          error: (err) => {
            this.toastrService.error('Não foi possível criar senha', '', { progressBar: true })
            this.ngxSpinnerService.hide();
          }
        })
    }

  }

  userConfirmPassword() {
    const request: any = {
      hasPassword: true
    }

    this.clientService.updateUserInfo(request).subscribe({
      next: data => {
      },
      error: err => {
        console.error(err);
        this.toastrService.error(`${err.error.errors}`, 'Erro', { progressBar: true })
        this.ngxSpinnerService.hide();
      }
    });
  }

  goForward() {
    this.router.navigate(['/logged/home']);
  }
}
