<section class="container min-vh-100 h-100">
  <div class="row justify-content-center py-5">
    <div class="fade-in col-md-5">
      <div class="d-flex justify-content-between align-items-center">
        <div [routerLink]="['/logged/my-account']" class="col-2 align-items-start cursor-pointer">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
              fill="#060A30" />
          </svg>
        </div>
        <h1 class="fw-500 font-size-16 color-secundary cursor-click text-center mb-0 mt-1">
          {{ 'PERSONAL_INFO.TITLE' | translate }}
        </h1>
        <div style="width: 40px; height: 40px;"></div>
      </div>
      <form class="mt-4" [formGroup]="form">
        <h1 class="fw-700 font-size-16 color-secundary cursor-click mb-0 mt-1">
          {{ 'PERSONAL_INFO.PROFILE' | translate }}
        </h1>
        <div class="my-3 d-flex flex-column">
          <input type="text" (ngModelChange)="checkChanges()" formControlName="name"
            class="input-transparent-darkgreen fw-500 font-size-14" id="name"
            placeholder="{{ 'PERSONAL_INFO.NAME_PLACEHOLDER' | translate }}">
        </div>
        <div class="my-3 d-flex flex-column">
          <input type="text" (ngModelChange)="checkChanges()" formControlName="dateOfBirth" name="dp"
            [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1930, month: 1, day: 1}"
            class="input-transparent-darkgreen fw-500 font-size-14" id="dateOfBirth"
            placeholder="{{ 'PERSONAL_INFO.BIRTHDATE_PLACEHOLDER' | translate }}" (click)="d.toggle()">
        </div>
        <div *ngIf="client?.document" class="my-3 d-flex flex-column">
          <input type="text" (ngModelChange)="checkChanges()" formControlName="document"
            class="input-transparent-darkgreen fw-500 font-size-14" id="document"
            placeholder="{{ 'PERSONAL_INFO.CPF_PLACEHOLDER' | translate }}" mask="000.000.000-00" readonly>
        </div>
        <div *ngIf="!client?.document" class="my-3 d-flex flex-column">
          <input
            [ngClass]="this.form.invalid && this.submitted ? 'input-transparent-login-number-red' : 'input-transparent-darkgreen'"
            type="text" (ngModelChange)="checkChanges()" formControlName="document" class="fw-500 font-size-14"
            id="document" placeholder="{{ 'PERSONAL_INFO.CPF_PLACEHOLDER' | translate }}" mask="000.000.000-00">
        </div>
        <div *ngIf="this.submitted && form.controls['document'].hasError('documentExists')">
          <p class="d-flex fw-400 font-size-14 color-red">CPF já cadastrado</p>
        </div>
        <div *ngIf="isCPFInvalidAndTouched()">
          <p class="d-flex fw-400 font-size-14 color-red">{{ 'PERSONAL_INFO.INVALID_CPF' | translate }}</p>
        </div>
        <h1 class="fw-700 font-size-16 color-secundary cursor-click mb-0 my-3">
          {{ 'PERSONAL_INFO.ADDRESS' | translate }}
        </h1>
        <div class="mt-3 d-flex flex-column pe-2">
          <input
            [ngClass]="this.form.invalid && this.submittedCep ? 'input-transparent-login-number-red' : 'input-transparent-darkgreen'"
            (ngModelChange)="checkChanges()" type="text" formControlName="zipCode" class="fw-500 font-size-14 w-50"
            id="zipCode" placeholder="{{ 'PERSONAL_INFO.ZIP_CODE_PLACEHOLDER' | translate }}" (input)="searchViaCEP()"
            maxlength="9" mask="00000-000">
        </div>
        <div *ngIf="this.submittedCep" class="mt-1">
          <p class="d-flex fw-400 font-size-14 color-red m-0">CEP não encontrado</p>
        </div>
        <div class="row">
          <div class="col-md-6 col-12 mt-3">
            <input (ngModelChange)="checkChanges()" type="text" formControlName="country"
              class="input-transparent-darkgreen fw-500 font-size-14 w-100" id="country"
              placeholder="{{ 'PERSONAL_INFO.COUNTRY_PLACEHOLDER' | translate }}">
          </div>
          <div class="col-md-6 col-12 mt-3">
            <input (ngModelChange)="checkChanges()" type="text" formControlName="state"
              class="input-transparent-darkgreen fw-500 font-size-14 w-100" id="state"
              placeholder="{{ 'PERSONAL_INFO.STATE_PLACEHOLDER' | translate }}">
          </div>
        </div>
        <div class="my-3 d-flex flex-column">
          <input (ngModelChange)="checkChanges()" type="text" formControlName="city"
            class="input-transparent-darkgreen fw-500 font-size-14" id="city"
            placeholder="{{ 'PERSONAL_INFO.CITY_PLACEHOLDER' | translate }}">
        </div>
        <div class="mt-3 d-flex flex-column">
          <input (ngModelChange)="checkChanges()" type="text" formControlName="address" class="input-transparent-darkgreen fw-500 font-size-14"
            id="address" placeholder="{{ 'PERSONAL_INFO.ADDRESS_PLACEHOLDER' | translate }}">
        </div>
        <div class="row">
          <div class="col-md-6 col-12 mt-3">
            <input (ngModelChange)="checkChanges()" type="number" formControlName="number"
              class="input-transparent-darkgreen fw-500 font-size-14 w-100" id="number"
              placeholder="{{ 'PERSONAL_INFO.NUMBER_PLACEHOLDER' | translate }}">
          </div>
          <div class="col-md-6 col-12 mt-3">
            <input (ngModelChange)="checkChanges()" type="text" formControlName="additionalInformation"
              class="input-transparent-darkgreen fw-500 font-size-14 w-100" id="additionalInformation"
              placeholder="{{ 'PERSONAL_INFO.ADDITIONAL_INFORMATION_PLACEHOLDER' | translate }}">
          </div>
        </div>
        <div class="d-flex flex-column pt-5">
          <button [disabled]="this.form.invalid || !this.form.dirty || isCPFInvalidAndTouched() || !infoHasChanged"
            class="button-green fw-800 font-size-14" (click)="confirm()">{{ 'PERSONAL_INFO.CONFIRM' | translate
            }}</button>
        </div>
      </form>
    </div>
  </div>
</section>