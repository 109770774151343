<section class="container min-vh-100 pb-5">
  <!-- HEADER -->
  <div class="fade-in row pt-5">
    <div (click)="back()" class="col-2 align-items-start cursor-pointer">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path
          d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
          fill="#060A30" />
      </svg>
    </div>
    <div class="col-8 d-flex justify-content-center align-items-center">
      <p *ngIf="!client?.pin" class="fw-700 font-size-16 color-secundary m-0">Senha Eletrônica</p>
      <p *ngIf="client?.pin" class="fw-700 font-size-16 color-secundary m-0">Senha Eletrônica</p>
    </div>
  </div>

  <!-- FORMS -->
  <div class="row justify-content-center mt-3">
    <div *ngIf="!showPin"
    (click)="showPin = !showPin"
    class="cursor-pointer d-flex fw-400 text-center justify-content-center align-items-center font-size-14 color-secundary pt-2 mb-2">
      Mostrar números
      <span class="bi" [ngClass]="showPin ? 'bi-eye' : 'bi-eye-slash'" class="ms-2 font-size-16"></span>
    </div>

    <div *ngIf="showPin"
      (click)="showPin = !showPin"
      class="cursor-pointer d-flex fw-400 text-center justify-content-center align-items-center font-size-14 color-secundary pt-2 mb-2">
      Ocultar números
      <span class="bi" [ngClass]="showPin ? 'bi-eye' : 'bi-eye-slash'" class="ms-2 font-size-16"></span>
    </div>

    <!-- OLD PIN FORM -->
    <form [formGroup]="oldPINForm" class="col-lg-12 px-3 mt-4 mb-4" *ngIf="client?.pin">
      <label class="form-label fw-700 font-size-14 color-secundary text-center w-100">Insira sua antiga Senha Eletrônica</label>
      <div class="d-flex d-inline-block justify-content-center w-100 mt-1">

        <input type="{{ showPin ? 'tel' : 'password' }}" #oldCode1 mask="0"
            class="btn w46h46 border-code border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="oldCode1" (keyup)="move($event, '',oldCode1,oldCode2)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #oldCode2 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="oldCode2" (keyup)="move($event,oldCode1,oldCode2,oldCode3)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #oldCode3 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="oldCode3" (keyup)="move($event, oldCode2,oldCode3,oldCode4)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #oldCode4 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="oldCode4" (keyup)="move($event, oldCode3,oldCode4,'')" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">
      </div>
    </form>

    <!-- NEW PIN FORM -->
    <form [formGroup]="newPINForm" class="col-lg-12 px-3">
      <label *ngIf="!client?.pin" class="form-label fw-700 font-size-14 color-secundary text-center w-100 mt-4">Cadastre sua Senha Eletrônica</label>
      <label *ngIf="client?.pin" class="form-label fw-700 font-size-14 color-secundary text-center w-100 mt-4">Insira sua nova Senha Eletrônica</label>

      <div class="d-flex d-inline-block justify-content-center w-100 mt-1">
        <input type="{{ showPin ? 'tel' : 'password' }}" #newCode1 mask="0"
            class="btn w46h46 border-code border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="newCode1" (keyup)="move($event, '',newCode1,newCode2)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #newCode2 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="newCode2" (keyup)="move($event,newCode1,newCode2,newCode3)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #newCode3 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="newCode3" (keyup)="move($event, newCode2,newCode3,newCode4)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #newCode4 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="newCode4" (keyup)="move($event, newCode3,newCode4, repeatCode1)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">
      </div>
      <div *ngIf="oldEqualNewPin"
      class="d-flex align-items-center justify-content-center fw-400 font-size-11 color-red pt-1">A nova Senha deve ser diferente da antiga!</div>
    </form>

    <!-- REPEAT PIN FORM -->
    <form [formGroup]="repeatPINForm" class="col-lg-12 px-3">
      <label *ngIf="!client?.pin" class="form-label fw-700 font-size-14 color-secundary text-center w-100 mt-4">Repita a sua Senha</label>
      <label *ngIf="client?.pin" class="form-label fw-700 font-size-14 color-secundary text-center w-100 mt-4">Repita sua nova Senha</label>

      <div class="d-flex d-inline-block justify-content-center w-100 mt-1">
        <input type="{{ showPin ? 'tel' : 'password' }}" #repeatCode1 mask="0"
            class="btn w46h46 border-code border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="repeatCode1" (keyup)="move($event, '',repeatCode1,repeatCode2)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #repeatCode2 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="repeatCode2" (keyup)="move($event,repeatCode1,repeatCode2,repeatCode3)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #repeatCode3 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="repeatCode3" (keyup)="move($event, repeatCode2,repeatCode3,repeatCode4)" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">

        <input type="{{ showPin ? 'tel' : 'password' }}" #repeatCode4 mask="0"
            class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
            formControlName="repeatCode4" (keyup)="move($event, repeatCode3,repeatCode4,'')" maxlength="1"
            style="background-color: white !important; color: #22232E !important; border: 1px solid black">
      </div>
      <div *ngIf="wrongRepeatPin"
      class="d-flex align-items-center justify-content-center fw-400 font-size-11 color-red pt-1">A Senha Eletrônica deve ser a mesma!</div>
    </form>
      
    <div class="d-flex flex-column pt-5">
      <button [disabled]="newPINForm.invalid || repeatPINForm.invalid" class="button-green fw-800 font-size-14" (click)="confirm()">{{
      'CHANGE_PASSWORD.CONFIRM' | translate }}</button>
    </div>
  </div>
</section>