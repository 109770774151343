import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-transfer-value',
  templateUrl: './transfer-value.component.html',
  styleUrls: ['./transfer-value.component.scss']
})
export class TransferValueComponent implements OnInit {

  form: FormGroup;
  userTransferData: any;

  showBalance = true

  balanceCodeWallet: number
  formatedBalance: string;

  nameToTransfer: string | null;
  phoneToTransfer: any;
  emailToTransfer: string | null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private clientService: ClientService,
    private accountService: AccountService,
  ) {
    this.form = this.fb.group({
      value: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.userTransferData = this.clientService.getUserDataTransfer();

    this.nameToTransfer = localStorage.getItem('nameToTransfer');
    this.phoneToTransfer = localStorage.getItem('phoneToTransfer');
    this.emailToTransfer = localStorage.getItem('emailToTransfer');

    this.accountService.getAccount().subscribe({
      next: ({ balance }) => {
        this.balanceCodeWallet = balance
        this.formatedBalance = balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    })
  }

  formatBalance(balance: string): string {
    if (this.showBalance) {
      return balance;
    } else {
      const hiddenBalance = '*'.repeat(balance.length);
      return hiddenBalance;
    }
  }

  toggleBalance() {
    this.showBalance = !this.showBalance
  }

  submit() {
    localStorage.setItem('chosenTransferValue', this.form.controls['value'].value)
    this.router.navigate(['/logged/transfer-message'])
  }

}
