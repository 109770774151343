<section class="container-fluid p-4 mt-5" style="background-color: #232323 !important;">
  <div class="d-flex justify-content-end">
    <i class="bi bi-x-lg cursor-pointer color-secundary" (click)="exit()"></i>
  </div>
  <p class="fw-bold color-secundary">Digite o nome e um número de telefone</p>

  <form action="" [formGroup]="form">
    <input class="form-control input-textarea-custom mt-4" placeholder="Digite o nome" formControlName="name">
    <p *ngIf="this.form.controls['name'].errors?.['required'] && isSubmit"
      class="fw-500 font-size-14 color-red cursor-click w-100 mt-1 mb-0 ms-1">
      O nome é obrigatório
    </p>

    <input mask="+00 00 00000-0000" class="form-control input-textarea-custom mt-4" placeholder="Digite o número de telefone" formControlName="phone">
    <p *ngIf="this.form.controls['phone'].errors?.['required'] && isSubmit"
      class="fw-500 font-size-14 color-red cursor-click w-100 mt-1 mb-0 ms-1">
      O número é obrigatório
    </p>
  </form>

  <div class="d-flex justify-content-center flex-column mt-3">
    <!-- <button class="button-green fw-800 font-size-14 mt-3" (click)="onSubmit()">Próximo</button> -->

    <button class="button-green fw-800 font-size-14 mt-3" (click)="save()" [disabled]="form.invalid">
      Próximo
    </button>
  </div>
</section>