<section class="container min-vh-100">
  <div class="row justify-content-center">
      <div class="col-md-5">
          <div class="row pt-5">
              <div (click)="goBack()" class="col-2 align-items-start cursor-pointer">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path
                          d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                          fill="#060A30" />
                  </svg>
              </div>
              <div class="col-8 d-flex justify-content-center align-items-center">
                  <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="148px"></p>
              </div>
          </div>
          <h6 class="fw-700 font-size-21 color-secundary text-center letter-spacing-1 my-5">{{ 'CONFIRM_CODE.CONFIRM_TITLE' | translate }}</h6>
          <div class="fw-400 font-size-16 color-secundary text-center letter-spacing-1">
              <p *ngIf="email">Enviamos um código de verificação para o email <span class="fw-700 color-primary">{{ email }}</span></p>
              <p *ngIf="phone">Enviamos um código de verificação para o telefone <span class="fw-700 color-primary">{{ phone }}</span></p>
              <p class="fw-400 font-size-16 color-secundary text-center letter-spacing-1 mt-3">{{ 'CONFIRM_CODE.ENTER_CODE' | translate }}</p>
          </div>

          <form [formGroup]="form">
              <div class="d-flex d-inline-block justify-content-center w-100">
                  <input type="tel" #txt1
                      class="btn w46h46 border-code border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
                      formControlName="code1" (keyup)="move($event, '',txt1,txt2)" maxlength="1"
                      style="background-color: white !important; color: #22232E !important;">

                  <input type="tel" #txt2 mask="0"
                      class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
                      formControlName="code2" (keyup)="move($event,txt1,txt2,txt3)" maxlength="1"
                      style="background-color: white !important; color: #22232E !important;">

                  <input type="tel" #txt3
                      class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
                      formControlName="code3" (keyup)="move($event, txt2,txt3,txt4)" maxlength="1"
                      style="background-color: white !important; color: #22232E !important;">

                  <input type="tel" #txt4
                      class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
                      formControlName="code4" (keyup)="move($event, txt3,txt4,txt5)" maxlength="1"
                      style="background-color: white !important; color: #22232E !important;">

                  <input type="tel" #txt5
                      class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
                      formControlName="code5" (keyup)="move($event, txt4,txt5,txt6)" maxlength="1"
                      style="background-color: white !important; color: #22232E !important;">

                  <input type="tel" #txt6
                      class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
                      formControlName="code6" (keyup)="move($event, txt5,txt6,'')" maxlength="1"
                      style="background-color: white !important; color: #22232E !important;">
              </div>
              <div class="d-flex flex-column justify-content-center mt-5">
                    <ng-container *ngIf="msgkeepcalm else resendLink">
                    <div class="color-secundary text-center">
                        <p class="font-size-16 color-secundary fw-700 text-center mb-3">Não recebeu o código?</p>
                        <p class="color-secundary text-center mb-2 fw-400">
                            Aguarde um minuto para enviar o código novamente:
                            <span class="color-primary text-center fw-700">
                                {{ counterdown ? '' : '1:00' }}
                                {{ counterdown === 0 ? '' : counterdown }}
                            </span>
                        </p>
                    </div>
                    </ng-container>
        
                    <ng-template #resendLink>
                        <a (click)="resend()" class="fw-400 font-size-16 d-flex justify-content-center text-center color-blue cursor-pointer mt-3 fade-in-left">Reenviar código</a>
                    </ng-template>

                    <button [disabled]="this.form.invalid" class="button-green fw-800 line-24 font-size-14 mt-5" (click)="verifyCode()">{{
                      'CONFIRM_CODE.VERIFY_CODE' | translate }}</button>
              </div>
          </form>
      </div>
  </div>
</section>
