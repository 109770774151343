<section class="container-fluid p-4 mt-5" style="background-color: #232323 !important; border-radius: 10px;">
  <div class="modal-header">
    <h4 class="modal-title fw-700 font-size-14 color-secundary">Insira sua Senha Eletrônica</h4>
    <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="exit()"></i>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="my-3 w-100">

        <div *ngIf="!showPin"
          (click)="showPin = !showPin"
          class="d-flex fw-400 text-center justify-content-center align-items-center font-size-14 color-secundary pt-2">
          Mostrar números
          <span class="bi" [ngClass]="showPin ? 'bi-eye' : 'bi-eye-slash'" class="ms-2 font-size-16"></span>
        </div>

        <div *ngIf="showPin"
          (click)="showPin = !showPin"
          class="d-flex fw-400 text-center justify-content-center align-items-center font-size-14 color-secundary pt-2">
          Ocultar números
          <span class="bi" [ngClass]="showPin ? 'bi-eye' : 'bi-eye-slash'" class="ms-2 font-size-16"></span>
        </div>

        <div class="d-flex d-inline-block justify-content-center w-100 mt-4">
          <input type="{{ showPin ? 'tel' : 'password' }}" #txt1 mask="0"
              class="btn w46h46 border-code border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code1" (keyup)="move($event, '',txt1,txt2)" maxlength="1"
              style="background-color: white !important; color: #22232E!important; font-size: 21px !important;">

          <input type="{{ showPin ? 'tel' : 'password' }}" #txt2 mask="0"
              class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code2" (keyup)="move($event,txt1,txt2,txt3)" maxlength="1"
              style="background-color: white !important; color: #22232E!important; font-size: 21px !important;">

          <input type="{{ showPin ? 'tel' : 'password' }}" #txt3 mask="0"
              class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code3" (keyup)="move($event, txt2,txt3,txt4)" maxlength="1"
              style="background-color: white !important; color: #22232E!important; font-size: 21px !important;">

          <input type="{{ showPin ? 'tel' : 'password' }}" #txt4 mask="0"
              class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code4" (keyup)="move($event, txt3,txt4,'')" maxlength="1"
              style="background-color: white !important; color: #22232E!important; font-size: 21px !important;">
        </div>

        <!-- ERROR MESSAGE -->
        <div *ngIf="invalidCode"
          class="d-flex fw-400 text-center justify-content-center align-items-center font-size-11 color-red pt-2">Senha Invalida
        </div>
      </div>
    </form>
  </div>
  
  <!-- BUTTONS -->
  <div class="px-2 d-flex flex-column pt-3 pb-4 fade-in">
    <button [disabled]="form.invalid" (click)="confirmPin()" class="button-green fw-800 font-size-14 mt-3">
      {{'CHANGE_PASSWORD.CONFIRM' | translate }}
    </button>
    <button (click)="exit()" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
  </div>
  <!-- <div class="row d-flex justify-content-around align-items-center px-5">
    <button style="height: 48px;" type="button" class="btn btn-light col fw-800 font-size-14 mx-2" (click)="exit()">Close</button>
    <button [disabled]="form.invalid" class=" mx-2 button-green fw-800 font-size-14 col" (click)="confirmPin()">{{
      'CHANGE_PASSWORD.CONFIRM' | translate }}</button>
  </div> -->
</section>