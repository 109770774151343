import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-number-modal',
  templateUrl: './number-modal.component.html',
  styleUrls: ['./number-modal.component.scss'],
})
export class NumberModalComponent {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  exit() {
    this.modalService.dismissAll();
  }

  save() {
    this.isSubmit = true;
    if (this.form.invalid) {
      this.toastr.error('Preencha os campos corretamente', 'Erro', {
        progressBar: true,
      });
      return;
    }

    const country = '+' + this.form.value.phone.slice(0, 2);
    const state = ' ' + this.form.value.phone.slice(2, 4);
    const start = ' ' + this.form.value.phone.slice(4, 9);
    const end = '-' + this.form.value.phone.slice(9, 13);

    const user = {
      name: this.form.value.name,
      phone: country.concat(state, start, end),
    };

    this.passEntry.emit(user);
    this.modalService.dismissAll();
  }
}
