import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BuySuccessComponent } from '../buy/buy-success/buy-success.component';

@Component({
  selector: 'app-exchange-page',
  templateUrl: './exchange-page.component.html',
  styleUrls: ['./exchange-page.component.scss']
})
export class ExchangePageComponent implements OnInit {

  form: FormGroup;
  location: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.form = this.formBuilder.group({
      coin: ['', [Validators.required]],
      valuePay: ['', [Validators.required]],
      coinReceive: ['', [Validators.required]],
      valueRecevice: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
  }

  goToComents() {
    this.modalService.open(BuySuccessComponent)
  }

  submit() {
  }

  back() {
    this.location.back();
  }
}
