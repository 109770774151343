<section class="container min-vh-100 pt-3">
  <div class="row justify-content-center">
    <form [formGroup]="form">
      <div class="mx-auto col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
        <!-- HEADER -->
        <div class="d-flex justify-content-between align-items-center fade-in">
          <svg (click)="back()" class="cursor-pointer" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
              fill="#060A30" />
          </svg>
          <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center pt-4">
            Digite sua senha eletrônica de <br>
            <span class="color-primary">4 dígitos</span>
          </h1>
          <div class="col-1"></div>
        </div>

        <!-- CODE FORM -->
        <div class="flex-grow-1 d-flex flex-column fade-in-left">

          <div class="d-flex d-inline-block justify-content-center w-100 mt-5">
            <input type="password" #txt1 mask="0"
              class="btn w46h46 border-code border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code1" (keyup)="move($event, '',txt1,txt2)" maxlength="1"
              style="background-color: white !important; color:#22232E !important; font-size: 21px !important;">

            <input type="password" #txt2 mask="0"
              class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code2" (keyup)="move($event,txt1,txt2,txt3)" maxlength="1"
              style="background-color: white !important; color:#22232E !important; font-size: 21px !important;">

            <input type="password" #txt3 mask="0"
              class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code3" (keyup)="move($event, txt2,txt3,txt4)" maxlength="1"
              style="background-color: white !important; color:#22232E !important; font-size: 21px !important;">

            <input type="password" #txt4 mask="0"
              class="btn w46h46 border-code bg-gray border-radius-10 p-3 m-1 fw-400 font-size-21 line-20"
              formControlName="code4" (keyup)="move($event, txt3,txt4,'')" maxlength="1"
              style="background-color: white !important; color:#22232E !important; font-size: 21px !important;">

          </div>
          <div *ngIf="invalidCode"
            class="d-flex fw-400 text-center justify-content-center align-items-center font-size-11 color-red pt-2">
            Senha
            Invalida
          </div>
        </div>

        <div class="d-flex flex-column justify-content-center mt-5 pt-5 pb-3 px-2">
          <button [disabled]="this.form.invalid" class="button-green fw-800 line-24 font-size-14 mt-4"
            (click)="confirmPin()">{{ 'CONFIRM_CODE.VERIFY_CODE' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
</section>