<section class="container-fluid bg-modal p-4 fade-in-right position-absolute" style="top: 0;">
  <div>
    <div class="modal-header">
      <h4 class="modal-title fw-700 font-size-18 color-white letter-spacing-0098 fade-in-right">
        Termo de Privacidade - BitBentz
      </h4>
      <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="exit()"></i>
    </div>
    <div class="modal-body">
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Introdução
      </h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Antes de usufruir dos nossos serviços, acreditamos que você deve saber quais dados coletamos de
        você e como os utilizamos.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        É importante que você leia integralmente esta política para saber quais são os dados coletados e a
        finalidade da coleta.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        É rápido e super importante.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Ao optar por usar o nossos serviços, você concorda com a coleta e uso de informações
        relacionadas a essa política, lembrando que os dados coletados por nós são usados
        primordialmente para fornecer e melhorar nossos serviços.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Salientamos ainda que os dados coletados não serão compartilhados em nenhuma hipótese que
        não esteja prevista nesta Política de Privacidade.
      </p>

      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Dados Tratados e Finalidade do
        Tratamento
      </h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Durante a utilização do aplicativo, podemos exigir que você nos forneça alguns dados
        voluntariamente, como: e-mail, nome completo, foto de perfil, CPF, telefone, data de nascimento,
        sexo, cidade, localização, opiniões, comentários, dados de meios de pagamentos e outras mais.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Estes dados são coletados para que possamos executar e melhorar os serviços oferecidos,
        personalizando o contato para nos manter cada vez mais conectados.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        O nome completo e o e-mail são dados obrigatórios para que você possa finalizar o seu cadastro e
        utilizar totalmente os serviços oferecidos.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Com eles vamos identificar o seu perfil no sistema e liberar o acesso à partes do app que você
        tenha autorização para acessar e individualizar sua atividade no aplicativo.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        O telefone pode ser utilizado tanto como dado identificador para acesso ao app através de código
        enviado através de SMS quanto para envio de mensagens destinadas à divulgação de promoções
        gerais ou individuais ou ainda para participação em eventuais promoções ou programas de
        benefícios, como o do sistema de fidelidade.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        O CPF pode ser utilizado para a individualização do seu perfil e vinculação do mesmo a eventuais
        promoções e programas de benefícios relacionados ao app através do sistema de fidelidade.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Dados de pagamento e endereço da entrega poderão ser exigidos ao final de cada operação
        realizada no app, caso disponibilize algum recurso com operações de compra e venda de bens ou
        serviços. O endereço será utilizado para a entrega do produto ou serviço contratado através do
        aplicativo.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        As informações que solicitamos serão retidas por nós e usadas para fornecer o serviço oferecido
        pelo app, facilitar a operação do aplicativo, promover o app e ofertas dentro do app, melhorar
        constantemente o serviço oferecido, conhecer melhor o perfil do usuário, enviar comunicações
        sobre atualizações e novos serviços e disponibilizar uma experiência cada vez melhor enquanto
        você mantiver seus dados no nosso sistema. Lembrando que você pode excluir seu perfil quando
        quiser, através do próprio aplicativo.
      </p>

      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Dados Tratados por terceiros e
        Finalidade do Tratamento
      </h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo,
        como:
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05 text-center">Finalidade</p>
      <div class="row">
        <a href="https://support.google.com/admob/answer/6128543?hl%3Dpt&sa=D&source=editors&ust=1668114069940728&usg=AOvVaw2L6bamMnfN8azAtGIoIXun"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">AdMob</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Garantir que os anúncios enviados para você
          sejam relevantes e condizentes
          com seus interesses</p>
      </div>
      <div class="row">
        <a href="https://aws.amazon.com/pt/privacy&sa=D&source=editors&ust=1668114069941598&usg=AOvVaw0Yvxv7DODi5isOB58osSOf/"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Amazon AWS</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Onde seus dados serão armazenados</p>
      </div>
      <div class="row">
        <a href="https://www.facebook.com/privacy/explanation&sa=D&source=editors&ust=1668114069942591&usg=AOvVaw2vYPHymRopyUQn895fmtSj"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Facebook</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Finalizar o cadastro no aplicativo através do
          login
          social com Facebook</p>
      </div>
      <div class="row">
        <a href="https://firebase.google.com/support/privacy/&sa=D&source=editors&ust=1668114069943858&usg=AOvVaw2nSFsO2yKdbcPwFh_vj3RT"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Firebase</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Monitorar o desempenho e a estabilidade e
          enviar
          notificações para os usuários</p>
      </div>
      <div class="row">
        <a href="https://policies.google.com/privacy?hl%3Dpt-BR&sa=D&source=editors&ust=1668114069945066&usg=AOvVaw2ELTDCsK_oAG97wIf_ANDG"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Google Cloud Plataform</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Finalizar o cadastro no aplicativo através do
          login
          social com Google
        </p>
      </div>
      <div class="row">
        <a href="https://www.google.com/intl/pt_US/help/terms_maps/&sa=D&source=editors&ust=1668114069945906&usg=AOvVaw2SdQYx2jrpLPKOxrXslQHY"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Google Maps</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Ativar geolocalização no app, para organizar
          o
          conteúdo do app ou definir o
          local de eventual entrega contratada através do serviço do app
        </p>
      </div>
      <div class="row mb-2">
        <a href="https://policies.google.com/privacy?hl%3Dpt-BR&sa=D&source=editors&ust=1668114069946722&usg=AOvVaw22tVZzn8Z0is4JiXg9Eb9x"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Google Play Services</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Criação e funcionamento do app
        </p>
      </div>
      <div class="row">
        <a href="https://www.tawk.to/privacy-policy&sa=D&source=editors&ust=1668114069947692&usg=AOvVaw1CCnDrfUmLvS3q7qG0YB75"
          class="col-5 fw-500 font-size-11 color-blue letter-spacing-05">Tawk.to</a>
        <p class="col-7 fw-500 font-size-11 color-secundary letter-spacing-05">Troca de mensagens
        </p>
      </div>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Importante ressaltar que, sempre que você usar os nossos Serviços, coletamos dados de registro.
        Esses dados de registro podem incluir informações como identificadores online, identificadores de
        cookies, endereços de protocolo de internet, identificadores de dispositivos, identificadores de
        cliente, endereço de ip, nome do dispositivo, versão do sistema operacional, configuração do
        aplicativo ao utilizar os nossos serviços, a hora e a data do seu uso do serviço e outras estatísticas.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Uma vez que os serviços de hospedagem e servidores podem não estar localizados no território
        brasileiro. Ressaltamos que sempre que transferirmos informações pessoais para outras
        jurisdições, garantimos que tais informações sejam transferidas de acordo com esta política de
        privacidade e conforme permitido pela legislação de proteção de dados aplicável.
      </p>

      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Compartilhamento dos Dados</h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">
        Como visto, empregamos empresas terceiras devido às seguintes razões:
      </p>
      <ul class="fw-500 font-size-11 color-secundary letter-spacing-05">
        <li>Facilitar nosso serviço;</li>
        <li>Fornecer o serviço em nosso nome;</li>
        <li>Executar serviços relacionados a serviços do aplicativo;</li>
        <li>Ajudar a analisar como nosso serviço é usado.</li>
      </ul>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Esses terceiros têm acesso a certos dados pessoais
        para
        executar as tarefas atribuídas a eles em
        nosso nome.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">No entanto, eles são obrigados a não divulgar ou usar
        as informações para qualquer outra
        finalidade.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Lembramos que também podemos compartilhar informações
        pessoais de usuários com empresas,
        organizações ou indivíduos externos ao serviço prestado, se acreditarmos, de boa-fé, que o acesso,
        o uso, a preservação ou a divulgação das informações sejam necessários para
      </p>
      <ol class="fw-500 font-size-11 color-secundary letter-spacing-05 mb-3">
        <li>Cumprir qualquer legislação, regulamentação, ordem judicial ou solicitação governamental
          aplicável;</li>
        <li>Cumprir os Termos de Serviço aplicáveis, inclusive a investigação de possíveis violações;</li>
        <li> Detectar, impedir ou lidar de alguma forma com fraudes, problemas técnicos ou de
          segurança;
        </li>
        <li>Proteger contra prejuízo aos direitos, à propriedade ou à segurança dos nossos usuários ou
          do público, conforme solicitado ou permitido por lei.</li>
      </ol>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Links para outros sites</h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Este aplicativo pode conter links, webviews e iframes
        para outros sites.</p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Se você clicar em um link de terceiros e/ou acessar o
        conteúdo desses sites, você será direcionado
        para esse site e estará sujeito às políticas de privacidade dos mesmos</p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Observe que esses sites externos não são operados por
        nós.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Portanto, aconselhamos fortemente que você revise a
        Política de Privacidade desses sites, pois não
        temos controle e não assumimos nenhuma responsabilidade pelo conteúdo, políticas de privacidade
        ou práticas de quaisquer sites ou serviços de terceiros.
      </p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Cookies</h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Cookies são arquivos com uma pequena quantidade de
        dados que são comumente usados como
        identificadores exclusivos anônimos. Eles são enviados para o seu navegador a partir dos sites que
        você visita e são armazenados na memória interna do seu dispositivo.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Este serviço não utiliza "cookies" explicitamente.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">No entanto, o aplicativo pode usar código de
        terceiros
        e bibliotecas que usam "cookies" para coletar
        informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e
        saber quando um cookie está sendo enviado para o seu dispositivo. Se você optar por recusar
        nossos cookies, talvez não consiga usar algumas partes deste serviço
      </p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Segurança</h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Nós valorizamos sua confiança ao nos fornecer seus
        Dados Pessoais, assim, estamos nos
        esforçando para usar meios comercialmente aceitáveis de protegê-los. Todas as informações
        coletadas dos usuários trafegam de forma segura, utilizando processo de criptografia padrão da
        Internet;
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Mas lembre-se de que nenhum método de transmissão
        pela
        Internet, ou método de armazenamento
        eletrônico é 100% seguro e confiável, e nós não podemos garantir sua segurança absoluta.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Ainda assim, estamos sempre alertas a qualquer
        situação
        que possa gerar riscos aos seus dados
        sob nossa responsabilidade, de forma que procuraremos sempre informar a todos sobre eventuais
        quebras de segurança o mais rápido possível.</p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Direito dos Titulares
      </h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">É muito importante que você tenha total controle dos
        dados pessoais que compartilha conosco.</p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">É possível acessar ou corrigir esses dados a qualquer
        momento através do próprio aplicativo ou
        então desativar sua conta excluindo permanentemente seus dados quando quiser.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Lembrando ainda que você pode sempre entrar em
        contato
        conosco para quaisquer
        esclarecimentos.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Observe que, para ajudar a proteger sua privacidade e
        manter a segurança, tomamos medidas para
        verificar sua identidade antes de conceder acesso a suas informações pessoais ou cumprir
        exclusão, portabilidade ou outras solicitações relacionadas.
      </p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Privacidade Infantil
      </h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Estes serviços não tratam ninguém com idade inferior
        a
        13 anos. Não coletamos intencionalmente
        informações pessoalmente identificáveis de crianças menores de 13 anos. Caso uma criança com
        menos de 13 anos forneça informações pessoais, excluiremos imediatamente estes dados dos
        nossos servidores tão logo seja identificado o fato.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Se você é pai/mãe ou responsável legal e sabe que seu
        filho(a) nos forneceu informações pessoais,
        entre em contato conosco para que possamos tomar as providências necessárias.
      </p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Alteração da Política de Privacidade
      </h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Podemos revisar esta Política de Privacidade sempre
        que
        entendermos que uma alteração seja
        necessária para aumentar sua transparência e eficiência, sendo a versão mais atual a aplicável ao
        processamento de seus dados pessoais.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Se efetuarmos alguma alteração nesta Política de
        Privacidade que, a nosso critério exclusivo, seja
        substancial, encaminharemos um aviso para todos os usuários comunicando tal alteração e
        solicitando a aceitação da nova política de privacidade atualizada.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Ao continuar a acessar ou utilizar os serviços do
        aplicativo após a data de entrada em vigor da nova
        política, você aceita e concorda em estar vinculado à versão revisada da mesma.</p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Data do Documento</h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Esta política de privacidade entrou em vigor em 06 de
        outubro de 2022.</p>
      <h6 class="fw-700 font-size-18 color-secundary letter-spacing-05 mb-3">Área de Privacidade/DPO</h6>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Qualquer dúvida, você pode entrar em contato com
        nosso
        encarregado de dados através do e-mail: <a
          class="fw-500 font-size-11 color-blue letter-spacing-05 mb-3">atendimento@codetech.one</a></p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Nosso encarregado de dados terá o maior prazer em te
        atender.</p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Valorizamos uma cultura de proteção de dados pessoais
        e
        por tal motivo estaremos sempre abertos
        para sugestões, críticas e elogios.
      </p>
      <p class="fw-500 font-size-11 color-secundary letter-spacing-05">Obrigado por confiar em nós!</p>
    </div>
  </div>
</section>