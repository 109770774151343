import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KycUserStatus } from 'src/app/dtos/kyc/kyc-user-status.enum';
import { ClientInterface } from 'src/app/interface/client.interface';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';
import { FeeService } from 'src/app/services/fee.service';

@Component({
  selector: 'app-send-pix',
  templateUrl: './send-pix.component.html',
  styleUrls: ['./send-pix.component.scss']
})
export class SendPixComponent implements OnInit {
  showBalance = true
  form: FormGroup
  balanceCodeWallet: number
  FormatedBalance: string
  userInfo: ClientInterface;
  fee: number;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private feeService: FeeService,
    private router: Router
  ) {
    this.form = this.fb.group({
      valuePix: ['', Validators.required],
    })
  }

  ngOnInit() {
    // Balance
    this.accountService.getAccount().subscribe({
      next: ({ balance }) => {
        this.balanceCodeWallet = balance
        this.FormatedBalance = balance.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      }
    })

    this.feeService.getPixFee().subscribe({
      next: (data) => {
        this.fee = data.value
      },
      error: err => {
        console.error(err);
      }
    })

    // MOCK
    // const mockBalance = 10
    // this.FormatedBalance = mockBalance .toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})

    this.clientService.getUserInfo().subscribe({
      next: (data) => {
        this.userInfo = data;
    
        // Verify Has Info
        if (!this.hasEssentialInfo(this.userInfo)) {
          this.router.navigate(['/personal-info-needed']);
          localStorage.setItem('registerInfos', 'true');
        } else {
          if (!(this.userInfo.kyc?.userStatus === KycUserStatus.CHECKED)) {
            this.router.navigate(['/logged/details']);
            
            this.toastrService.warning('Valide sua identidade para prosseguir', '', { progressBar: true })
          }
        }
      }
    });
  }

  formatBalance(balance: string): string {
    if (this.showBalance) {
      return balance;
    } else {
      const hiddenBalance = '*'.repeat(balance.length);
      return hiddenBalance;
    }
  }
  
  hasEssentialInfo(data: any): boolean {
    return (
      data &&
      data.dateOfBirth &&
      data.document &&
      data.name &&
      data.address &&
      data.address.address &&
      data.address.country &&
      data.address.city &&
      data.address.zipCode &&
      data.address.state
    );
  }

  toggleBalance() {
    this.showBalance = !this.showBalance
  }
  
  submitvalue() {
    if(this.form.controls['valuePix'].value === 0) {
      this.toastrService.error('Insira um valor', '', { progressBar: true })
      return
    }
    if(this.form.controls['valuePix'].value && this.form.controls['valuePix'].value > this.balanceCodeWallet) {
      this.toastrService.error('Saldo insuficiente', '', { progressBar: true })
      return
    }

    let chosenPixValue = {
      value: this.form.controls['valuePix'].value,
      fee: this.fee
    };
 
    localStorage.setItem('chosenPixValue', JSON.stringify(chosenPixValue))
    this.router.navigate(['/logged/send-pix-key'])
  }
}
