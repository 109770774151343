<section class="container min-vh-100">
  <div class="row justify-content-center">
    <div *ngIf="processed" class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
      <div class="d-flex justify-content-between align-items-center">
        <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
            fill="#060A30" />
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          Em processamento
        </h1>
        <div class="col-1"></div>
      </div>
      <div class="flex-grow-1 d-flex flex-column pt-5 px-3">
        <h4 class="text-start text-wrap fw-700 font-size-16 letter-spacing-05 line-24 color-secundary my-3">Por favor,
          aguarde um momento e não feche esta página.</h4>
        <div class="flex-grow-1 d-flex justify-content-center align-items-center">
          <!-- 360 bar -->
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="flex-grow-1"></div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div *ngIf="!processed" class="col-md-5 d-flex flex-column min-vh-100 h-100 pt-3 fade-in-bottom">
      <div class="d-flex justify-content-between align-items-center">
        <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
            fill="#060A30" />
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          Em processamento
        </h1>
        <div class="col-1"></div>
      </div>
      <div class="flex-grow-1 d-flex flex-column pt-5 px-3">
        <h4 class="text-center fw-700 font-size-21 letter-spacing-05 line-24 color-secundary my-3">Dinherio enviado
          com sucesso!</h4>
      </div>
      <div class="d-flex justify-content-center">
        <img src="../../../assets/icon/icon-foguete.svg">
      </div>
    </div>
  </div>
</section>