import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WebcamModule } from 'ngx-webcam';
import { ChangeLanguageComponent } from '../components/change-language/change-language.component';
import { AddMoneyComponent } from './add-money/add-money.component';
import { CheckoutBoletoComponent } from './add-money/components/checkout-boleto/checkout-boleto.component';
import { CheckoutPixComponent } from './add-money/components/checkout-pix/checkout-pix.component';
import { CheckoutComponent } from './add-money/components/checkout/checkout.component';
import { BuySuccessComponent } from './buy/buy-success/buy-success.component';
import { BuyComponent } from './buy/buy.component';
import { CreatePasswordLoggedComponent } from './create-password-logged/create-password-logged.component';
import { SenhaProgressLoggedComponent } from './create-password-logged/senha-progress-logged/senha-progress-logged.component';
import { CriptoComponent } from './cripto/cripto.component';
import { DepositCoinComponent } from './deposit/deposit-coin/deposit-coin.component';
import { DepositComponent } from './deposit/deposit.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { ExchangePageComponent } from './exchange-page/exchange-page.component';
import { HomeComponent } from './home/home.component';
import { MyAccountSettingsComponent } from './home/my-account-settings/my-account-settings.component';
import { NotificationsComponent } from './home/notifications/notifications.component';
import { RescueComponent } from './home/rescue/rescue.component';
import { SplashRescueComponent } from './home/splash-rescue/splash-rescue.component';
import { LoggedComponent } from './logged.component';
import { LoggedRouteModule } from './logged.route';
import { AccountDetailsComponent } from './my-account/account-details/account-details.component';
import { PinComponent } from './my-account/account-details/pin/pin.component';
import { ValidationIdComponent } from './my-account/account-details/validation-id/validation-id.component';
import { ChangePasswordComponent } from './my-account/change-password/change-password.component';
import { ChangePhotoComponent } from './my-account/change-photo/change-photo.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PersonalInfoComponent } from './my-account/personal-info/personal-info.component';
import { SettingsComponent } from './my-account/settings/settings.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { PersonalInfoNeededComponent } from './personal-info-needed/personal-info-needed.component';
import { PhoneVerifyComponent } from './phone-verify/phone-verify.component';
import { ProcessingScreenComponent } from './processing-screen/processing-screen.component';
import { ReceiveMoneyComponent } from './receive-money/receive-money.component';
import { ReceivePixComponent } from './receive-money/receive-pix/receive-pix.component';
import { ReceiveTicketComponent } from './receive-money/receive-ticket/receive-ticket.component';
import { RequestCardSuccessComponent } from './request-card/request-card-success/request-card-success.component';
import { RequestCardComponent } from './request-card/request-card.component';
import { SaleComponent } from './sale/sale.component';
import { SendMoneySet2Component } from './send-money/send-money-set2/send-money-set2.component';
import { AdicionarManualmenteComponent } from './send-money/send-money-step1/adicionar-manualmente/adicionar-manualmente.component';
import { SendMoneyStep1Component } from './send-money/send-money-step1/send-money-step1.component';
import { NumberModalComponent } from './send-money/send-money-step3/number-modal/number-modal.component';
import { SendMoneyStep3Component } from './send-money/send-money-step3/send-money-step3.component';
import { SendMoneyStep4Component } from './send-money/send-money-step4/send-money-step4.component';
import { SendMoneyComponent } from './send-money/send-money.component';
import { ChooseMethodComponent } from './send-pix/choose-method/choose-method.component';
import { KeyPixMessageComponent } from './send-pix/key-pix-message/key-pix-message.component';
import { KeyPixStepComponent } from './send-pix/key-pix-step/key-pix-step.component';
import { PixSuccessComponent } from './send-pix/pix-success/pix-success.component';
import { PixVerifyCodeComponent } from './send-pix/pix-verify-code/pix-verify-code.component';
import { SendPixComponent } from './send-pix/send-pix.component';
import { ShareAccountComponent } from './share-account/share-account.component';
import { SplashCriptoComponent } from './splash-cripto/splash-cripto.component';
import { TabsComponent } from './tabs/tabs.component';
import { TransferMessageComponent } from './transfer/transfer-message/transfer-message.component';
import { TransferSuccessComponent } from './transfer/transfer-success/transfer-success.component';
import { TransferValueComponent } from './transfer/transfer-value/transfer-value.component';
import { TransferVerifyCodeComponent } from './transfer/transfer-verify-code/transfer-verify-code.component';
import { TransferComponent } from './transfer/transfer.component';
import { VerifyCodeLoggedComponent } from './verify-code-logged/verify-code-logged.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { YourActivitiesComponent } from './your-activities/your-activities.component';
@NgModule({
  declarations: [
    LoggedComponent,
    TabsComponent,
    HomeComponent,
    MyAccountComponent,
    YourActivitiesComponent,
    BuyComponent,
    BuySuccessComponent,
    MyAccountSettingsComponent,
    DepositComponent,
    DepositCoinComponent,
    RequestCardComponent,
    SplashRescueComponent,
    NotificationsComponent,
    RescueComponent,
    SettingsComponent,
    SplashCriptoComponent,
    CriptoComponent,
    AccountDetailsComponent,
    PersonalInfoComponent,
    MyOrdersComponent,
    AddMoneyComponent,
    SendMoneyComponent,
    SendMoneyStep1Component,
    SendMoneySet2Component,
    SendMoneyStep3Component,
    NumberModalComponent,
    ChangePasswordComponent,
    AddMoneyComponent,
    ShareAccountComponent,
    CheckoutComponent,
    CheckoutPixComponent,
    ValidationIdComponent,
    ChangePhotoComponent,
    SaleComponent,
    SendMoneyStep4Component,
    ProcessingScreenComponent,
    ExchangePageComponent,
    AdicionarManualmenteComponent,
    ReceiveMoneyComponent,
    ReceiveTicketComponent,
    ReceivePixComponent,
    CheckoutBoletoComponent,
    PersonalInfoNeededComponent,
    ChangeLanguageComponent,
    CreatePasswordLoggedComponent,
    SenhaProgressLoggedComponent,
    SendPixComponent,
    KeyPixStepComponent,
    KeyPixMessageComponent,
    PixVerifyCodeComponent,
    PixSuccessComponent,
    TransferComponent,
    TransferValueComponent,
    TransferMessageComponent,
    WithdrawComponent,
    TransferVerifyCodeComponent,
    TransferSuccessComponent,
    RequestCardSuccessComponent,
    ChooseMethodComponent,
    EmailVerifyComponent,
    VerifyCodeLoggedComponent,
    PhoneVerifyComponent,
    PinComponent,
  ],
  imports: [
    CommonModule,
    LoggedRouteModule,
    FormsModule,
    NgxMaskDirective,
    NgxIntlTelInputModule,
    NgxMaskPipe,
    ReactiveFormsModule,
    NgbModule,
    QRCodeModule,
    CurrencyMaskModule,
    WebcamModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    BsDatepickerModule.forRoot(),
    TranslateModule
  ],
})
export class LoggedModule { }
