import { Component } from '@angular/core';

@Component({
  selector: 'app-my-account-settings',
  templateUrl: './my-account-settings.component.html',
  styleUrls: ['./my-account-settings.component.scss']
})
export class MyAccountSettingsComponent {
  remindMeSwitch: boolean = false;
}
