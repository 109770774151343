<section class="container min-vh-100 pt-3">
  <div class="row justify-content-center">
    <form [formGroup]="form" class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center fade-in">
        <svg class="cursor-pointer" (click)="back()" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          PIX
        </h1>
        <div class="col-1"></div>
      </div>
      
      
      <!-- RECEIVER DATA -->
      <ng-container>
        <!-- TITLE -->
        <h4 class="fw-700 font-size-21 color-secundary mb-2 px-2 mt-5 mb-3 letter-spacing-080">Transferindo</h4>
        <h3 class="fw-700 font-size-32 color-primary mb-2 px-2 letter-spacing-080">
          {{this.chosenPixValue.value | currency: 'R$ '}}
  
          <svg class="ms-2 cursor-pointer" (click)="openChangeValueModal()" width="16" height="16" viewBox="0 0 18 18" fill="none">
            <path d="M13.3438 2.25L11.6562 3.96875L14.0312 6.34375L15.75 4.65625C16.0312 4.34375 16.0312 3.875 15.75 3.59375L14.4062 2.25C14.125 1.96875 13.6562 1.96875 13.3438 2.25ZM10.9375 4.65625L3.59375 12.0312C3.34375 12.25 3.15625 12.5625 3.0625 12.875L2.21875 15.7812L5.125 14.9062C5.4375 14.8125 5.75 14.6562 5.96875 14.4062L13.3438 7.0625L10.9375 4.65625ZM15.125 1.53125L16.4688 2.875C17.125 3.5625 17.125 4.65625 16.4688 5.34375L6.6875 15.125C6.3125 15.4688 5.875 15.75 5.40625 15.875L1.625 17C1.4375 17.0312 1.25 17 1.125 16.875C1 16.75 0.96875 16.5625 1 16.375L2.125 12.5938C2.25 12.125 2.53125 11.6875 2.875 11.3125L12.6562 1.53125C13.3438 0.875 14.4375 0.875 15.125 1.53125Z" fill="#4A35E8"/>
          </svg>
        </h3>

        <div class="px-2 pt-3 pb-3 mb-2">
          <p class="d-block text-wrap fw-400 font-size-14 line-19 color-gray mb-2">
            para o {{this.keyType}} {{this.keyPIX }}
          </p>
          
          <!-- CPF -->
          <!-- <div class="d-flex align-items-center justify-content-between mt-3">
            <p class="m-0 fw-400 font-size-14 line-19 color-gray">{{ receiverData.cpfCnpj.length === 14 ? 'CPF' : 'CNPJ' }}</p>
            <p class="m-0 fw-500 font-size-14 line-19 color-secundary text-right">{{ receiverData.cpfCnpj }}</p>
          </div> -->
          
          <!-- INSTITUICAO -->
          <!-- <div class="d-flex align-items-center justify-content-between mt-3">
            <p class="m-0 fw-400 font-size-14 line-19 color-gray">Instituição</p>
            <p class="m-0 fw-500 font-size-12 line-19 color-secundary text-end">{{ receiverData.bank.name.toUpperCase() }}</p>
          </div> -->
          
          <!-- AGENCIA -->
          <!-- <div class="d-flex align-items-center justify-content-between mt-3">
            <p class="m-0 fw-400 font-size-14 line-19 color-gray">Agência</p>
            <p class="m-0 fw-500 font-size-14 line-19 color-secundary text-right">{{ receiverData.agency }}{{ receiverData.agencyDigit ? '-' + receiverData.agencyDigit : '' }}</p>
          </div> -->
          
          <!-- CONTA CORRENTE -->
          <!-- <div class="d-flex align-items-center justify-content-between mt-3">
            <p class="m-0 fw-400 font-size-14 line-19 color-gray"> Conta corrente</p>
            <p class="m-0 fw-500 font-size-14 line-19 color-secundary text-right">{{ receiverData.account }}-{{ receiverData.accountDigit }}</p>
          </div> -->
        </div>

        <!-- DIVISOR -->
        <!-- <div class="w-100" style=""></div> -->

        <div class="px-2 pt-3 pb-3 mb-2"> 
          <!-- TAX -->
          <div class="d-flex align-items-center justify-content-between mt-3">
            <p class="m-0 fw-400 font-size-14 line-19 color-gray">Taxa de transação</p>
            <p class="m-0 fw-500 font-size-14 line-19 color-secundary text-right">{{ tax | currency: 'R$ '}}</p>
          </div>
          
          <!-- TOTAL VALUE -->
          <div class="d-flex align-items-center justify-content-between mt-3">
            <p class="m-0 fw-400 font-size-14 line-19 color-gray">Você está enviando</p>
            <p class="m-0 fw-500 font-size-14 line-19 color-primary text-end">{{ taxedFormatedChosenValue }}</p>
          </div>
        </div>
  
        <!-- MESSAGE -->
        <div class="flex-grow-1 d-flex flex-column px-2 fade-in-left pb-4">
          <label for="message" class="text-wrap fw-700 font-size-14 line-19 color-secundary mb-2 px-2 mt-5">Escreva uma mensagem</label>
  
          <textarea type="text"
            id="message"
            name="message"
            class="border border-dark-green rounded-3 fw-400 font-size-14 form-control border-0 color-secundary py-3"
            formControlName="pixMessage"
            maxlength="100"
            placeholder="(opcional)"></textarea>
          <p class="fw-500 font-size-11 line-16 letter-spacing-2019 color-secundary mt-2 pt-1 ms-1">O envio da mensagem é opcional</p>
        </div>
  
        <!-- BUTTONS -->
        <div class="px-2 d-flex flex-column py-5 fade-in">
          <button (click)="goToCheckPin()" class="button-green fw-800 font-size-14 mt-3">Transferir</button>
          <button [routerLink]="['/logged/home']" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
        </div>
      </ng-container>
      
      <!-- <ng-template #notFound>
        <div *ngIf="loaded">
          <div class="text-center mt-5 fade-in-left">
            <p class="font-size-16 color-secundary">A chave PIX informada não existe</p>
            <img class="pt-3" src="../../../../assets/icon/none-notifications-img.svg">
          </div>
  

          <div class="px-2 d-flex flex-column py-5 fade-in">
            <button (click)="back()" class="button-green fw-800 font-size-14 mt-3">Tentar outra chave</button>
          </div>
        </div>
      </ng-template> -->
    </form>
  </div>
</section>

<ng-template #changeValueModal>
  <form [formGroup]="formChangeValue">
    <div class="container p-4 pb-3 bg-modal">
      <div class="d-flex justify-content-end">
        <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="close()"></i>
      </div>

      <!-- VALUE -->
      <div class="flex-grow-1 d-flex flex-column pt-5 px-2 fade-in-left pb-4">
        <label for="value" class="fw-700 font-size-14 line-19 letter-spacing-05 color-secundary mb-2">Insira a quantia</label>
        <div class="position-relative fade-in-left border border-dark-green rounded-3 d-flex align-items-center cursor-pointer py-2 mb-2">
          <div class="position-absolute d-flex align-items-center justify-content-start" style="left: .5rem; top: 50%; transform: translateY(-50%);">
            <img src="../../../assets/icon/brl-icon.png" alt="" height="20px" class="mx-2">
            <span class="fw-400 mt-1" style="color: #9E9D9D; font-size: 0.875rem; letter-spacing: 0.01469rem;">BRL</span>
          </div>

          <input type="text"
            class="fw-700 font-size-16 form-control border-0 bg-transparent color-secundary py-2"
            formControlName="valuePix" currencyMask
            [defaultValue]="0"
            [options]="{ prefix: 'R$ ' ,thousands: '.', decimal: ',' }">
        </div>
        <p class="fw-400 font-size-11 line-16 letter-spacing-2019 color-secundary mb-1 mt-2">Para a sua segurança atente-se aos limites disponíveis da plataforma por transação: </p>
        <p class="fw-500 font-size-11 line-16 letter-spacing-2019 color-secundary mb-1">Limites Diurnos: até R$10.000</p>
        <p class="fw-500 font-size-11 line-16 letter-spacing-2019 color-secundary mb-1">Limites Noturnos: até R$1.000</p>
        <p class="fw-500 font-size-11 line-16 letter-spacing-2019 color-secundary mb-1">A taxa de transferência é de {{ tax | currency: 'R$ '}}</p>
      </div>

      <!-- BUTTONS -->
      <div class="px-2 d-flex flex-column py-5 fade-in">
        <button [disabled]="this.formChangeValue.controls['valuePix'].invalid || this.formChangeValue.controls['valuePix'].value < 3" (click)="submitChangePIXValue()" class="button-green fw-800 font-size-14 mt-3">Continuar</button>
        <button (click)="close()" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
      </div>

    </div>
  </form>
</ng-template>