import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  homeicon = true;
  homeicongreen = false;

  activitiesicon = true;
  activitiesicongreen = false;

  criptoicon = true;
  criptoicongreen = false;

  walleticon = true;
  walleticongreen = false;

  constructor(
    private router: Router
  ) {

  }

  selectItem(pathname: any) {

    if (pathname === '/home') {
      this.homeicon = false;
      this.homeicongreen = true;

      this.activitiesicon = true;
      this.activitiesicongreen = false;

      this.criptoicon = true;
      this.criptoicongreen = false;

      this.walleticon = true;
      this.walleticongreen = false;

      this.router.navigate(['/logged/home'])
    }

    if (pathname === '/your-activities') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.activitiesicon = false;
      this.activitiesicongreen = true;

      this.criptoicon = true;
      this.criptoicongreen = false;

      this.walleticon = true;
      this.walleticongreen = false;
      this.router.navigate(['/logged/your-activities'])
    }

    if (pathname === '/cripto') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.activitiesicon = true;
      this.activitiesicongreen = false;

      this.criptoicon = false;
      this.criptoicongreen = true;

      this.walleticon = true;
      this.walleticongreen = false;
      this.router.navigate(['/logged/splash-cripto'])
    }

    if (pathname === '/wallet') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.activitiesicon = true;
      this.activitiesicongreen = false;

      this.criptoicon = true;
      this.criptoicongreen = false;

      this.walleticon = false;
      this.walleticongreen = true;
      this.router.navigate(['/logged/request-card'])
    }
  }
}
