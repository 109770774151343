import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginNumberComponent } from './auth/login-number/login-number.component';
import { SplashComponent } from './splash/splash.component';

import { ConfirmCodeComponent } from './auth/confirm-code/confirm-code.component';
import { CreatePasswordComponent } from './auth/create-password/create-password.component';
import { FingerPrintComponent } from './auth/finger-print/finger-print.component';
import { ForgotPassword1Component } from './auth/forgot-password1/forgot-password1.component';
import { ForgotPassword2Component } from './auth/forgot-password2/forgot-password2.component';
import { HelpCenterComponent } from './auth/help-center/help-center.component';
import { LoginPasswordComponent } from './auth/login-password/login-password.component';
import { PersonalDataComponent } from './auth/personal-data/personal-data.component';
import { SendCodeComponent } from './auth/send-code/send-code.component';
import { LoginWithoutPasswordComponent } from './auth/login-without-password/login-without-password.component';
import { LoginFaceIdComponent } from './auth/login-face-id/login-face-id.component';
import { LoginEmailComponent } from './auth/login-email/login-email.component';
import { ConfirmEmailCodeComponent } from './auth/confirm-email-code/confirm-email-code.component';

const routes: Routes = [
  { path: '', component: SplashComponent },
  { path: 'login-number', component: LoginNumberComponent },
  { path: 'login-email', component: LoginEmailComponent },
  { path: 'confirm-code', component: ConfirmCodeComponent },
  { path: 'confirm-email-code', component: ConfirmEmailCodeComponent },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'personal-data', component: PersonalDataComponent },
  { path: 'help', component: HelpCenterComponent },
  { path: 'send-code', component: SendCodeComponent },
  { path: 'login-password', component: LoginPasswordComponent },
  { path: 'login-without-password', component: LoginWithoutPasswordComponent },
  { path: 'forgot-password', component: ForgotPassword1Component },
  {
    path: 'forgot-password-register-password',
    component: ForgotPassword2Component,
  },
  { path: 'fingerprint', component: FingerPrintComponent },
  { path: 'login-face-id', component: LoginFaceIdComponent },
  {
    path: 'logged',
    loadChildren: () =>
      import('./logged/logged.module').then((m) => m.LoggedModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
