<section class="container min-vh-100">
  <div class="row justify-content-center pb-5" *ngFor="let item of user">
    <div class="col-md-10">
      <div class="row pt-5 mb-3 fade-in">
        <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
              fill="#060A30" />
          </svg>
        </div>
        <div class="col-8 d-flex justify-content-center align-items-center">
          <p class="fw-700 font-size-16 color-secundary mb-0">{{ 'MY_ACCOUNT.TITLE' | translate }}</p>
        </div>
      </div>
      <!-- CHANGE IMAGE THUMB -->
      <div class="row pt-5 fade-in">
        <div class="col">
          <div
            class="border-profile-picture-s position-relative p-0 mx-auto rounded-circle d-flex justify-content-center align-items-end ">
            <img *ngIf="item.avatar" [src]="item.avatar" class="d-block rounded-circle"
              [ngClass]="item.avatar ? 'w-100 h-100' :'default-profile-svg'">

            <img *ngIf="!item.avatar && !item.name"
              class="d-flex justify-content-center align-items-center rounded-circle w-100 h-100"
              src="../../../assets/icon/icon-nova-perfil.svg">

            <span *ngIf="!item.avatar && item.name"
              class="fw-600 font-size-35 d-flex justify-content-center align-items-center rounded-circle w-100 color-white"
              style="height: 90%;">{{item?.name[0]}}</span>

            <button class="btn btn-edit-photo-custom d-flex justify-content-center align-items-center position-absolute"
              style="padding: 0.38rem;" (click)="changePhoto()">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                <path
                  d="M7.29688 2.70312L10.2969 5.70312L3.78125 12.2188L1.10938 12.5C0.757812 12.5469 0.453125 12.2422 0.5 11.8906L0.78125 9.21875L7.29688 2.70312ZM12.1484 2.25781C12.5938 2.67969 12.5938 3.40625 12.1484 3.85156L10.8359 5.16406L7.83594 2.16406L9.14844 0.851562C9.59375 0.40625 10.3203 0.40625 10.7422 0.851562L12.1484 2.25781Z"
                  fill="white" />
              </svg>
            </button>
          </div>

          <div class="d-flex justify-content-center align-items-center mt-2">
            <span class="fw-700 font-size-21 color-secundary">
              {{item?.name}}
            </span>
          </div>
          <div *ngIf="phone" class="d-flex justify-content-center align-items-center">
            <span class="fw-700 font-size-14 color-primary">
              ({{phone.slice(0, 2)}}) {{phone.slice(2, 7)}} - {{phone.slice(7, 11)}}
            </span>
          </div>
          <div *ngIf="email" class="d-flex justify-content-center align-items-center">
            <p class="fw-700 font-size-14 color-primary">
              {{email}}
            </p>
          </div>
        </div>
      </div>

      <!-- CARDS -->
      <div class="row fade-in-left">
        <div class="col d-flex align-items-center justify-content-center" style="gap: 0.5rem;">
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="row fade-in-left">

        <!-- PROFILE -->
        <div class="col-12 col-md-6 mt-3">
          <span class="fw-700 font-size-16 color-secundary">{{ 'MY_ACCOUNT.PROFILE' | translate }}</span>

          <div class="card-home mt-2 p-3 cursor-pointer" (click)="goToDetails()">
            <div class="row">
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/wallet.svg" width="20px">
              </div>
              <div class="col d-flex col justify-content-start align-items-start">
                <span class="fw-500 font-size-14 color-secundary">{{ 'MY_ACCOUNT.ACCOUNT_DETAILS' | translate }}</span>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/arrow-right.svg" width="20px">
              </div>
            </div>
          </div>

          <div class="card-home mt-2 p-3 cursor-pointer" [routerLink]="['/logged/personal-info']">
            <div class="row">
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/user-green.svg" width="20px">
              </div>
              <div class="col d-flex col justify-content-start align-items-start">
                <span class="fw-500 font-size-14 color-secundary">{{ 'MY_ACCOUNT.PERSONAL_INFO' | translate }}</span>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/arrow-right.svg" width="20px">
              </div>
            </div>
          </div>
        </div>

        <!-- COMMUNITY -->
        <div class="col-12 col-md-6 mt-3">
          <span class="fw-700 font-size-16 color-secundary">{{ 'MY_ACCOUNT.COMMUNITY' | translate }}</span>

          <div class="card-home mt-2 p-3 cursor-pointer">
            <div class="row">
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/group-green.svg" width="25px">
              </div>
              <div class="col d-flex col justify-content-start align-items-start">
                <span class="fw-500 font-size-14 color-secundary">{{ 'MY_ACCOUNT.INVITE_FRIEND' | translate }}</span>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/arrow-right.svg" width="20px">
              </div>
            </div>
          </div>
        </div>

        <!-- SUPPORT -->
        <div class="col-12 col-md-6 mt-3">
          <span class="fw-700 font-size-16 color-secundary">{{ 'MY_ACCOUNT.HELP_AND_SUPPORT' | translate }}</span>

          <div class="card-home mt-2 p-3 cursor-pointer" routerLink="/help">
            <div class="row">
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/exclamation-green.svg" width="20px">
              </div>
              <div class="col d-flex col justify-content-start align-items-start">
                <span class="fw-500 font-size-14 color-secundary">{{ 'MY_ACCOUNT.HELP_CENTER' | translate }}</span>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/arrow-right.svg" width="20px">
              </div>
            </div>
          </div>

          <div class="card-home mt-2 p-3 cursor-pointer" (click)="goToConfig()">
            <div class="row">
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/config-green.svg" width="20px">
              </div>
              <div class="col d-flex col justify-content-start align-items-start">
                <span class="fw-500 font-size-14 color-secundary">{{ 'MY_ACCOUNT.SETTINGS' | translate }}</span>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                <img src="../../../assets/icon/arrow-right.svg" width="20px">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>