import { AfterContentChecked, AfterContentInit, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
  ) {
  }
  
  ngOnInit() {
    const backdrop : any = document.getElementsByTagName('ngb-modal-window')[0]
    setTimeout(() => {
      backdrop.style.display = 'flex !important';
      backdrop.style.alignItems = 'start';
    }, 0)
  }

  exit() {
    this.modalService.dismissAll();
  }
}
