<section class="container min-vh-100 pb-5">
    <div class="fade-in row pt-5">
        <div (click)="backToValidation()" class="col-2 align-items-start cursor-pointer">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                    d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                    fill="#060A30" />
            </svg>
        </div>
        <div class="col-8 d-flex justify-content-center align-items-center">
            <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" height="37px"></p>
        </div>
    </div>
    <p class="fade-in fw-700 font-size-16 text-center letter-spacing-1 color-secundary mt-5">Documento de Identificação</p>
    <div class="fade-in text-center letter-spacing-1">
        <p class="fw-400 font-size-14 color-secundary">Envie o seu documento para podermos validar sua identidade.</p>
    </div>
    <div class="row justify-content-center" *ngIf="step1">
        <div class="col-md-5 pt-5">
            <div class="card-add-money mt-5 mb-2 cursor-pointer" (click)="next(2)">
                <div class="d-flex justify-content-between align-items-center px-3 py-4">
                    <div class="d-flex align-items-center">
                        <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'VALIDATION_ID.ID' | translate }}</p>
                    </div>
                    <img src="../../../assets/icon/arrow-right.svg">
                </div>
            </div>
            <div class="card-add-money mt-3 mb-2 cursor-pointer" (click)="next(2)">
                <div class="d-flex justify-content-between align-items-center px-3 py-4">
                    <div class="d-flex align-items-center">
                        <p class="fw-400 font-size-14 color-secundary mb-0">{{ 'VALIDATION_ID.PASSPORT' | translate }}</p>
                    </div>
                    <img src="../../../assets/icon/arrow-right.svg">
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="step2">
        <div class="col-md-5 pt-5">
            <div class="card-add-money mt-5 cursor-pointer">
                <div class="d-flex justify-content-between align-items-center custom-file-upload">
                    <label class="d-flex align-items-center w-100 p-2">
                        <i class="bi bi-images" style="color: #4A35E8;"></i>
                        <p class="fw-400 font-size-14 color-secundary mb-0 ms-3">Escolha um arquivo</p>
                        <input type="file" accept="image/*" (change)="handleGallerySelection($event)">
                    </label>
                </div>
            </div>

            <img class="img-fluid mt-5" [src]="capturedImage">

            <button [disabled]="!capturedImage" class="button-green fw-800 line-24 font-size-14 mt-5" (click)="uploadImage()">Upload</button>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="step3">
        <div class="pt-5">
            <div class="d-flex justify-content-center align-items-center mb-5 mt-5">

                <div class="mt-4 position-relative">
                    <img src="../../../../../assets/images/id-default2.png" alt style="max-height: 278px !important;"
                        class="img-fluid">
                </div>

                <div class="mt-4 ms-3 position-relative">
                    <img src="../../../../../assets/images/id-default1.png" class="img-fluid" alt style="max-height: 278px !important;">
                </div>
            </div>
            <p class="color-secundary text-center mt-5">{{ 'VALIDATION_ID.AVOID_SHADOWS' | translate }}</p>
            <div *ngIf="step2 || step3|| step4|| step5 " class="step-indicator">
                <div class="step-circle" [class.active]="step2"></div>
                <div class="step-circle" [class.active]="step3"></div>
                <div class="step-circle" [class.active]="step4"></div>
                <div class="step-circle" [class.active]="step5"></div>
            </div>
            <button class="btn button-green fw-800 font-size-14 mt-5" (click)="next(4)">{{ 'VALIDATION_ID.CONTINUE' | translate }}</button>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="step4">
        <div class="pt-5">
            <div class="d-flex justify-content-center align-items-center mb-5 mt-5">

                <div class="mt-4 position-relative">
                    <img src="../../../../../assets/images/id-default3.png" alt
                        class="img-fluid">
                </div>

                <div class="mt-4 ms-3 position-relative">
                    <img src="../../../../../assets/images/id-default1.png" class="img-fluid" alt>
                </div>
            </div>
            <p class="color-secundary text-center mt-5">{{ 'VALIDATION_ID.MAKE_SURE' | translate }}</p>
            <div *ngIf="step2 || step3|| step4|| step5 " class="step-indicator">
                <div class="step-circle" [class.active]="step2"></div>
                <div class="step-circle" [class.active]="step3"></div>
                <div class="step-circle" [class.active]="step4"></div>
                <div class="step-circle" [class.active]="step5"></div>
            </div>
            <button class="btn button-green fw-800 font-size-14 mt-5" (click)="next(5)">{{ 'VALIDATION_ID.CONTINUE' | translate }}</button>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="step5">
        <div class="pt-5">
            <div class="d-flex justify-content-center align-items-center mb-5 mt-5">

                <div class="mt-4 position-relative">
                    <img src="../../../../../assets/images/id-default4.png" alt
                        class="img-fluid">
                </div>

                <div class="mt-4 ms-3 position-relative">
                    <img src="../../../../../assets/images/id-default1.png" class="img-fluid" alt>
                </div>
            </div>
            <p class="color-secundary text-center mt-5">{{ 'VALIDATION_ID.MAKE_SURE_INFO' | translate }}</p>
            <div *ngIf="step2 || step3|| step4|| step5 " class="step-indicator">
                <div class="step-circle" [class.active]="step2"></div>
                <div class="step-circle" [class.active]="step3"></div>
                <div class="step-circle" [class.active]="step4"></div>
                <div class="step-circle" [class.active]="step5"></div>
            </div>
            <button class="btn button-green fw-800 font-size-14 mt-5" (click)="next(6)">{{ 'VALIDATION_ID.READY' | translate }}</button>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="step6">
        <div class="col-md-5 pt-3">
            <p class="color-secundary text-center mt-2 mb-4">{{ 'VALIDATION_ID.SHOW_ID_FRONT' | translate }}</p>
            <div class="d-flex justify-content-center align-items-center">

                <div class="card-validation-id-photo-custom mt-4 position-relative">
                    <button class="btn btn-change-cam" (click)="switchCamera()"><img
                            src="../../../../../assets/icon/trocar-camera.png"
                            style="max-height: 45px !important;"></button>
                    <div class="webcam-wrapper">
                        <webcam [width]="videoWidth" [height]="videoHeight" [trigger]="triggerObservable"
                            [videoOptions]="videoOptions" (imageCapture)="handleImage($event)" #webcamElement
                            [allowCameraSwitch]="true"></webcam>
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-end mt-3">
                <button class="btn btn-outline-success"><i class="bi bi-question-circle-fill"></i> {{ 'VALIDATION_ID.HELP' | translate }}</button>
            </div>
            <div class="d-flex justify-content-center mt-3 mb-5">
                <button class="btn btn-cicle-fake-photo" (click)="captureImage()">
                    <div class="div-fake-center-button-take-photo"></div>
                </button>
            </div>
        </div>
    </div>
</section>
