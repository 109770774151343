<section>
  <form [formGroup]="form">
      <div class="container min-vh-100">
          <div class="row justify-content-center">
              <div class="col-md-5">
                    <div class="fade-in row pt-5">
                        <div (click)="back()" class="col-2 align-items-start cursor-pointer">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path
                                    d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                                    fill="#060A30" />
                            </svg>
                        </div>
                        <div class="col-8 d-flex justify-content-center align-items-center">
                            <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="148px"></p>
                        </div>
                    </div>

                  <div class="fw-400 font-size-16 color-secundary text-center px-2 pt-4 pb-2 mb-5 fade-in-right">
                    <p class="letter-spacing-0016">Verifique seu Telefone</p>
                  </div>

                  <h6 class="fw-700 font-size-21 color-secundary text-center mb-3 pb-1 letter-spacing-0098 fade-in-left">
                      {{ 'LOGIN_NUMBER.PHONE_NUMBER_LABEL' | translate }}
                  </h6>

                  <div class="d-flex flex-column justify-content-center fade-in-left">
                      <ngx-intl-tel-input (countryChange)="onCountryChange($event)" (keydown)="maskApply($event)"
                          [ngClass]="this.form.invalid && this.isSubmit ? 'input-transparent-login-number-red' : 'input-transparent-login-number'"
                          [cssClass]="'custom fw-400'" [preferredCountries]="preferredCountries"
                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                          [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                          ]" [selectFirstCountry]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                          formControlName="phone" [customPlaceholder]="mask" #phoneInput>
                      </ngx-intl-tel-input>

                      <div *ngIf="this.isSubmit && form.controls['phone'].hasError('phoneExists')"
                          class="d-flex color-red margin-right-118 letter-spacing-1 mt-1 fade-in-left">
                          Telefone já cadastrado
                      </div>
                  </div>
                  <div class="d-flex justify-content-center mt-5 pt-5 fw-800">
                      <button [disabled]="this.form.invalid" (click)="confirm()" class="button-green fw-800 font-size-14">{{ 'LOGIN_NUMBER.CONTINUE_BUTTON' | translate }}</button>
                  </div>
                  <a class="d-flex justify-content-center fw-400 color-blue cursor-pointer font-size-16 mt-3 mb-5 letter-spacing-0016 fade-in"
                      [routerLink]="['/help']">
                      {{ 'LOGIN_NUMBER.NEED_HELP_LINK' | translate }}
                  </a>
              </div>
          </div>
      </div>
  </form>
</section>
