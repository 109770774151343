import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, catchError, map } from "rxjs";
import { ClientInterface } from "../interface/client.interface";

@Injectable()
export class TokenService extends BaseService {
  walletApiUrl: string = `${environment.api.path}/token`
  userInfo: ClientInterface | undefined

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    super();
  }

  exchangeCoin(dto: any): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/exchange-coin`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  transferToBtc(dto: any): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/transfer-to-btc`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  transferToEth(dto: any): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/transfer-to-eth`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}