<section class="container min-vh-100 pt-3">
  <div class="row justify-content-center">
    <form [formGroup]="form" class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center fade-in">
        <svg [routerLink]="['/logged/home']" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
            fill="#060A30" />
        </svg>
        <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
          Transferir
        </h1>
        <div class="col-1"></div>
      </div>

      <!-- FOR WHO -->
      <div class="flex-grow-1 d-flex flex-column pt-5 px-2 fade-in-left pb-4">
        <h4 class="fw-700 font-size-16 line-24 color-secundary mt-2 mb-3">Para quem você quer transferir?</h4>
        <div class="d-flex justify-content-start mb-3">
          <div class="form-check">
            <input formControlName="type" class="form-check-input cursor-pointer" value="document" type="radio" name="type"
              id="flexRadioCpf">
            <label class="cursor-pointer form-check-label color-secundary fw-300 letter-spacing-1 font-size-16 margin-top-1 ms-2" for="flexRadioCpf">
              CPF
            </label>
          </div>
          <div class="form-check mx-3">
            <input formControlName="type" class="form-check-input cursor-pointer" value="phone" type="radio" name="type"
              id="flexRadioPhone">
            <label class="cursor-pointer form-check-label color-secundary fw-300 letter-spacing-1 font-size-16 margin-top-1 ms-2" for="flexRadioPhone">
              Celular
            </label>
          </div>
          <div class="form-check">
            <input formControlName="type" class="form-check-input cursor-pointer" value="email" type="radio" name="type"
              id="flexRadioEmail">
            <label class="cursor-pointer form-check-label color-secundary fw-300 letter-spacing-1 font-size-16 margin-top-1 ms-2" for="flexRadioEmail">
              Email
            </label>
          </div>
        </div>
        <div *ngIf="this.form.controls['type'].value !== ''">
          <div *ngIf="this.form.controls['type'].value === 'phone'"
            class="fade-in-left border border-green rounded-3 d-flex align-items-center cursor-pointer py-2 mt-2">
            <input type="text" mask="(00)00000-0000"
              class="px-3 fw-600 font-size-16 form-control border-0 bg-transparent color-secundary py-2"
              formControlName="value" placeholder="Digite o número">
          </div>
          <div *ngIf="this.form.controls['type'].value === 'email'"
            class="fade-in-left border border-green rounded-3 d-flex align-items-center cursor-pointer py-2 mt-2">
            <input type="email"
              class="px-3 fw-600 font-size-16 form-control border-0 bg-transparent color-secundary py-2"
              formControlName="value" placeholder="Digite o email">
          </div>
          <div *ngIf="this.form.controls['type'].value === 'document'"
            class="fade-in-left border border-green rounded-3 d-flex align-items-center cursor-pointer py-2 mt-2">
            <input type="text" mask="000.000.000-00"
              class="px-3 fw-600 font-size-16 form-control border-0 bg-transparent color-secundary py-2"
              formControlName="value" placeholder="Digite o CPF">
          </div>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="px-2 d-flex flex-column py-5 fade-in">
        <button [disabled]="this.form.invalid" (click)="submit()"
          class="button-green fw-800 font-size-14 mt-3">Continuar</button>
        <button [routerLink]="['/logged/home']"
          class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
      </div>
    </form>
  </div>
</section>