import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShareDataService } from 'src/app/services/share-data.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Component({
  selector: 'app-send-money-step4',
  templateUrl: './send-money-step4.component.html',
  styleUrls: ['./send-money-step4.component.scss'],
})
export class SendMoneyStep4Component {
  form: FormGroup;
  destinyUser: any;
  usdPrice: any
  coinSelectSend = {
    name: 'BRL',
    img: '../../../assets/icon/brl-icon.png',
  };

  coinSelectReceive = {
    name: 'BRL',
    img: '../../../assets/icon/brl-icon.png',
  };

  listCoin = [
    {
      name: 'BRL',
      img: '../../../assets/icon/brl-icon.png',
    },
    {
      name: 'USDT',
      img: '../../../assets/icon/usdt-icon.png',
    },
  ];

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private dataService: ShareDataService,
    private readonly httpClient: HttpClient,
  ) {
    this.destinyUser = this.userDestiny ? JSON.parse(this.userDestiny) : {};

    this.form = this.formBuilder.group({
      valuePay: [0],
    });
  }
  ngOnInit(): void {
    this.httpClient.get('https://api.bcb.gov.br/dados/serie/bcdata.sgs.10813/dados?formato=json').subscribe({
      next: data => {
        const price: any = data
         this.usdPrice = Number(price[price.length - 1].valor)
  
      },
      error: error => {}
    })
  }
  

  selectCoin(coin: typeof this.coinSelectSend, type: string) {
    this.form.controls['valuePay'].setValue(0)
    if (type === 'receive') {
      this.coinSelectReceive = coin;
    } else {
      this.coinSelectSend = coin
    };
    
  }

  get userDestiny(): string {
    return this.dataService.serviceData;
  }

  goBack() {
    this.location.back();
  }

}
