import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-transfer-message',
  templateUrl: './transfer-message.component.html',
  styleUrls: ['./transfer-message.component.scss']
})
export class TransferMessageComponent implements OnInit {
  @ViewChild('content') modal: any;
  @ViewChild('changeValueModal') changeValueModal: any;

  form: FormGroup;
  formChangeValue: FormGroup;

  userInfo: any;
  userTransferData: any;

  chosenValue: number;
  formatedChosenValue: string;

  nameToTransfer: string | null;
  phoneToTransfer: any;
  emailToTransfer: string | null;

  balanceCodeWallet: number
  receiveValue: any

  userTransferInfo: any;
  getAccountUserInfo: any

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private accountService: AccountService,
  ) {
    
    this.userTransferData = this.clientService.getUserDataTransfer();
    const chosenValue = localStorage.getItem('chosenTransferValue')
    if (chosenValue) {
      this.chosenValue = JSON.parse(chosenValue)
    } else {
      this.router.navigate(['/logged/transfer-value'])
    }

    this.form = this.fb.group({
      message: ['', Validators.required, Validators.minLength(5)]
    })

    this.formChangeValue = this.fb.group({
      valuePix: [this.chosenValue, Validators.required],
    })
  }


  ngOnInit(): void {
    this.clientService.getUserInfo().subscribe({
      next: data => {
        console.log('data', data)
        this.userInfo = data
      }
    })

    this.nameToTransfer = localStorage.getItem('nameToTransfer');
    this.phoneToTransfer = localStorage.getItem('phoneToTransfer');
    this.emailToTransfer = localStorage.getItem('emailToTransfer');

    this.formatedChosenValue = this.chosenValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

    // Get Balance
    this.accountService.getAccount().subscribe({
      next: ({ balance }) => {
        this.balanceCodeWallet = balance
      }
    })

    this.receiveValue = localStorage.getItem('chosenTransferValue');

    // Pegar o numero da conta/digito
    this.userTransferInfo = this.clientService.getUserDataTransfer();
    this.accountService.getAccountById(this.userTransferInfo._id).subscribe({
      next: data => {
        console.log('numero da conta que recebra', data)
        this.getAccountUserInfo = data
      },
      error: err => {
        console.error(err);
      }
    })
  }

  close() {
    this.modalService.dismissAll();
  }

  submitChangePIXValue() {
    if (this.formChangeValue.controls['valuePix'].value === 0) {
      this.toastrService.error('Insira um valor', '', { progressBar: true })
      return
    }
    if (this.formChangeValue.controls['valuePix'].value && this.formChangeValue.controls['valuePix'].value > this.balanceCodeWallet) {
      this.toastrService.error('Saldo insuficiente', '', { progressBar: true })
      return
    }

    // SUCCESS
    this.chosenValue = this.formChangeValue.controls['valuePix'].value
    this.formatedChosenValue = this.chosenValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

    localStorage.setItem('chosenPixValue', this.formChangeValue.controls['valuePix'].value)
    this.toastrService.success('Valor alterado com sucesso', '', { progressBar: true })
    this.close()
  }

  openChangeValueModal() {
    this.modalService.open(this.changeValueModal)
  }

  goToCheckPin() {
    this.router.navigate(['/logged/transfer-verify']);
  }

}
