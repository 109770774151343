<section class="container min-vh-100 pt-3">
    <div class="row justify-content-center">
        <form [formGroup]="form" class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
            <!-- HEADER -->
            <div class="d-flex justify-content-between align-items-center fade-in">
                <svg [routerLink]="['/logged/transfer']" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                        fill="#060A30" />
                </svg>
                <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
                    Transferência
                </h1>
                <div class="col-1"></div>
            </div>

            <!-- VALUE -->
            <div class="flex-grow-1 d-flex flex-column pt-5 fade-in-left pb-4">
                <h4 class="text-wrap fw-700 font-size-14 line-19 color-secundary m-0">Saldo disponível em conta</h4>
                <div class="d-flex align-items-center justify-content-start mt-1">
                    <p class="fw-400 font-size-14 line-19 letter-spacing-05 color-secundary m-0">{{ !showBalance ? 'R$ ' : ''
                        }}{{ formatBalance(formatedBalance) }}</p>
                    <button class="bg-transparent border-none ms-2" (click)="toggleBalance()">
                        <span class="bi color-secundary" style="font-size: 1.15rem; color: #4A35E8;"
                            [ngClass]="showBalance ? 'bi-eye' : 'bi-eye-slash'"></span>
                    </button>
                </div>
                <h4 class="fw-700 font-size-14 color-secundary mb-2 mt-3">Transferindo para</h4>
                <div class="card-add-money d-flex flex-row p-3 align-items-start cursor-pointer">
                    <div class="border-profile-picture-s position-relative p-0 rounded-circle cursor-pointer mt-0">
                        <span [routerLink]="['/logged/my-account']"
                            class="fw-500 d-flex justify-content-center color-white align-items-center rounded-circle w-100 h-100 margin-top-2 pt-1"
                            style="font-size: 1.25rem;">
                            {{ nameToTransfer?.slice(0, 1) }}</span>
                    </div>
                    
                    <div class="d-flex flex-column px-3">
                        <h4 *ngIf="nameToTransfer !== ''"
                            class="text-start text-wrap fw-700 font-size-14 letter-spacing-05 line-19 color-secundary">
                            {{nameToTransfer}}
                        </h4>
                        <h4 *ngIf="nameToTransfer == ''"
                            class="text-start text-wrap fw-700 font-size-14 letter-spacing-05 line-19 color-secundary">
                            Nome não informado
                        </h4>
                        <p *ngIf="emailToTransfer !== ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            {{emailToTransfer}}
                        </p>
                        <p *ngIf="emailToTransfer == ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            E-mail não informado
                        </p>
                        <p *ngIf="phoneToTransfer !== ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            ({{phoneToTransfer.slice(3, 5)}}) {{phoneToTransfer.slice(5,10)}}-{{phoneToTransfer.slice(10,14)}}
                        </p>
                        <p *ngIf="phoneToTransfer == ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            Telefone não informado
                        </p>
                    </div>
                </div>
                <p class="fade-in fw-400 font-size-16 color-secundary mt-5">Qual valor você quer transferir?</p>
                <label class="fade-in-left fw-700 font-size-14 color-secundary">Insira a quantia</label>
                <div
                    class="position-relative fade-in-left border border-dark-green rounded-3 d-flex align-items-center cursor-pointer mt-2">
                    <div class="position-absolute d-flex align-items-center justify-content-start"
                        style="left: .5rem; top: 50%; transform: translateY(-50%);">
                        <img src="../../../assets/icon/brl-icon.png" alt="" height="20px" class="mx-2">
                        <span class="fw-400 mt-1"
                            style="color: #9E9D9D; font-size: 0.875rem; letter-spacing: 0.01469rem;">BRL</span>
                    </div>
                    <input type="text" class="fw-700 font-size-16 form-control border-0 bg-transparent color-secundary py-3"
                        formControlName="value" currencyMask
                        [options]="{ prefix: 'R$ ' ,thousands: '.', decimal: ',' }">
                </div>
            </div>

            <!-- BUTTONS -->
            <div class="px-2 d-flex flex-column py-5 fade-in">
                <button [disabled]="this.form.invalid || form.controls['value'].value === 0 
                || this.form.controls['value'].value && this.form.controls['value'].value > this.balanceCodeWallet"
                    (click)="submit()" class="button-green fw-800 font-size-14 mt-3">Continuar</button>
                <button [routerLink]="['/logged/home']"
                    class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
            </div>
        </form>
    </div>
</section>