import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CriptoValuesService {

  criptoBalances: any[] = [
    {
      icon: '../../../assets/icon/icon-tether.svg',
      name: 'Tether',
      coinCripto: 'USDT',
    },
    {
      icon: '../../../assets/icon/icon-bitcoin.svg',
      name: 'Bitcoin',
      coinCripto: 'BTC',
    },
    {
      icon: '../../../assets/icon/icon-ethereum.svg',
      name: 'Ethereum',
      coinCripto: 'ETH',
    },
    {
      icon: '../../../assets/icon/icon-busd.svg',
      name: 'Binance USD',
      coinCripto: 'BUSD',
    },
  ]
  totalUsdValue: number = 0

  constructor() { }

  getWalletBalance() {
    return {
      criptoBalances: this.criptoBalances,
      totalUsdValue: this.totalUsdValue
    }
  }

  setWalletBalance(balances : any) {
    for (const balance of balances.balances) {
      const foundCoin = this.criptoBalances?.find(coin => coin.coinCripto === balance.symbol);
      if(foundCoin) {
        foundCoin.value = balance.value;
        foundCoin.usdValue = balance.valueInUsd
        
        if (balance.valueInUsd) {
          this.totalUsdValue += parseFloat(balance.valueInUsd);
        }
      }
    }

    return {
      criptoBalances: this.criptoBalances,
      totalUsdValue: this.totalUsdValue
    }
  }
}
