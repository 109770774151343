<section class="container min-vh-100">
    <div class="row justify-content-center">
        <form [formGroup]="form" class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">
            <div class="d-flex justify-content-between align-items-center fade-in">
                <svg [routerLink]="['/logged/transfer-value']" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                        fill="#060A30" />
                </svg>
                <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
                    Transferência
                </h1>
                <div class="col-1"></div>
            </div>

            <div class="flex-grow-1 d-flex flex-column fade-in-left pb-4">
                <h4 class="fw-700 font-size-21 color-secundary mb-2 mt-5 letter-spacing-080">Transferindo</h4>
                <div class="d-flex align-items-center mb-4">
                    <h3 class="fw-700 font-size-32 color-primary letter-spacing-080">{{formatedChosenValue}}</h3>
    
                    <svg class="ms-2 cursor-pointer" (click)="openChangeValueModal()" width="16" height="16" viewBox="0 0 18 18" fill="none">
                        <path d="M13.3438 2.25L11.6562 3.96875L14.0312 6.34375L15.75 4.65625C16.0312 4.34375 16.0312 3.875 15.75 3.59375L14.4062 2.25C14.125 1.96875 13.6562 1.96875 13.3438 2.25ZM10.9375 4.65625L3.59375 12.0312C3.34375 12.25 3.15625 12.5625 3.0625 12.875L2.21875 15.7812L5.125 14.9062C5.4375 14.8125 5.75 14.6562 5.96875 14.4062L13.3438 7.0625L10.9375 4.65625ZM15.125 1.53125L16.4688 2.875C17.125 3.5625 17.125 4.65625 16.4688 5.34375L6.6875 15.125C6.3125 15.4688 5.875 15.75 5.40625 15.875L1.625 17C1.4375 17.0312 1.25 17 1.125 16.875C1 16.75 0.96875 16.5625 1 16.375L2.125 12.5938C2.25 12.125 2.53125 11.6875 2.875 11.3125L12.6562 1.53125C13.3438 0.875 14.4375 0.875 15.125 1.53125Z" fill="#4A35E8"/>
                    </svg>
                </div>

                <h4 class="fw-700 font-size-14 color-secundary mb-2">Transferindo para</h4>
                <div class="card-add-money d-flex flex-row p-3 align-items-start cursor-pointer">
                    <div class="border-profile-picture-s position-relative p-0 rounded-circle cursor-pointer mt-0">
                        <span [routerLink]="['/logged/my-account']"
                            class="fw-500 d-flex justify-content-center color-white align-items-center rounded-circle w-100 h-100 margin-top-2 pt-1"
                            style="font-size: 1.25rem;">
                            {{ nameToTransfer?.slice(0, 1) }}</span>
                    </div>
                    
                    <div class="d-flex flex-column px-3">
                        <h4 *ngIf="nameToTransfer !== ''"
                            class="text-start text-wrap fw-700 font-size-14 letter-spacing-05 line-19 color-secundary">
                            {{nameToTransfer}}
                        </h4>
                        <h4 *ngIf="nameToTransfer == ''"
                            class="text-start text-wrap fw-700 font-size-14 letter-spacing-05 line-19 color-secundary">
                            Nome não informado
                        </h4>
                        <p *ngIf="emailToTransfer !== ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            {{emailToTransfer}}
                        </p>
                        <p *ngIf="emailToTransfer == ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            E-mail não informado
                        </p>
                        <p *ngIf="phoneToTransfer !== ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            ({{phoneToTransfer.slice(3, 5)}}) {{phoneToTransfer.slice(5,10)}}-{{phoneToTransfer.slice(10,14)}}
                        </p>
                        <p *ngIf="phoneToTransfer == ''"
                            class="text-start text-wrap fw-400 font-size-14 line-19 color-secundary pb-0 mb-0">
                            Telefone não informado
                        </p>
                    </div>
                </div>

                <label for="message" class="text-wrap fw-700 font-size-14 line-19 color-secundary mb-2 px-1 mt-3">Escreva uma
                    mensagem</label>

                <textarea type="text" id="message" name="message"
                    class="border border-dark-green rounded-3 fw-400 font-size-14 form-control border-0 color-secundary py-3"
                    formControlName="message" maxlength="100" placeholder="(opcional)"></textarea>

            </div>
            <div class="px-2 d-flex flex-column py-5 fade-in">
                <button (click)="goToCheckPin()" class="button-green fw-800 font-size-14 mt-3">Transferir</button>
                <button [routerLink]="['/logged/home']"
                    class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
            </div>
        </form>
    </div>
</section>

<ng-template #changeValueModal>
    <form [formGroup]="formChangeValue">
      <div class="container p-4 pb-3 bg-modal">
        <div class="d-flex justify-content-end">
          <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="close()"></i>
        </div>
  
        <!-- VALUE -->
        <div class="flex-grow-1 d-flex flex-column pt-5 px-2 fade-in-left pb-4">
          <label for="value" class="fw-700 font-size-14 line-19 letter-spacing-05 color-secundary mb-2">Insira a quantia</label>
          <div class="position-relative fade-in-left border border-dark-green rounded-3 d-flex align-items-center cursor-pointer py-2 mb-2">
            <div class="position-absolute d-flex align-items-center justify-content-start" style="left: .5rem; top: 50%; transform: translateY(-50%);">
              <img src="../../../assets/icon/brl-icon.png" alt="" height="20px" class="mx-2">
              <span class="fw-400 mt-1" style="color: #9E9D9D; font-size: 0.875rem; letter-spacing: 0.01469rem;">BRL</span>
            </div>
  
            <input type="text"
              class="fw-700 font-size-16 form-control border-0 bg-transparent color-secundary py-2"
              formControlName="valuePix" currencyMask
              [defaultValue]="0"
              [options]="{ prefix: 'R$ ' ,thousands: '.', decimal: ',' }">
          </div>
        </div>
  
        <!-- BUTTONS -->
        <div class="px-2 d-flex flex-column py-5 fade-in">
          <button [disabled]="this.formChangeValue.controls['valuePix'].invalid" (click)="submitChangePIXValue()" class="button-green fw-800 font-size-14 mt-3">Continuar</button>
          <button (click)="close()" class="btn-outline-dark bg-transparent border-none mt-4 color-primary fw-800 font-size-14 mt-3">Cancelar</button>
        </div>
  
      </div>
    </form>
  </ng-template>