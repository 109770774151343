import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splash-cripto',
  templateUrl: './splash-cripto.component.html',
  styleUrls: ['./splash-cripto.component.scss']
})
export class SplashCriptoComponent {

  showSplashCripto = 1;

  constructor(
    private location: Location,
    private router: Router
  ) { }

  goBack() {
    this.location.back()
  }

  changeSplashCripto(splashCripto: number) {
    this.showSplashCripto = splashCripto;
  }

  dismissAnGo() {
    localStorage.setItem('dismissSplashCripto', 'true')
    this.router.navigate(['/logged/cripto'])
  }
}
