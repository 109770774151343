<section class="container min-vh-100">
    <div class="row justify-content-center">
        <div class="col col-xl-12 fade-in">
            <div class="row pt-5 mb-3">
                <div (click)="goBack()" class="col-2 align-items-start cursor-pointer">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
                    </svg>
                </div>
                <div class="col-8 d-flex justify-content-center align-items-center">
                    <p class="fw-700 font-size-16 color-secundary mb-0">{{ 'NOTIFICATIONS.TITLE' | translate }}</p>
                </div>
            </div>
        </div>

        <ng-container *ngIf="notifications.length !== 0 else empty">
            <div class="col-md-5">
                <div *ngFor="let item of notifications" class="card-home mt-3 p-3 pb-4 fade-in-left">
                    <div class="row">
                        <div class="col d-flex align-items-start justify-content-between">
                            <p class="mb-2"><img src="../../../../assets/icon/circle-green.svg" height="10px"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex align-items-start justify-content-start" style="gap: 1rem;">
                            <p><img src="../../../../assets/icon/icon-coins.svg"></p>
    
                            <div class="d-flex flex-column mt-1" style="max-width: 80%;">
                                <span class="fw-400 font-size-14 color-secundary letter-spacing-0014 pb-1">{{item?.title}}</span>
                                <span class="fw-400 font-size-11 color-gray letter-spacing-0020">{{item?.message}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #empty>
            <div class="text-center mt-5 fade-in-left">
                <p class="font-size-16 color-secundary">{{ 'NOTIFICATIONS.NO_RESULTS' | translate }}</p>
                <img class="pt-3" src="../../../../assets/icon/none-notifications-img.svg">
            </div>
        </ng-template>
    </div>
</section>
