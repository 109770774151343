import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientService } from 'src/app/services/client.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-phone-verify',
  templateUrl: './phone-verify.component.html',
  styleUrls: ['./phone-verify.component.scss']
})
export class PhoneVerifyComponent {

  // separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Brazil, CountryISO.UnitedStates];

  form: FormGroup;

  isSubmit: boolean = false;

  MASK_BR = '(00) 00000-0000';
  MASK_US = '000-000-0000';

  mask: string = this.MASK_BR;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private location: Location,
  ) {
    this.form = this.formBuilder.group({
      phone: ['', [Validators.required]]
    })
  }

  ngOnInit(): void { }

  onCountryChange(selectedCountry: any) {
    const countryCode = selectedCountry.iso2;

    if (countryCode === 'br') {
      this.mask = this.MASK_BR;
    } else if (countryCode === 'us') {
      this.mask = this.MASK_US;
    } else {
      this.mask = '';
    }
  }

  maskApply(a: any) {
    if (this.mask === this.MASK_BR) {
      setTimeout(() => {
        // Remove todos os não dígitos do número
        const numericValue = this.form.controls['phone'].value.number

        let maskedValue;

        if (numericValue.length === 2) {

          maskedValue = numericValue.replace(/(\d{2})/, '($1)');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 3) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d)(\d{4})/, '($1) $2');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 4) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{2})(\d{2})/, '($1) $2');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 5) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{3})(\d{3})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 6) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 7) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 8) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{1})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 9) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{2})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 10) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{3})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        } else if (numericValue.length === 11) {

          const cleanMasked = numericValue.replace(/\D/g, "")
          maskedValue = cleanMasked.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
          this.form.controls['phone'].setValue(maskedValue)

        }
      }, 0)
    }
    if (this.mask === this.MASK_US) {
      setTimeout(() => {
        // Remove todos os não dígitos do número
        const numericValue = this.form.controls['phone'].value.number

        // Aplica a máscara "(00) 00000-0000"
        const maskedValue = numericValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

        this.form.controls['phone'].setValue(maskedValue)
      }, 0)
    }

  }

  back() {
    this.location.back();
  }

  confirm() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }

    localStorage.setItem('newPhone', this.form.controls['phone'].value.e164Number);

    this.clientService.verifyEmailOrPhone({ phone: this.form.controls['phone'].value.e164Number }).subscribe({
      next: (data: any) => {
        this.router.navigate(['/verify-code-logged']);
      },
      error: (error: any) => {
        console.log(error);
        if(error.status === 400) {
          this.form.controls['phone'].setErrors({ 'phoneExists': true });
        } else {
          this.toastrService.error(`Este telefone já está sendo utilizado.`, '', { progressBar: true });
        }
      }
    });
  }
}
