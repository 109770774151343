<section>
    <form [formGroup]="form">
        <div class="container min-vh-100">
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div class="d-flex justify-content-center align-items-center pb-5 pt-5 fade-in">
                        <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="148px"></p>
                    </div>

                    <div class="fw-400 font-size-16 color-secundary text-center px-2 pt-4 pb-2 mb-5 fade-in-right">
                        <p class="letter-spacing-0016">{{ 'LOGIN_NUMBER.PAYMENT_TEXT' | translate }}</p>
                    </div>

                    <p class="d-flex justify-content-center color-secundary text-center fw-400 font-size-14 letter-spacing-1 pb-0 pt-4 mb-1 fade-in-left">
                        {{ 'LOGIN_NUMBER.CREATE_OR_SIGN_IN' | translate }}
                    </p>

                    <h6 class="fw-700 font-size-21 color-secundary text-center mb-3 pb-1 letter-spacing-0098 fade-in-left">
                        {{ 'LOGIN_NUMBER.PHONE_NUMBER_LABEL' | translate }}
                    </h6>

                    <div class="d-flex flex-column justify-content-center fade-in-left">
                        <ngx-intl-tel-input (countryChange)="onCountryChange($event)" (keydown)="maskApply($event)"
                            [ngClass]="this.form.invalid && this.isSubmit ? 'input-transparent-login-number-red' : 'input-transparent-login-number'"
                            [cssClass]="'custom fw-400'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                            ]" [selectFirstCountry]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                            formControlName="phone" [customPlaceholder]="mask" #phoneInput>
                        </ngx-intl-tel-input>

                        <div *ngIf="this.form.invalid && this.isSubmit"
                            class="d-flex color-red margin-right-118 letter-spacing-1 mt-1 fade-in-left">
                            {{ 'LOGIN_NUMBER.INVALID_NUMBER_ERROR' | translate }}
                        </div>

                        <a (click)="goToLoginEmail()"
                            class="fw-400 font-size-16 d-flex justify-content-center text-center color-blue cursor-pointer mt-3 fade-in-left">
                            Entre utilizando seu endereço de email
                        </a>
                    </div>
                    <div class="d-flex justify-content-center mt-5 pt-5 fw-800">
                        <button (click)="confirm()" class="button-green fw-800 font-size-14">{{ 'LOGIN_NUMBER.CONTINUE_BUTTON' | translate }}</button>
                    </div>
                    <a class="d-flex justify-content-center fw-400 color-blue cursor-pointer font-size-16 mt-3 mb-5 letter-spacing-0016 fade-in"
                        [routerLink]="['/help']">
                        {{ 'LOGIN_NUMBER.NEED_HELP_LINK' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </form>
</section>
