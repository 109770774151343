import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-forgot-password2',
  templateUrl: './forgot-password2.component.html',
  styleUrls: ['./forgot-password2.component.scss']
})
export class ForgotPassword2Component {
  form: FormGroup;

  senha: string = '';
  senhas: string = '';
  mostrarSenha: boolean = false;

  phone = localStorage.getItem('phone');

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: ToastrService,
    private router: Router,
    private clientService: ClientService
  ) {
    this.form = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(60),
      ])],
      confirmPassword: [''],
    })
  }

  goBack() {
    this.location.back()
  }

  toggleMostrarSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  verificarForca() {
    this.senha = this.form.controls['password']?.value;
  }

  confirm() {

    const request = {
      phone: this.phone || '',
      newPassword: this.form.controls['password'].value
    }

    this.clientService.forgotPassword(request).subscribe({
      next: data => {
        this.toastrService.success('Senha alterada!', 'Sucesso', { progressBar: true });
        this.router.navigate(['/login-number'])
      },
      error: error => {
        this.toastrService.error(`${error.error.errors}`, 'Erro', { progressBar: true });
      }
    })
  }
}
