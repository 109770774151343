import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssasPaymentGeneratePaymentTicketRequestDto } from 'src/app/dtos/payment/assas-payment-generate-pix-request.dto';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-receive-ticket',
  templateUrl: './receive-ticket.component.html',
  styleUrls: ['./receive-ticket.component.scss']
})
export class ReceiveTicketComponent implements OnInit {

  currentScreenIndex = 0;

  dueDateModel: NgbDate;
  formattedDate: string;
  
  minDate: any = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  dueDate: string;
  actualDate = new Date()
  actualDay = this.actualDate.getDate()
  actualMonth = this.actualDate.getMonth() + 1
  actualYear = this.actualDate.getFullYear()

  monthsWith31 = [1, 3, 5, 7, 8, 10, 12]

  invalidDate = true
  filledInput = false 

  valueToReceive: any

  boletoResponse: any

  constructor(
    private toastrService: ToastrService,
    private PaymentService: PaymentService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
    let responseValue: any = localStorage.getItem('receiveValue');
    this.valueToReceive = JSON.parse(responseValue);
  }

  onDateChange(newDate: NgbDate) {
    const formattedDate = `${newDate.day < 10 ? '0' : ''}${newDate.day}/${newDate.month < 10 ? '0' : ''}${newDate.month}/${newDate.year}`;
    this.dueDate = formattedDate;
    this.validData();
  }

  validData() : boolean {
    const day = this.dueDateModel.day;
    const month = this.dueDateModel.month;
    const year = this.dueDateModel.year;
    
    let validDate: boolean

    if(
      ( year < this.actualYear ) ||
      ( year === this.actualYear + 2 ) || 
      ( year === this.actualYear && month < this.actualMonth ) || 
      ( month > 12 ) || 
      ( month <= 0 ) || 
      ( day === 0 ) || 
      ( day > 31 ) || 
      ((month === this.actualMonth && day <= this.actualDay) && (year === this.actualYear)) || 
      ( !this.monthsWith31.includes(month) && day === 31 )
    ){
      validDate = false
      this.invalidDate = true
    } else {
      validDate = true
      this.invalidDate = false
    }
    
    if(validDate) {
      const formattedDate = String(year) + '-' + String(month < 10 ? '0' + month : month) + '-' + String(day < 10 ? '0' + day : day)
      localStorage.setItem('dueDate', formattedDate)
      this.formattedDate = formattedDate
    }

    return validDate
  }

  nextScreen() {
    switch(this.currentScreenIndex) {
      case 0:
        if(this.validData()) this.currentScreenIndex = this.currentScreenIndex + 1
        break;
      case 1:
        this.generateTicket()
        break;
    }
  }

  previousScreen() {
    if (this.currentScreenIndex > 0) {
      this.currentScreenIndex--
    }
  }

  generateTicket() {
    this.ngxSpinnerService.show();

    const dto: AssasPaymentGeneratePaymentTicketRequestDto = {
      dueDate: this.formattedDate,
      value: this.valueToReceive,
    }

    this.PaymentService.generateTicket(dto).subscribe({
      next: (data) => {
        this.boletoResponse = data
        this.toastrService.success('Boleto emitido com sucesso', 'Sucesso', { progressBar: true });
        this.currentScreenIndex = this.currentScreenIndex + 1
      },
      error: (err) => {
        console.log(err)
        this.toastrService.error('Erro ao emitir boleto', 'Erro', { progressBar: true });
        this.ngxSpinnerService.hide();
      },
      complete: () => {
        this.ngxSpinnerService.hide();
      }
    })
  }

  downloadBoleto() {
    const link = document.createElement('a');
    link.href = this.boletoResponse.bankSlipUrl;
    link.target = '_blank';
    link.download = 'boleto.pdf';
    link.click();
  }

  copyValue() {
    navigator.clipboard.writeText(this.boletoResponse.barCode)
    this.toastrService.success('Código copiado', 'Sucesso', { progressBar: true });
  }

  submit() {
    localStorage.removeItem('nameCharge');
    localStorage.removeItem('receiveValue');
    localStorage.removeItem('receiveType');
    localStorage.removeItem('dueDate');

    this.router.navigate(['/home']);
  }
}
