<section>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-5 d-flex justify-content-evenly align-items-center toolbar-background">
                <div class="d-flex flex-column justify-content-center cursor-pointer" (click)="selectItem('/home')">
                    <div class="d-flex justify-content-center">
                        <img src="../../../assets/icon/logged-inicio.svg" *ngIf="homeicon" class="icon-width-24">
                        <img src="../../../assets/icon/logged-inicio-verde.svg" *ngIf="homeicongreen"
                            class="icon-width-24">
                    </div>
                    <p class="tab-bar-title letter-spacing-1 font-size-11 pt-2 m-0" [class]="homeicongreen ? 'selected' : ''">Início</p>
                </div>
                <div class="d-flex flex-column justify-content-center cursor-pointer"
                    (click)="selectItem('/your-activities')">
                    <div class="d-flex justify-content-center">
                        <img src="../../../assets/icon/logged-atividade.svg" *ngIf="activitiesicon"
                            class="icon-width-24">
                        <img src="../../../assets/icon/logged-atividade-verde.svg" *ngIf="activitiesicongreen"
                            class="icon-width-24">
                    </div>
                    <p class="tab-bar-title letter-spacing-1 font-size-11 pt-2 m-0" [class]="activitiesicongreen ? 'selected' : ''">Atividade</p>
                </div>
                <div class="d-flex flex-column justify-content-center cursor-pointer" (click)="selectItem('/cripto')">
                    <div class="d-flex justify-content-center">
                        <img src="../../../assets/icon/logged-cripto.svg" *ngIf="criptoicon" class="icon-width-24">
                        <img src="../../../assets/icon/logged-cripto-verde.svg" *ngIf="criptoicongreen"
                            class="icon-width-24">
                    </div>
                    <p class="tab-bar-title letter-spacing-1 font-size-11 pt-2 m-0" [class]="criptoicongreen ? 'selected' : ''">Cripto</p>
                </div>
                <div class="d-flex flex-column justify-content-center cursor-pointer" (click)="selectItem('/wallet')">
                    <div class="d-flex justify-content-center">
                        <img src="../../../assets/icon/logged-cartao.svg" *ngIf="walleticon" class="icon-width-24">
                        <img src="../../../assets/icon/logged-cartao-verde.svg" *ngIf="walleticongreen"
                            class="icon-width-24">
                    </div>
                    <p class="tab-bar-title letter-spacing-1 font-size-11 pt-2 m-0" [class]="walleticongreen ? 'selected' : ''">Cartão</p>
                </div>
            </div>
        </div>
    </div>
</section>