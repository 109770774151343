import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AssasPaymentGeneratePaymentTicketRequestDto, AssasPaymentGeneratePixRequestDto, AssasPixCheckoutResponse } from '../dtos/payment/assas-payment-generate-pix-request.dto';
import { Observable, catchError, map } from 'rxjs';
import { PaymentConfirmationDto } from '../dtos/payment/payment-confirmation.dto';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService {
  walletApiUrl: string = `${environment.api.path}/payment`

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    super();
  }

  generatePIX(dto: AssasPaymentGeneratePixRequestDto): Observable<AssasPixCheckoutResponse> {
    return this.httpClient
      .post(`${this.walletApiUrl}/generate-pix`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  confirmation(dto: PaymentConfirmationDto) {
    return this.httpClient
      .post(`${this.walletApiUrl}/confirmation`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  generateTicket(dto: AssasPaymentGeneratePaymentTicketRequestDto) : Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/generate-ticket`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }


}
