import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService extends BaseService {
  walletApiUrl: string = `${environment.api.path}/client`

  constructor(private httpClient: HttpClient) {
    super()
   }

   getWalletBalance(_id: string): Observable<any> {
    return this.httpClient
      .get(`${this.walletApiUrl}/wallet-balance/${_id}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
