import { Component, EventEmitter, Output } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-confirm-pin-model',
  templateUrl: './confirm-pin-model.component.html',
  styleUrls: ['./confirm-pin-model.component.scss']
})
export class ConfirmPinModelComponent {
  @Output() confirmPinEvent = new EventEmitter<string>();

  PressBackspace: any;
  form:FormGroup;
  showPin = false;

  invalidCode = false

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private clientService: ClientService,
  ) {
    // this.form = this.fb.group({
    //   pin: ['', [Validators.required, Validators.minLength(4)]],
    // });
    this.form = this.fb.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]]
    });
   }

  exit() {
    this.modalService.dismissAll();
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');

    if (length == maxlength && n != '') {
      n.focus();
      return;
    }
    if (e.key === 'Backspace') {
      if (c.value === '') {
        if (p != '') {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      }
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  confirmPin() {
    // const pin = this.form.get('pin')?.value;
    // if(!pin) return;

    const code = Object.values(this.form.value)
    .join('')
    .replace(/[^0-9.]+/g, '');
    
    this.clientService.checkPin(code).subscribe({
      next: data => {
        if (data === true) {
          this.toastr.success('Senha confirmada com sucesso!');
          this.confirmPinEvent.emit(code);
          this.modalService.dismissAll();
        }
        else {
          this.toastr.error('Senha Eletrônica incorreta!');
          this.invalidCode = true
        }
      },
      error: error => {
        this.toastr.error('Senha Eletrônica incorreta!');
      }
    })
  }
}
