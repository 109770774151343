<section class="container min-vh-100 fade-in">
  <div class="fade-in row pt-5">
    <div [routerLink]="['/logged/my-account']" class="col-2 align-items-start cursor-pointer">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path
          d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
          fill="#060A30" />
      </svg>
    </div>
    <div class="col-8 d-flex justify-content-center align-items-center">
      <p class="fw-700 font-size-16 color-secundary m-0">{{ 'SETTINGS.TITLE' | translate }}</p>
    </div>
  </div>

  <div class="card-home mt-2 p-3 d-flex justify-content-between px-5">
    <div>
      <span class="fw-500 font-size-14 color-secundary">{{ 'SETTINGS.REMEMBER_ME' | translate }}</span>
    </div>
    <div class="d-flex align-items-center check-input-container px-1">
      <input class="check-input" type="checkbox" [(ngModel)]="rememberMeEnabled" (change)="toggleRemember()">
    </div>
  </div>

  <!-- TouchId -->
  <!-- <div class="card-home mt-2 p-3 d-flex justify-content-between px-5">
    <div>
      <span class="fw-500 font-size-14 color-secundary">{{ 'SETTINGS.TOUCHID' | translate }}</span>
    </div>
    <div class="d-flex align-items-center check-input-container px-1">
      <input class="check-input" type="checkbox" [(ngModel)]="touchIDEnabled" (change)="toggleTouchID()">
    </div>
  </div> -->

  <div class="card-home mt-2 p-3 d-flex justify-content-between px-5">
    <div>
      <span class="fw-500 font-size-14 color-secundary">{{ 'SETTINGS.LANGUAGE' | translate }}</span>
    </div>

    <app-change-language></app-change-language>
  </div>

  <div class="card-home mt-2 p-3 d-flex justify-content-between px-5" [routerLink]="['/logged/notifications']">
    <div class="d-flex col justify-content-start">
      <span class="fw-500 font-size-14 color-secundary">{{ 'SETTINGS.NOTIFICATIONS' | translate }}</span>
    </div>
    <div class="d-flex align-items-center justify-content-center form-check cursor-pointer">
      <img src="../../../assets/icon/arrow-right.svg" width="20px">
    </div>
  </div>

  <div class="card-home mt-2 p-3 d-flex justify-content-between px-5">
    <div class="d-flex col justify-content-start">
      <span class="fw-500 font-size-14 color-secundary">{{ 'SETTINGS.LOGOUT' | translate }}</span>
    </div>
    <div class="d-flex align-items-center justify-content-center form-check cursor-pointer">
      <img src="../../../assets/icon/out.svg" width="20px" (click)="logOut()">
    </div>
  </div>
</section>
