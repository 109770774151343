import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss']
})
export class RequestCardComponent implements OnInit {

  constructor(
    private router: Router,
    private clientService: ClientService,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
  ) {

  }

  ngOnInit(): void {

  }

  submit() {
    this.ngxSpinnerService.show();
    const request: any = {
      requestCard: true
    }

    this.clientService.updateUserInfo(request).subscribe({
      next: data => {
        this.ngxSpinnerService.hide();
        this.router.navigate(['logged/request-card-success'])
      },
      error: err => {
        this.ngxSpinnerService.hide();
        this.toastrService.error('Erro ao solicitar o cartão.', 'Erro', { progressBar: true });
        console.error(err)
      }
    })

  }

}
