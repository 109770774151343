<section class="container min-vh-100 pb-5">
    <form [formGroup]="form">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="row pt-5">
                    <div [routerLink]="['/login-number']" class="col-2 align-items-start cursor-pointer">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                                fill="#060A30" />
                        </svg>
                    </div>
                    <div class="col-8 d-flex justify-content-center align-items-center mb-3">
                        <p class="m-0"><img src="../../../assets/images/fundo-escuro.svg" width="120px"></p>
                    </div>
                </div>

                <div class="fw-400 font-size-16 color-secundary text-center px-2 pt-4 fade-in-right">
                    <p class="letter-spacing-0016">{{ 'LOGIN_NUMBER.PAYMENT_TEXT' | translate }}</p>
                </div>

                <h6 class="fw-700 font-size-21 color-secundary text-center pt-5 mb-3 pb-1 letter-spacing-0098 fade-in-left">
                    {{ 'LOGIN_PASSWORD.TITLE' | translate }}</h6>

                <div class="fw-400 font-size-16 line-20 color-secundary text-center fade-in-left">
                    <input class="input-transparent w-100 px-3" type="password" id="password" formControlName="password"
                        placeholder="{{ 'LOGIN_PASSWORD.PASSWORD_PLACEHOLDER' | translate }}" required>
                </div>
                <p class="d-flex justify-content-center color-secundary fw-400 mt-3 cursor-pointer letter-spacing-0014 line-1242 font-size-14 fade-in-left"
                    (click)="forgotPassword()" style="color: #060A30;">{{ 'LOGIN_PASSWORD.FORGOT_PASSWORD' | translate
                    }}</p>
                <a (click)="sendCodeWithoutPassword()"
                    class="fw-400 font-size-16 d-flex justify-content-center text-center color-blue cursor-pointer mt-3 fade-in-left">{{
                    'LOGIN_PASSWORD.SEND_CODE_BUTTON' | translate }}</a>
                <div class="d-flex justify-content-center mt-5 pt-3 fw-800 fade-in">
                    <button class="button-green fw-800 font-size-14" (click)="confirm()">{{
                        'LOGIN_PASSWORD.CONTINUE_BUTTON' | translate }}</button>
                </div>
                <!-- <div class="d-flex justify-content-center pt-3 fw-800 fade-in">
                    <button class="button-white fw-800 font-size-14" (click)="goToFaceId()">
                        Face ID
                        <svg height="1em" viewBox="0 0 448 512" class="ms-2">
                            <style>
                                svg {
                                    fill: #070813;
                                }
                            </style>
                            <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
                        </svg>
                    </button>
                </div> -->
            </div>
        </div>
    </form>
</section>