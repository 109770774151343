import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-processing-screen',
  templateUrl: './processing-screen.component.html',
  styleUrls: ['./processing-screen.component.scss']
})
export class ProcessingScreenComponent {

  processed: boolean = true

  constructor(private router: Router) { }

  ngOnInit(): void {
    const timer = setTimeout(() => {
      this.processed = false
      // this.router.navigate(['/logged/home'])
      clearTimeout(timer);
    },5000);
  }
}
