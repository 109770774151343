import { io, Socket } from "socket.io-client";
import { SocketDto } from "src/app/dtos/socket/socket.dto";

export abstract class BaseSocketService {

  private socketDto: SocketDto;

  constructor(socketDto: SocketDto) {
    this.socketDto = socketDto;
  }

  abstract initializeMain(): void;

  protected initializeCommonRoutine(socket: Socket): Socket {

    const user = JSON.parse(localStorage.getItem('user')!);

    if (!socket)
      socket = io(this.socketDto.host, {
        auth: {
          Authorization: `Bearer ${user.token}`,
        },
        path: this.socketDto.path,
      });

    return socket.connect();
  }

  abstract shutDownAll(): void;
}
