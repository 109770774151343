<section class="container min-vh-100">
    <div class="fade-in row pt-5">
        <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                    d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                    fill="#060A30" />
            </svg>
        </div>
        <div class="col-8 d-flex justify-content-center align-items-center">
            <p class="fw-700 font-size-16 color-secundary m-0">{{ 'REQUEST_CARD.CARDS' | translate }}</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-5 d-flex flex-column justify-content-center align-items-center pb-5">
            <img src="../../../assets/images/bitbentz-card-request.svg" alt="cartão code-wallet" style="width: 420px;">
            <p class="fw-700 font-size-18 letter-spacing-05 text-center text-black pb-2">{{ 'REQUEST_CARD.TAKE_YOUR_PASSION_TO_THE' | translate }}
                {{ 'REQUEST_CARD.NEXT_LEVEL' | translate }}</p>
            <p class="color-secundary letter-spacing-05 fw-400 text-center line-24 font-size-16">
                {{ 'REQUEST_CARD.GET_YOUR_CARD_CODEWALLET' | translate }}
                <b>{{ 'REQUEST_CARD.FREEDOM_TO_SHOP_ANYWHERE' | translate }}</b>
                {{ 'REQUEST_CARD.NOW_AVAILABLE_FOR_FREE' | translate }}
                <button (click)="submit()" class="btn button-green fw-800 font-size-14 mt-5">{{ 'REQUEST_CARD.REQUEST_MY_CARD' | translate }}</button>
            </div>
        </div>
    </section>
