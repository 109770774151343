<section class="container min-vh-100">
    <div class="row justify-content-center pt-3">
        <div class="col-md-5 d-flex flex-column min-vh-100 h-100 py-3">

            <div class="d-flex justify-content-between align-items-center fade-in">
                <svg [routerLink]="['/logged/deposit']" class="cursor-pointer" width="40" height="40"
                    viewBox="0 0 40 40" fill="none">
                    <path
                        d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z"
                        fill="#060A30" />
                </svg>
                <h1 class="fw-700 font-size-16 line-24 color-secundary cursor-click text-center mb-0 mt-1">
                    Depósito {{coinSelected?.name}}
                </h1>
                <div class="col-1"></div>
            </div>

            <div class="d-flex justify-content-center align-items-center mt-5" style="gap: 5rem;">
                <div class="d-flex flex-column">
                    <p class="text-purple mb-2">Moeda</p>
                    <div class="d-flex align-items-center">
                        <img [src]="coinSelected?.img" alt="" height="24px">
                        <span class="color-secundary ms-3">{{coinSelected?.name}}</span>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <p class="text-purple mb-2">Rede</p>
                    <div class="d-flex align-items-center">
                        <img [src]="rede?.img" alt="" height="24px">
                        <span class="color-secundary ms-3">{{rede?.name}}</span>
                    </div>
                </div>
            </div>
            <hr class="my-5">
            <p class="text-center color-gray" [class.d-none]="rede?.name !== 'Bitcoin'">Este endereço é compatível
                apenas com Bitcoin, altere a moeda
                para outras redes disponíveis.</p>
            <label for="" class="fw-bold color-secundary mt-3">Copie ou compartilhe o endereço da carteira</label>
            <div class="border-dark-green d-flex align-items-center p-3 rounded-3 mt-2">
                <span class="bg-transparent border-0 font-size-13 color-secundary w-100">{{walletAddress}}</span>
                <i class="bi bi-files" style="color: #4A35E8 !important;" (click)="copyWalletAddress()"></i>
            </div>
            <div class="d-flex align-items-center mt-4">
                <hr class="m-0 w-100">
                <span class="mx-3 color-secundary">
                    ou
                </span>
                <hr class="m-0 w-100">
            </div>
            <div class="d-flex justify-content-center align-items-center my-5 pb-5" style="gap: 4rem;">
                <div class="d-flex flex-column align-items-center">
                    <button class="btn btn-deposit-coin-bottom" (click)="openModalQrCode()">
                        <img src="../../../../assets/icon/qr-icon.svg">
                    </button>
                    <p class="color-secundary text-center mt-3">Escanear Qrcode</p>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center my-5 pb-5">
                <button [routerLink]="['/logged/home']" class="btn button-green fw-800 font-size-14">Voltar ao
                    início</button>
            </div>
        </div>
    </div>
</section>

<ng-template #content>
    <div class="container p-4 pb-3 bg-modal">
        <div class="d-flex justify-content-end">
            <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="close()"></i>
        </div>
        <p class="color-secundary text-center font-size-16">Compartilhe o endereço da carteira</p>

        <div class="d-flex justify-content-center">
            <i (click)="sharedIn('whatsapp')" class="bi bi-whatsapp font-size-35 color-secundary"></i>
            <i (click)="sharedIn('telegram')" class="bi bi-telegram font-size-35 mx-4 color-secundary"></i>
        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-outline-dark bg-transparent mt-4 color-secundary" (click)="close()">Cancelar</button>
        </div>
    </div>
</ng-template>

<ng-template #contentQrCode>
    <div class="container p-4 pb-3 bg-modal">
        <div class="d-flex justify-content-end">
            <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="close()"></i>
        </div>
        <p class="color-secundary text-center font-size-16">Compartilhe o endereço da carteira</p>

        <div class="d-flex justify-content-center mt-2">
            <qrcode [cssClass]="'qrcode'" [qrdata]="walletAddress" [colorDark]="'#4A35E8'" [colorLight]="'#FFFFFF'"
                [width]="230" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
        <div *ngIf="walletAddress === undefined" class="d-flex justify-content-center mt-2">
            <span class="color-gray">Usuário não possui esse tipo de carteira.</span>
        </div>
        <div class="border-dark-green d-flex align-items-center p-3 rounded-3 mt-4">
            <span class="bg-transparent border-0 font-size-13 color-secundary w-100">{{formatWalletAddress(walletAddress)}}</span>
            <i class="bi bi-files" style="color: #4A35E8 !important;" (click)="copyWalletAddress()"></i>
        </div>
    </div>
</ng-template>