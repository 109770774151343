<section class="background-principal">
  <div class="container min-vh-100 bg-transparent">
      <div class="d-flex justify-content-between align-items-center pt-4">
        <div [routerLink]="['/logged/home']" class="col-2 align-items-start cursor-pointer">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M21.0312 25.9375L20.3438 26.625C20.0312 26.9062 19.5625 26.9062 19.2812 26.625L13.2188 20.5312C12.9062 20.25 12.9062 19.7812 13.2188 19.5L19.2812 13.4062C19.5625 13.125 20.0625 13.125 20.3438 13.4062L21.0312 14.0938C21.3438 14.4062 21.3125 14.875 21.0312 15.1875L17.25 18.75H26.25C26.6562 18.75 27 19.0938 27 19.5V20.5C27 20.9375 26.6562 21.25 26.25 21.25H17.25L21.0312 24.8438C21.3125 25.1562 21.3438 25.625 21.0312 25.9375Z" fill="#060A30"/>
          </svg>
      </div>
      <span class="color-secundary">Configurações</span>
      <div style="width: 40px; height: 40px;"></div>
    </div>

    <div>
      <div>
        <span>Lembre-me</span>

        <div class="switch-container">
          <input type="checkbox" [checked]="remindMeSwitch" class="switch-input">
          <label for="switch" class="switch-label" (click)="remindMeSwitch = !remindMeSwitch">
            <span class="switch-button" [class.switch-button-on]="remindMeSwitch"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</section>

