import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map } from 'rxjs';
import { AccountGetResponseDto } from '../dtos/account/account-get-response.dto';
import { MovementRegisterResponseDto } from '../dtos/account/movement/movement-register-response.dto';
import { TransferCodeAccountsRegisterDto } from '../dtos/account/transfer-code-accounts-register.dto';
import { TransferCodeAccountsRegisterConfirmationDto } from '../dtos/account/transfer-code-accounts-register-confirmation.dto';
import { AssasTransferPixRequestDto } from '../dtos/account/AssasTransferPixRequest.dto';
import { AssasGetPixInformationRequestDto } from '../dtos/account/AssasGetPixInformationRequest.dto';
import { BankAccount } from '../types/pixInfo.type';
import { AssasTransferPixConfirmationRequestDto } from '../dtos/account/AssasTransferPixConfirmationRequest.dto';
import { CashOutPixRequestDto } from '../dtos/account/CashOutPixRequestDto';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  walletApiUrl: string = `${environment.api.path}/account`

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    super();
  }

  getAccount(): Observable<AccountGetResponseDto> {
    return this.httpClient
      .get(`${this.walletApiUrl}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAccountById(_id: string): Observable<AccountGetResponseDto> {
    return this.httpClient
      .get(`${this.walletApiUrl}/account/${_id}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAccountMovement(start: string, end: string): Observable<any> {
    return this.httpClient
      .get(`${this.walletApiUrl}/movement/start/${start}/end/${end}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  transferCodeAccounts(dto: TransferCodeAccountsRegisterDto): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/transfer-code-accounts`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  transferCodeAccountsConfirmation(dto: TransferCodeAccountsRegisterConfirmationDto): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/transfer-code-accounts-confirmation`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  pixInfo(dto: AssasGetPixInformationRequestDto): Observable<BankAccount> {
    return this.httpClient
      .get(`${this.walletApiUrl}/pix-info/type/${dto.pixAddressKeyType}/key/${dto.pixAddressKey}`, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  transferPix(dto: AssasTransferPixRequestDto): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/transfer-pix`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  transferPixConfirmation(dto: AssasTransferPixConfirmationRequestDto): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/transfer-pix-confirmation`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  pixCashOut(dto: CashOutPixRequestDto): Observable<any> {
    return this.httpClient
      .post(`${this.walletApiUrl}/pix-cashout`, dto, this.authorizedHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}