<section class="container p-4 pb-3 bg-modal">
    <div class="d-flex justify-content-end">
        <i class="bi bi-x-lg color-secundary cursor-pointer" (click)="exit()"></i>
    </div>
    <p class="color-secundary">{{ 'CHANGE_PHOTO.CHANGE_PROFILE_PHOTO' | translate }}</p>

    <!-- <div class="card-add-money mt-3 cursor-pointer" (click)="openCamera()">
        <div class="d-flex justify-content-between align-items-center custom-file-upload">
            <label class="d-flex align-items-center w-100 p-2">
                <i class="bi bi-camera" style="color: #4A35E8;"></i>
                <p class="fw-400 font-size-14 color-secundary mb-0 ms-3">{{ 'CHANGE_PHOTO.CAMERA' | translate }}</p>
            </label>
        </div>
    </div> -->

    <div class="card-add-money mt-3 cursor-pointer">
        <div class="d-flex justify-content-between align-items-center custom-file-upload">
            <label class="d-flex align-items-center w-100 p-2">
                <i class="bi bi-images" style="color: #4A35E8;"></i>
                <p class="fw-400 font-size-14 color-secundary mb-0 ms-3">{{ 'CHANGE_PHOTO.GALLERY' | translate }}</p>
                <input type="file" accept="image/*" (change)="handleGallerySelection($event)">
            </label>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <button class="btn btn-outline-dark bg-transparent mt-4 color-secundary" (click)="exit()">{{ 'CHANGE_PHOTO.CANCEL' | translate }}</button>
    </div>
</section>

<div id="cameraModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'CHANGE_PHOTO.CAPTURE_IMAGE' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <video id="cameraVideo" autoplay playsinline></video>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary color-black" data-dismiss="modal">{{ 'CHANGE_PHOTO.CANCEL' | translate }}</button>
                <button type="button" class="btn btn-primary color-black" (click)="captureImage()">{{ 'CHANGE_PHOTO.CAPTURE' | translate }}</button>
            </div>
        </div>
    </div>
</div>
