import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-change-photo',
  templateUrl: './change-photo.component.html',
  styleUrls: ['./change-photo.component.scss']
})
export class ChangePhotoComponent {
  @Output() modalClosed = new EventEmitter<boolean>();

  private videoElement?: HTMLVideoElement;
  private canvas?: HTMLCanvasElement;

  constructor(
    private modalService: NgbModal,
    private clientService: ClientService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.videoElement = document.getElementById('cameraVideo') as HTMLVideoElement;
    this.canvas = document.createElement('canvas');
  }

  exit() {
    this.modalService.dismissAll();
    this.modalClosed.emit(true);
  }

  openCamera() {
    if (this.videoElement) {
      this.videoElement.style.display = 'block';

      const constraints = {
        video: {
          facingMode: 'user',
        },
        audio: false,
      };

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          if (this.videoElement) {
            this.videoElement.srcObject = stream;
          }
        });
    }
  }

  captureImage() {
    if (!this.videoElement || !this.canvas) return;

    if (this.videoElement.videoWidth && this.videoElement.videoHeight) {
      this.canvas.width = this.videoElement.videoWidth;
      this.canvas.height = this.videoElement.videoHeight;

      const context = this.canvas.getContext('2d');
      if (!context) return;

      context.drawImage(this.videoElement, 0, 0, this.canvas.width, this.canvas.height);

      this.canvas.toBlob((blob) => {
        if (!blob) return;

        const file = new File([blob], 'captured-image.png', { type: 'image/png' });

        const shouldUpload = confirm('Deseja fazer o upload da imagem capturada?');
        if (shouldUpload) {
          this.spinner.show();
          this.clientService.uploadAvatar(file).subscribe({
            next: (response) => {
              this.spinner.hide();
              this.toastrService.success('Avatar alterado com sucesso.', '', { progressBar: true });
              this.exit();
            },
            error: (error) => {
              console.error('Erro ao fazer o upload de avatar:', error);
              this.spinner.hide();
              this.toastrService.error('Não foi possível alterar o avatar, tente novamente mais tarde.', '', { progressBar: true });
              this.exit();
            },
          });
        }

        if (this.videoElement) {
          this.videoElement.style.display = 'none';
        }
      }, 'image/png');
    }
  }

  handleGallerySelection(event: any) {
    this.spinner.show();
    const file = event.target.files[0];
    if (!file) {
      this.spinner.hide();
      return;
    }
    if (file.type.startsWith('image/')) {
      this.clientService.uploadAvatar(file).subscribe({
        next: (response) => {
          this.spinner.hide();
          this.toastrService.success('Avatar alterado com sucesso, aguarde alguns instantes para ver a mudança.', '', { progressBar: true });
          this.exit();
        },
        error: (error) => {
          console.error('Erro ao fazer o upload de avatar:', error);
          this.spinner.hide();
          this.toastrService.error('Não foi possível alterar o avatar, tente novamente mais tarde.', '', { progressBar: true });
        },
      });
    } else {
      this.spinner.hide();
      this.toastrService.error('Selecione um arquivo de imagem válido (por exemplo, JPG, PNG).', '', { progressBar: true });
    }
  }
}

