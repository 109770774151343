import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddMoneyComponent } from './add-money/add-money.component';
import { CheckoutBoletoComponent } from './add-money/components/checkout-boleto/checkout-boleto.component';
import { CheckoutPixComponent } from './add-money/components/checkout-pix/checkout-pix.component';
import { CheckoutComponent } from './add-money/components/checkout/checkout.component';
import { BuyComponent } from './buy/buy.component';
import { CreatePasswordLoggedComponent } from './create-password-logged/create-password-logged.component';
import { CriptoComponent } from './cripto/cripto.component';
import { DepositCoinComponent } from './deposit/deposit-coin/deposit-coin.component';
import { DepositComponent } from './deposit/deposit.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { ExchangePageComponent } from './exchange-page/exchange-page.component';
import { HomeComponent } from './home/home.component';
import { NotificationsComponent } from './home/notifications/notifications.component';
import { RescueComponent } from './home/rescue/rescue.component';
import { SplashRescueComponent } from './home/splash-rescue/splash-rescue.component';
import { LoggedComponent } from './logged.component';
import { AccountDetailsComponent } from './my-account/account-details/account-details.component';
import { PinComponent } from './my-account/account-details/pin/pin.component';
import { ValidationIdComponent } from './my-account/account-details/validation-id/validation-id.component';
import { ChangePasswordComponent } from './my-account/change-password/change-password.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PersonalInfoComponent } from './my-account/personal-info/personal-info.component';
import { SettingsComponent } from './my-account/settings/settings.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { PersonalInfoNeededComponent } from './personal-info-needed/personal-info-needed.component';
import { PhoneVerifyComponent } from './phone-verify/phone-verify.component';
import { ProcessingScreenComponent } from './processing-screen/processing-screen.component';
import { ReceiveMoneyComponent } from './receive-money/receive-money.component';
import { ReceivePixComponent } from './receive-money/receive-pix/receive-pix.component';
import { ReceiveTicketComponent } from './receive-money/receive-ticket/receive-ticket.component';
import { RequestCardSuccessComponent } from './request-card/request-card-success/request-card-success.component';
import { RequestCardComponent } from './request-card/request-card.component';
import { SaleComponent } from './sale/sale.component';
import { SendMoneySet2Component } from './send-money/send-money-set2/send-money-set2.component';
import { SendMoneyStep1Component } from './send-money/send-money-step1/send-money-step1.component';
import { SendMoneyStep3Component } from './send-money/send-money-step3/send-money-step3.component';
import { SendMoneyStep4Component } from './send-money/send-money-step4/send-money-step4.component';
import { SendMoneyComponent } from './send-money/send-money.component';
import { ChooseMethodComponent } from './send-pix/choose-method/choose-method.component';
import { KeyPixMessageComponent } from './send-pix/key-pix-message/key-pix-message.component';
import { KeyPixStepComponent } from './send-pix/key-pix-step/key-pix-step.component';
import { PixSuccessComponent } from './send-pix/pix-success/pix-success.component';
import { PixVerifyCodeComponent } from './send-pix/pix-verify-code/pix-verify-code.component';
import { SendPixComponent } from './send-pix/send-pix.component';
import { ShareAccountComponent } from './share-account/share-account.component';
import { SplashCriptoComponent } from './splash-cripto/splash-cripto.component';
import { TransferMessageComponent } from './transfer/transfer-message/transfer-message.component';
import { TransferSuccessComponent } from './transfer/transfer-success/transfer-success.component';
import { TransferValueComponent } from './transfer/transfer-value/transfer-value.component';
import { TransferVerifyCodeComponent } from './transfer/transfer-verify-code/transfer-verify-code.component';
import { TransferComponent } from './transfer/transfer.component';
import { VerifyCodeLoggedComponent } from './verify-code-logged/verify-code-logged.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { YourActivitiesComponent } from './your-activities/your-activities.component';

const routes: Routes = [
  {
    path: '',
    component: LoggedComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'your-activities', component: YourActivitiesComponent },
      { path: 'my-account', component: MyAccountComponent },
      { path: 'buy', component: BuyComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'deposit', component: DepositComponent },
      { path: 'withdraw', component: WithdrawComponent },
      { path: 'deposit-coin/:coinSelect', component: DepositCoinComponent },
      { path: 'request-card', component: RequestCardComponent },
      { path: 'request-card-success', component: RequestCardSuccessComponent },
      { path: 'splash-rescue', component: SplashRescueComponent },
      { path: 'splash-cripto', component: SplashCriptoComponent },
      { path: 'cripto', component: CriptoComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'rescue', component: RescueComponent },
      { path: 'details', component: AccountDetailsComponent },
      { path: 'personal-info', component: PersonalInfoComponent },
      { path: 'my-orders', component: MyOrdersComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'add-money', component: AddMoneyComponent },
      { path: 'send-money', component: SendMoneyComponent },
      { path: 'send-money-permision', component: SendMoneyStep1Component },
      { path: 'send-money-option', component: SendMoneySet2Component },
      { path: 'send-money-contact', component: SendMoneyStep3Component },
      { path: 'send-money-value', component: SendMoneyStep4Component },
      { path: 'share-account', component: ShareAccountComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'checkout-pix', component: CheckoutPixComponent },
      { path: 'checkout-boleto', component: CheckoutBoletoComponent },
      { path: 'validation-id', component: ValidationIdComponent },
      { path: 'sale', component: SaleComponent },
      { path: 'processing', component: ProcessingScreenComponent },
      { path: 'exchange', component: ExchangePageComponent },
      { path: 'receive-money', component: ReceiveMoneyComponent },
      { path: 'receive-pix', component: ReceivePixComponent },
      { path: 'send-pix', component: SendPixComponent },
      { path: 'send-pix-key', component: KeyPixStepComponent },
      { path: 'send-pix-message', component: KeyPixMessageComponent },
      { path: 'send-pix-verify', component: PixVerifyCodeComponent },
      { path: 'send-pix-success', component: PixSuccessComponent },
      { path: 'choose-method-2fa', component: ChooseMethodComponent },
      { path: 'receive-ticket', component: ReceiveTicketComponent },
      { path: 'personal-info-needed', component: PersonalInfoNeededComponent },
      { path: 'create-password-logged', component: CreatePasswordLoggedComponent },
      { path: 'transfer', component: TransferComponent },
      { path: 'transfer-value', component: TransferValueComponent },
      { path: 'transfer-message', component: TransferMessageComponent },
      { path: 'transfer-verify', component: TransferVerifyCodeComponent },
      { path: 'transfer-success', component: TransferSuccessComponent },
      { path: 'pin', component: PinComponent},
      { path: 'verify-email', component: EmailVerifyComponent },
      { path: 'verify-phone', component: PhoneVerifyComponent },
      { path: 'verify-code-logged', component: VerifyCodeLoggedComponent },

      //THIS ROUTE MUST BE THE LAST ONE
      { path: '**', redirectTo: 'home' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoggedRouteModule { }
