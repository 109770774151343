import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NotificationModel } from 'src/app/services/notification/models/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: NotificationModel[] = [];

  constructor(
    private location: Location,
    private notificationService: NotificationService,
    private toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.notificationService.listByClient().subscribe({
      next: (data) => {
        this.notifications = data;
        this.ngxSpinnerService.hide();
      },
      error: error => {
        this.ngxSpinnerService.hide();
      }
    });
  }
  
  goBack() {
    this.location.back();
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  }

}
